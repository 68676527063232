import {FC, useEffect, useState} from "react"
import {getTextures, TextureData} from "../../../lib/Models/Texture"
import Feed from "./Feed"
import {RootWrapper, UnsplashWrapper} from "../../../Integrations/Unpslash";
import styled from "styled-components";


const TexturesWrapper = styled(UnsplashWrapper)`
  padding: 0 0 12px 12px;
`

const Textures: FC<{ onSelection?: (texture: TextureData) => void }> = ({onSelection}) => {
    // @ts-ignore
    const [textures, setTextures] = useState<TextureData[] | null>(null)

    useEffect(() => {
        getTextures().then(texturesResponse => {
            setTextures(texturesResponse)
        })
    }, [setTextures])

    return (
        <RootWrapper>
            {textures && <TexturesWrapper><Feed textures={textures} onSelection={onSelection}/></TexturesWrapper>}
        </RootWrapper>
    )
}

export default Textures