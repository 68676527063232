import React from 'react';
import {createPortal} from 'react-dom';

const portalRoot = document.getElementById('portalSimple');


class PortalSimple extends React.Component {
    render() {
        return createPortal(
            <>
                {this.props.children}
            </>
            , portalRoot || document.body);
    }
}

export default PortalSimple;