import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import Header from "../Layouts/Header";
import {UserContext} from "../Providers/Auth/UserProvider";
import {IUser} from "../lib/Models/User";
import {RouteComponentProps, useParams} from "@reach/router";
import styled from "styled-components";
import MagmaModules from '../Modules'
import {useBus, useListener} from 'react-bus'
import {DeleteOutlineBlack24Dp, IconCanvaCDarkLight} from "../icons";
import MagCanvas from "../Components/MagCanvas";
import {Block, convertScalePercentageToDecimal, getComponentName, ModuleResolver, urlToBlob} from "../helpers";
import CanvasControls from "../Components/CanvasControls";
import MagNavigator from "../Components/MagNavigator";
import Portal from "../Portal";
import Showcase from "../Components/PageTemplates/Showcase";
import {initialPagesState, PagesContext, PageState} from "../Providers/Page/PageProvider";
import cuid from "cuid";
import {remove} from "lodash";
import {removeAsset} from "../Modules/Image/ImageModule";
import {firestore} from "../Integrations/firebase";
import PublishScreen from "../Components/PageTemplates/PublishScreen";
import {CompState} from "../lib/Models/Component";
import ControlledTemplateCanvas from "../Components/ControlledTemplateCanvas";
import MagmaBlockEntity from "../lib/MagmaBlockEntity";
import Switch from "react-switch";
import {uploadAssetToMag} from "../lib/Models/Asset";
import GridLoader from "react-spinners/GridLoader";

const Body = styled.div`

`

const SideNav = styled.div`
  width: 100px;
  background: ${props => props.theme.colors.sidenavBg};
  height: calc(100vh - 76px);
  top: 76px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07),
  0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07),
  0 32px 64px rgba(0, 0, 0, 0.07);
  z-index: 99;
`
const SideNavItem = styled.div`
  cursor: pointer;
  font-size: 42px;
  text-align: center;
  padding: 12px 0;
  color: ${props => props.theme.colors.text};

  &:hover {
    background: ${props => props.theme.colors.sidenavHover};
  }

  svg {
    margin: auto;
  }
`

const DeleteNavItem = styled(SideNavItem) <{ canDelete: boolean }>`
  color: ${props => props.canDelete ? 'red' : 'inherit'};
  opacity: ${props => props.canDelete ? '0.7' : '0'};
  transition: opacity .3s ease;

  &:hover {
    opacity: 1;
    background: transparent;
  }
`

const OverflowContainer = styled.div`
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
  //overflow: auto;
  //max-height: calc(100vh - 76px);
  //
  //&::-webkit-scrollbar {
  //  display: none;
  //}
`

const CanvasWrapper = styled.div<{ visibleSidebar: boolean, focusMode?: boolean }>`
  margin-left: ${props => props.visibleSidebar ? '400px' : 0};
  display: flex;
  position: relative;
  min-height: calc(100vh - 76px);
  transition: .3s ease;
  transform: translate3d(0);
  background\: ${props => (props.focusMode || (props.theme.name === 'lightTheme' && !props.visibleSidebar)) ? props.theme.colors.canvasWrapperBgFocus : props.theme.colors.canvasWrapperBg};
`

const OptionsWrapper = styled.div`
  left: 100px;
  position: fixed;
  top: 76px;
  bottom: 0;
  z-index: 99;
`
const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  transition: .3s ease;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.89);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Create: FC<RouteComponentProps> = () => {
    // Declarations //
    const {magID} = useParams()


    const canvasWrapperRef = useRef<HTMLDivElement>(null)

    //....contexts
    // @ts-ignore
    const user: IUser = useContext(UserContext);
    // @ts-ignore
    const [pagesState, pagesStateDispatch] = useContext(PagesContext)

    const [centerX, setCenterX] = useState<boolean>(false)
    const [addingNewPage, setAddingNewPage] = useState<boolean>(false)
    const [publishingMag, setPublishingMag] = useState<boolean>(false)
    const nullComponent = {id: null, meta: null}
    const [activeComponent, setActiveComponent] = useState<{ id: string | null, meta?: any }>(nullComponent)
    const [focusMode] = useState<boolean>(false)
    const [reOrderMode, setReOrderMode] = useState<boolean>(false)

    const [lastOrderChange, setLastOrderChange] = useState(true)

    const [loading, setLoading] = useState(false)

    //... bus
    const bus = useBus()


    //.. bus listeners
    useListener('centerX', (payload) => {
        setCenterX(payload)
    })


    const startLoading = useCallback(() => {
        setLoading(true)
    }, [setLoading])

    const stopLoading = useCallback(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500)

    }, [setLoading])

    useEffect(() => {

        window.addEventListener('start_loading', startLoading)
        window.addEventListener('stop_loading', stopLoading)

        return () => {
            window.removeEventListener('start_loading', startLoading)
            window.removeEventListener('stop_loading', stopLoading)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [])

    const getComponents = useCallback((): CompState[] => {
        const page: PageState = pagesState?.Pages?.find((page: PageState) => page.ID === pagesState?.activePage)

        return page && Array.isArray(page.LayeredBlocks) ? page.LayeredBlocks : []
    }, [pagesState?.activePage, pagesState?.Pages])

    const getBlocks = useCallback((): MagmaBlockEntity[] => {
        const page: PageState = pagesState?.Pages?.find((page: PageState) => page.ID === pagesState?.activePage)

        return page && Array.isArray(page.Blocks) ? page.Blocks : []
    }, [pagesState?.activePage, pagesState?.Pages])


    const isMaster = useMemo(() => {
        const activePage: PageState = pagesState?.Pages?.find((page: PageState) => page.ID === pagesState?.activePage)
        if (!activePage) {
            return true
        }

        if (activePage.Blocks && activePage.Blocks.length < 1) {
            return true
        }

        return activePage.Blocks[0].Kind === 'master'


    }, [pagesState])

    const addModule = ({component, meta = {}}: { component: string, meta?: any }): string => {
        const components = getComponents()

        const _uid = cuid()

        components.push({
            id: _uid,
            component,
            ...meta
        })

        setActiveComponent({id: _uid})

        pagesStateDispatch({
            type: 'SET_COMPONENTS',
            payload: components
        })

        return _uid
    }

    const removeModule = async (uid: string | null) => {
        if (uid === null) {
            return
        }

        setActiveComponent({id: null})

        const payload = getComponents()
        const [component] = remove(payload, ({id}: CompState) => id === uid)
        const dispatch = () => pagesStateDispatch({
            type: 'SET_COMPONENTS',
            payload
        })

        if (component?.design) {
            const removedAsset = await removeAsset(component)
            if (removedAsset) {
                dispatch()

                return
            }

            console.error("Error deleting asset: ", removedAsset)

            return
        }

        dispatch()
    }


    useListener('updateComponent', payload => onUpdateComponent({id: payload.id, meta: payload.data}))

    const onUpdateComponent = useCallback((data: { id: string, meta?: any }) => {


        if (!data.meta) {
            setLastOrderChange(!lastOrderChange)
            return;
        }
        data.meta.tag = 0

        let components = getComponents()
        let componentToUpdate = components.find((comp: CompState) => comp.id === data.id)
        if (!componentToUpdate) {
            return
        }

        const compIndex = components.indexOf(componentToUpdate)
        componentToUpdate = {...componentToUpdate, ...data.meta}

        if (componentToUpdate) {
            components[compIndex] = componentToUpdate
        }

        pagesStateDispatch({
            type: 'SET_COMPONENTS',
            payload: components
        })
    }, [getComponents, lastOrderChange, pagesStateDispatch])

    const findActiveComponentOptions = (): any | undefined => {
        const components = getComponents()
        const component = components.find((component: CompState) => component.id === activeComponent.id)

        return component ? getComponentName(component) : ''
    }

    const updateFromOptions = (payload: any) => {
        bus.emit(payload.id, payload)
    }


    const initCanva = async () => {
        if (window.Canva?.DesignButton) {
            if (!window.CanvaApi) {
                window.CanvaApi = await window.Canva.DesignButton.initialize({
                    apiKey: 'Y3PJXSb9L1tqYMTtJnPqJ__7',
                });
            }

            window.CanvaApi.createDesign({
                design: {
                    type: 'InstagramStory',
                    //TODO: Add dimensions to match screen  size in canva
                },
                onDesignOpen: () => {
                    // Triggered when editor finishes loading and opens a new design.
                    // You can save designId for future use.
                },
                onDesignPublish: ({exportUrl}) => {
                    // Triggered when design is published to an image.
                    // Save the image to your server as the exportUrl will expire cshortly.
                    const newMeta = {
                        x: 0,
                        y: 0,
                        size: [570, 570]
                    }

                    // TODO: Replace with new component update reducer events
                    const newUid = addModule({component: "Image", meta: newMeta})
                    onUpdateComponent({id: newUid, meta: newMeta})

                    urlToBlob(exportUrl).then(blob => {
                        uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {
                            updateFromOptions({
                                id: newUid,
                                action: 'updateMeta',
                                data: {
                                    // @ts-ignore
                                    design: {
                                        asset
                                    }
                                }
                            })
                        })
                    })


                },
                onDesignClose: () => {
                    // Triggered when editor is closed.
                },
            });
        }
    }

    const onPublishingMag = () => {

        setPublishingMag(true)
    }

    useEffect(() => {
        // document.body.style.overflow = 'hidden'

        firestore.doc(`/users/${user.uid}/drafts/${magID}`).get().then((snapshot) => {
            let magData = snapshot.data()
            if (!magData) return

            /* 
            TODO: remove or refactor? causing last draft's to replace current
              */

            if (pagesState?.Pages[0].LayeredBlocks.length > 0) {
                magData = {...magData, ...pagesState}
            }

            pagesStateDispatch({
                type: 'SET_MAG',
                payload: magData
            })
        })

        pagesStateDispatch({
            type: 'SET_ACTIVE_MAG_ID',
            payload: magID
        })

        return () => {
            pagesStateDispatch({
                type: 'SET_ACTIVE_MAG_ID',
                payload: null
            })

            pagesStateDispatch({
                type: 'SET_MAG',
                payload: initialPagesState
            })

            // document.body.style.removeProperty('overflow')
        }

        // eslint-disable-next-line
    }, [magID, pagesStateDispatch, user.uid])

    return (
        <>
            <Header user={user}/>
            <Body>
                {isMaster && <>

                    <SideNav>
                        <ul>
                            {MagmaModules.map((magmaModule, idx) => (
                                <li key={idx}>
                                    <SideNavItem onClick={() => addModule({
                                        component: magmaModule.name,
                                        meta: magmaModule.defaultMeta || {}
                                    })}>
                                        <Block component={magmaModule.icon} key={idx}/>
                                    </SideNavItem>
                                </li>
                            ))}
                            <li>
                                <SideNavItem onClick={() => initCanva()}>
                                    <IconCanvaCDarkLight style={{color: '#fff'}}/>
                                </SideNavItem>
                            </li>
                        </ul>
                        <div>
                            <DeleteNavItem canDelete={!!activeComponent.id}
                                           onClick={() => removeModule(activeComponent.id)}>
                                <DeleteOutlineBlack24Dp/>
                            </DeleteNavItem>
                        </div>
                    </SideNav>

                    <OptionsWrapper>
                        {activeComponent.id && <ModuleResolver
                            key={activeComponent.id}
                            props={{
                                meta: activeComponent.meta,
                                id: activeComponent.id,
                                onDone: () => setActiveComponent({id: null}),
                                onRemove: () => activeComponent.id && removeModule(activeComponent.id),
                                onUpdate: updateFromOptions

                            }}
                            component={findActiveComponentOptions()}
                            type={"options"}
                        />
                        }
                    </OptionsWrapper>
                </>}

                <OverflowContainer id={"overflowContainer"}>
                    <CanvasWrapper ref={canvasWrapperRef} focusMode={focusMode} visibleSidebar={isMaster}>
                        {isMaster ? <MagCanvas
                                components={getComponents()}
                                centerX={centerX}
                                removeModule={removeModule}
                                setActiveComponent={setActiveComponent}/> :
                            <ControlledTemplateCanvas
                                components={getBlocks()}
                                centerX={centerX}
                                removeModule={removeModule}
                                sortEnabled={reOrderMode}
                                setActiveComponent={setActiveComponent}
                                key={pagesState?.activePage}/>}

                        <MagNavigator onNewPage={() => setAddingNewPage(true)}
                                      onSwitchPage={() => setActiveComponent({id: null})}/>
                        <CanvasControls visibleSidebar={isMaster} onPublishingMag={onPublishingMag}/>
                        {addingNewPage && <Portal onClose={() => setAddingNewPage(false)}>
                            <Showcase onPageAdded={() => {
                                setAddingNewPage(false)
                                setActiveComponent({id: null})
                            }}/>
                        </Portal>
                        }

                        {publishingMag && <Portal onClose={() => setPublishingMag(false)}>
                            <PublishScreen onClose={() => setPublishingMag(false)}/>
                        </Portal>}


                        {/* TODO: Experimental */
                            !isMaster && <>
                                {/*<label className={"flex items-center mb-4 fixed bottom-0 left-0 p-4"}>*/}
                                {/*    <Switch onChange={(checked) => {*/}
                                {/*        if (checked) {*/}
                                {/*            bus.emit('focusModeEnabled', convertScalePercentageToDecimal(100))*/}
                                {/*        } else {*/}
                                {/*            bus.emit('scaleChange', convertScalePercentageToDecimal(70))*/}
                                {/*        }*/}
                                {/*        setFocusMode(checked)*/}
                                {/*    }} checked={focusMode}/>*/}
                                {/*    <span className={"ml-2 text-xl"}>Focus Mode</span>*/}

                                {/*</label>*/}
                                <label className={"flex items-center mb-16 fixed bottom-0 left-0 p-4"}>
                                    <Switch onChange={(checked) => {
                                        if (checked) {
                                            bus.emit('focusModeEnabled', convertScalePercentageToDecimal(100))

                                            bus.emit('sortEnabled')
                                        } else {
                                            bus.emit('sortDisabled')
                                        }
                                        setReOrderMode(checked)
                                    }} checked={reOrderMode}/>
                                    <span className={"ml-2 text-xl"}>Re-Order Blocks</span>

                                </label>
                            </>}
                    </CanvasWrapper>
                </OverflowContainer>
            </Body>
            {loading &&
            <LoadingWrapper>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <GridLoader loading={loading} size={15} color={'rgb(251,179,30)'}
                                    margin={5}/>
                    </div>
                    <div style={{color: '#fff', fontSize: 22, fontWeight: 600, marginTop: 10}}>Uploading Asset..
                    </div>
                    <div style={{color: 'rgba(255,255,255,0.48)', fontSize: 16, fontWeight: 400, marginTop: 10}}>DO NOT
                        CLOSE THIS
                        WINDOW
                    </div>

                </div>
            </LoadingWrapper>}
        </>
    )

}

export default Create