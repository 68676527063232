import { FC } from "react";
import styled from "styled-components";

const GridWrapper = styled.div`
  display: grid;
  grid-column-gap: var(--column-gutter);
  grid-template-columns: repeat(var(--columns),minmax(0,1fr));
  align-items: start;
`

const Grid: FC = ({ children }) => {
  return (
    <GridWrapper>
      {children}
    </GridWrapper>
  )
}

const ColumnWrapper = styled.div`
  display: grid;
  row-gap: var(--row-gutter);
  grid-template-columns: minmax(0,1fr);
`

export const Column: FC = ({ children }) => <ColumnWrapper>{children}</ColumnWrapper>

export default Grid