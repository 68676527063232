import React, {FC, useCallback, useContext, useEffect} from "react";
import {uploadAssetToMag} from "../lib/Models/Asset";
import {PagesContext} from "../Providers/Page/PageProvider";
import {useParams} from "@reach/router";
// @ts-ignore
import {initiateScreenshotTimer, setCurrentScreenshotPageID, stopScreenshotTimer} from "../screenshotUtils";

type WithSnapshotProps = {}


const withSnapshotHelper = <P extends object>(Component: React.ComponentType<P>): FC<P & WithSnapshotProps> => {
    return ({...props}) => {

        const {magID} = useParams()

        // @ts-ignore
        const [pagesState, pagesStateDispatch] = useContext(PagesContext)


        const handleScreenshotUpdate = useCallback((ev: any) => {
            if (window._SNAPSHOT_PAGE_ID !== pagesState?.activePage) {
                return
            }
            if (!ev.detail.file) {
                return
            }

            uploadAssetToMag({
                magID,
                file: ev.detail.file,
                isScreenshot: true
            }).then(asset => {
                let proof = {
                    id: asset.ID,
                    kind: 'proof',
                    preview: asset
                }
                pagesStateDispatch({
                    type: 'SET_PROOFS',
                    payload: {proof}
                })

            })

        }, [magID, pagesState?.activePage, pagesStateDispatch])

        const startScreenshotListener = useCallback(() => {
            window.addEventListener('pageScreenshot', handleScreenshotUpdate)

        }, [handleScreenshotUpdate])

        const stopScreenshotListener = useCallback(() => {
            window.removeEventListener('pageScreenshot', handleScreenshotUpdate)
        }, [handleScreenshotUpdate])

        useEffect(() => {

            stopScreenshotListener()
            stopScreenshotTimer()


            startScreenshotListener()

            setCurrentScreenshotPageID(pagesState?.activePage)

            initiateScreenshotTimer()


            return () => {
                setCurrentScreenshotPageID(undefined)
                stopScreenshotListener()
                stopScreenshotTimer()
            }

        }, [pagesState?.activePage, startScreenshotListener, stopScreenshotListener])


        return (
            <>
                <Component {...props as P} />
            </>
        )
    };
}

export default withSnapshotHelper