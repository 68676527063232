import React, {FC, useState} from 'react'
import MagmaVideoEntity from "../../lib/Content/MagmaVideoEntity";
import PhotoContent from "./PhotoContent";
import styled from "styled-components";


const YoutubePlayIcon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;

`

const PlayIcon: FC<{ onClick?: () => void, video: MagmaVideoEntity }> = ({onClick, video}) => {
    const mp4 = video.Provider === 2

    return (
        <div
            data-trigger
            className={['block', 'w-full', 'h-full', 'text-white', 'flex', 'items-center', 'justify-center', 'cursor-pointer'].join(' ')}>
            {mp4 ? <svg width={80} fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                      clipRule="evenodd"/>
            </svg> : <svg width={80} version="1.1" viewBox="0 0 68 48">
                <path className="ytp-large-play-button-bg"
                      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                      fill="#f00"/>
                <path d="M 45,24 27,14 27,34" fill="#fff"/>
            </svg>}
        </div>
    )
}


const VideoContent: FC<MagmaVideoEntity> = (Video) => {
    const [videoToggle] = useState(false);

    return (
        <>
            <div
                className={'w-full h-full'}
            >
                {Video.Provider === 1 && <>
                    <PhotoContent ID={Video.ID} Asset={{
                        URL: (Video.Placeholder ? Video.Placeholder.URL : Video.Thumbnail) || '',
                        ID: '',
                        FileType: '',
                        Directory: ''
                    }} ShoppingLink={''} TaggedUsers={[]}/>
                    <YoutubePlayIcon data-trigger>
                        {!videoToggle && <PlayIcon video={Video}/>}
                    </YoutubePlayIcon>
                </>}
                {Video.Provider === 2 && Video.Asset && <>
                    <video poster={Video.Placeholder?.URL} src={Video.Asset?.URL}
                           style={{width: '100%', height: '100%'}} controls/>
                </>}

            </div>
        </>

    )
}

export default VideoContent
