import {ComponentProps, createContext, useReducer} from "react";
import {CompState} from "../../lib/Models/Component";
import PageReducer from "./PageReducer";
import {IProof} from "../../lib/Models/Mag";
import firestore from "@google-cloud/firestore";
import cuid from "cuid";
import MagmaBlockEntity from "../../lib/MagmaBlockEntity";
import {IGenre} from "../../lib/Models/Genre";
import {ILocation} from "../../lib/Models/Map";

const coverID = cuid()

export interface PageState {
    ID: string
    LayeredBlocks: CompState[],
    Blocks: MagmaBlockEntity[]
    snapshot?: string
}

export interface PagesState {
    ID: string;
    activePage: string
    activeMagID: string | null
    Pages: PageState[],
    Proofs: IProof[]
    CreatedAt: firestore.Timestamp
    UpdatedAt: firestore.Timestamp
    PublisherID: string
    Caption: string
    Genre: IGenre[]
    Location: ILocation
    Privacy: string
}

export const initialPagesState = {
    Pages: [
        {
            ID: coverID,
            LayeredBlocks: [],
            Blocks: []
        }
    ],
    activePage: coverID

}

const PageProvider = (props: ComponentProps<any>) => {
    // @ts-ignore
    const [state, dispatch] = useReducer(PageReducer, initialPagesState);

    return (
        //  @ts-ignore
        <PagesContext.Provider value={[state, dispatch]}>
            {props.children}
        </PagesContext.Provider>
    )
}

export const PagesContext = createContext(initialPagesState)
export default PageProvider