import {FC, FocusEventHandler, MouseEventHandler, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import Portal from "../../Portal";
import MediaComponents from '../MediaComponents'
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";

import {isNil, mapValues, omitBy} from 'lodash'
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
// @ts-ignore
import {Account, DeleteOutlineBlack24Dp, FlightBlack24Dp, LinkBlack24Dp, LocalMallBlack24Dp} from "../../icons";
import AddLinkButton from "../AddLinkButton";
import AddingLinkScreen from "../AddingLinkScreen";
import MagmaLinkEntity, {LinkType} from "../../lib/Content/MagmaLinkEntity";
import TaggingUsersScreen from "../TaggingUsersScreen";
import {IUser} from "../../lib/Models/User";
import {prepareTaggedUsers} from "../../helpers";

const Wrapper = styled.div`
  cursor: pointer;
  height: 100%;
`


const ComponentsWrapper = styled.div`


  width: fit-content;
  margin: auto;

  & > div {
    display: grid;
    gap: 1rem;
  }



`

const CaptionWrapper = styled.button`
  font-size: 1.1rem;
  letter-spacing: 0.08em;
  padding: 2px 4px;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.secondary.bg};
  }
`

const Input = styled.input`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: none;
  outline: none !important;
  font-size: 1.1rem;
  padding: 2px 4px;

  a {
    color: ${props => props.theme.colors.secondary.bg};
  }
`

const CaptionButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`
const DeleteWrapper = styled.button`
  position: absolute;
  bottom: 30px;
  right: 0;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 4px;
  border-radius: 50%;
  color: #000000;
  font-size: 2em;
  background-color: #fff;

  &:hover {
    background: ${props => props.theme.colors.secondary.bg}
  }
`


const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: auto;
  }
`


const LinksGrid = styled.div`
  display: flex;
  gap: 1rem;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 99;

`

const LinkDisplayButton = styled.div`
  padding: 10px;
  border-radius: 50%;
  font-size: 2rem;
  background-color: #fff;
  color: ${props => props.theme.colors.secondary.bg};

  &:hover {
    color: #fff;
    background: ${props => props.theme.colors.secondary.bg}
  }
`

const CaptionButton: FC<{ onUpdateCaption: (caption: string) => void, caption?: string }> = ({
                                                                                                 onUpdateCaption,
                                                                                                 caption
                                                                                             }) => {

    const inputRef = useRef<HTMLInputElement>(null)
    const [defaultVal, setDefaultVal] = useState<undefined | string>(caption)
    const [addingCaption, setAddingCaption] = useState<boolean>(false)


    useEffect(() => {
        if (defaultVal) {
            setAddingCaption(true)
        }
    }, [defaultVal])

    const handleInputBlur: FocusEventHandler<HTMLInputElement> = (ev) => {
        setDefaultVal('')
        const value = ev.currentTarget.value
        if (!value) {
            setAddingCaption(false)
        }
        onUpdateCaption(value)
    }


    return (
        <CaptionButtonWrapper>
            {addingCaption ? (defaultVal && defaultVal.indexOf('<a href') > -1 ?
                        <Input as={"div"} dangerouslySetInnerHTML={{__html: defaultVal || ''}}/> :
                        <Input defaultValue={defaultVal} ref={inputRef} onBlur={handleInputBlur} type="text"/>
                ) :
                <CaptionWrapper onClick={() => {
                    setAddingCaption(true)
                    if (inputRef?.current) {
                        inputRef.current.focus()
                    }
                }}>+ Add Caption </CaptionWrapper>}
        </CaptionButtonWrapper>
    )
}
const DeleteButton: FC<{ onClick: MouseEventHandler<HTMLButtonElement> }> = ({onClick}) => {
    return (
        <DeleteWrapper onClick={onClick}>
            <DeleteOutlineBlack24Dp/>
        </DeleteWrapper>
    )
}


const LinkIcon: FC<{ type?: LinkType }> = ({type = 'standard'}) => {
    switch (type) {
        case "shopping":
            return <LocalMallBlack24Dp/>
        case "standard":
            return <LinkBlack24Dp/>
        case "travel":
            return <FlightBlack24Dp/>
        default:
            return <LinkBlack24Dp/>
    }
}


const MediaWrapper: FC<{
    Content?: MagmaBlockContent<unknown>
    onDeleteBlock: () => void
    updateContent?: (content: MagmaBlockContent<unknown>) => void
}> = ({children, updateContent, Content, onDeleteBlock}) => {

    const [addingMedia, setAddingMedia] = useState<boolean>(false)
    const [addingLink, setAddingLink] = useState<null | string | false>(null)
    const [tempData, setTempData] = useState<undefined | MagmaBlockContent<unknown>>(Content)

    const handleLinkOpenerClick = (type: 'link' | 'tag') => {

        if (type === 'link' && content?.Link) {
            updateLink(undefined)
            return
        }

        setAddingLink(type)

    }


    const type = useMemo(() => {
        if (!tempData) return

        return Object.keys(tempData)[0] as 'Photo'

    }, [tempData])


    const handleUpdate = (data: MagmaBlockContent<unknown>, ignoreSerialization = false) => {

        data = omitBy(data, isNil)

        setTempData(data)

        const plainData = mapValues(data, val => ({...val}))

        if (updateContent) {
            updateContent(plainData as MagmaBlockContent<unknown>)
        }
        setAddingMedia(false)
    }


    const content = useMemo((): MagmaPhotoEntity | undefined => {
        if (!tempData || !type) return

        return tempData[type] as MagmaPhotoEntity

    }, [tempData, type])


    const updateCaption = (caption: string) => {

        if (!content || !type) return


        content.Caption = caption

        handleUpdate({
            [type]: content
        })

    }


    const updateLink = (link: MagmaLinkEntity | undefined) => {

        if (!content || !type) return

        content.Link = link


        handleUpdate({
            [type]: content
        })

        setAddingLink(false)

    }

    const updateTaggedUsers = (users: IUser[] = []) => {

        if (!content || !type) return

        content.TaggedUsers = prepareTaggedUsers(users)


        handleUpdate({
            [type]: content
        },)

        setAddingLink(false)
    }


    const handleWrapperClick: MouseEventHandler<HTMLDivElement> = (ev) => {
        const targetEl = ev.target as HTMLElement
        if (targetEl.tagName === 'IMG' || typeof targetEl.dataset.trigger !== 'undefined') {
            setAddingMedia(true)
        }
    }

    return (
        <>
            <Wrapper onClick={handleWrapperClick}>
                {children}
                {content && <ButtonWrapper>
                    <CaptionButton caption={content?.Caption} onUpdateCaption={updateCaption}/>
                    <DeleteButton onClick={() => {
                        setTempData(undefined)
                        onDeleteBlock()
                    }}/>
                    <AddLinkButton
                        right
                        onSelect={handleLinkOpenerClick}
                        valueKey={'type'}
                        items={[
                            {title: content.Link ? 'Remove Link' : 'Add Link', type: 'link'},
                            {
                                title: content.TaggedUsers && content.TaggedUsers.length > 0 ? 'Edit Tagged Users' : 'Tag Someone',
                                type: 'tag'
                            }
                        ]}
                    />

                </ButtonWrapper>
                }
                <LinksGrid>
                    {content?.Link && <LinkDisplayButton as={"a"}
                                                         href={content.Link.Url}
                                                         target={"_blank"}
                                                         title={content.Link.Url}><LinkIcon
                        type={content.Link.linkType}/></LinkDisplayButton>}
                    {content?.TaggedUsers && content?.TaggedUsers.length > 0 &&
                    <LinkDisplayButton onClick={() => setAddingLink('tag')}><Account/></LinkDisplayButton>}
                </LinksGrid>
            </Wrapper>

            {addingMedia && <Portal onClose={() => setAddingMedia(false)}>
                <ComponentsWrapper>
                    <div style={{color: '#fff'}}>
                        {MediaComponents.map((Comp, idx) => {
                            return <Comp key={idx} updateContent={handleUpdate}/>
                        })}
                    </div>
                </ComponentsWrapper>
            </Portal>}

            {addingLink && <Portal onClose={setAddingLink}>
                <ComponentsWrapper>
                    {addingLink === 'link' &&
                    <AddingLinkScreen onChange={updateLink} defaultValue={content?.Link || undefined}/>}
                    {addingLink === 'tag' &&
                    <TaggingUsersScreen onChange={updateTaggedUsers} defaultValue={content?.TaggedUsers}/>}
                </ComponentsWrapper>
            </Portal>}
        </>
    )
}


export default MediaWrapper