import {PagesState, PageState} from "./PageProvider";
import {CompState} from "../../lib/Models/Component";
import {getPageIndex, toPlainObject} from "../../helpers";
import {IProof, publishMag} from "../../lib/Models/Mag";
import {createMasterBlock} from "../../lib/Models/Block";
import {firestore} from "../../Integrations/firebase";
import MagmaBlockEntity from "../../lib/MagmaBlockEntity";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import {debounce, isNil, remove, sortBy, uniqBy} from "lodash";
import {initiateScreenshotTimer, stopScreenshotTimer} from "../../screenshotUtils";


interface StateAction {
    type?: 'CHANGE_ORDER' | 'PUBLISH_MAG' | 'SET_BLOCKS' | 'DELETE_BLOCK' | 'ADD_BLOCK_CONTENT' | 'SET_MAG' | 'REMOVE_BLOCK_CONTENT' | 'SET_BLOCK_CONTENT' | 'SET_MAG_WITH_STORAGE' | 'SET_ACTIVE_MAG_ID' | 'SET_PAGES' | 'ADD_PAGE' | 'REMOVE_PAGE' | 'SET_COMPONENTS' | 'SWITCH_PAGE' | 'SET_PROOFS' | null
    payload?: PageState | PageState[] | CompState[] | MagmaBlockEntity[] | IProof | number | string
}

export const saveToStorage = (value?: PagesState) => {
    if (!value) {
        return
    }

    if (!value?.ID) {
        return
    }


    firestore.doc(`/users/${value.PublisherID}/drafts/${value?.ID}/`)
        .set(value, {merge: true}).catch(e => console.error(e))
}

const saveToStorageDebouncers = {
    thirty_seconds: debounce((result) => saveToStorage(result), 3000),
    ten_seconds: debounce((result) => saveToStorage(result), 1000)
}

const PageReducer = (state: PagesState, action?: StateAction) => {
    if (!['SET_PROOFS', 'SET_MAG', 'SET_MAG_WITH_STORAGE', 'PUBLISH_MAG', 'ADD_PAGE', 'REMOVE_PAGE'].includes(action?.type as string)) {
        stopScreenshotTimer()
        initiateScreenshotTimer()
    }
    let result: PagesState | undefined

    const pageToUpdate = state?.Pages?.find(page => page.ID === state.activePage)
    const pageIndex = pageToUpdate ? state.Pages.indexOf(pageToUpdate) : undefined

    if (action) {
        switch (action.type) {
            case 'CHANGE_ORDER':

                const co_payload = action.payload as unknown as { direction: 'back' | 'front' | -1 | 1, id: string }


                if (typeof pageIndex === 'undefined' || typeof pageToUpdate === "undefined") {
                    return state
                }

                let _all_blocks = [...pageToUpdate.LayeredBlocks]
                const bgBlocks = remove(_all_blocks, ({component}) => component && component === 'Background')

                if (['back', 'front'].includes(co_payload.direction as string)) {

                    _all_blocks = sortBy(_all_blocks, ({id}) => id === co_payload.id ? (co_payload.direction === 'front' ? 4 : 0) : 1);
                    _all_blocks = [...bgBlocks, ..._all_blocks]

                    const _Pages = [...state.Pages]

                    // @ts-ignore
                    pageToUpdate.LayeredBlocks = _all_blocks
                    _Pages[pageIndex] = pageToUpdate

                    result = {
                        ...state,
                        Pages: _Pages
                    }

                    return state
                }
                if ([1, -1].includes(co_payload.direction as number)) {

                    const componentToMove = _all_blocks.find((component) => component.id === co_payload.id)
                    if (!componentToMove) {
                        return state
                    }
                    const componentToMoveIdx = _all_blocks.indexOf(componentToMove)


                    const componentToReplaceIdx = componentToMoveIdx + (co_payload.direction as number)

                    if (!_all_blocks[componentToReplaceIdx]) {
                        return state
                    }


                    _all_blocks[componentToMoveIdx] = {..._all_blocks[componentToReplaceIdx]}
                    _all_blocks[componentToReplaceIdx] = {...componentToMove}
                    _all_blocks = [...bgBlocks, ..._all_blocks]

                    const __Pages = [...state.Pages]

                    // @ts-ignore
                    pageToUpdate.LayeredBlocks = _all_blocks
                    __Pages[pageIndex] = pageToUpdate

                    result = {
                        ...state,
                        Pages: __Pages
                    }

                    return state
                }

                return state
            case 'PUBLISH_MAG':

                publishMag({draft: state})


                return state
            case 'SET_MAG':
                result = {
                    ...state,
                    // @ts-ignore
                    ...action.payload
                }

                return result
            case 'SET_MAG_WITH_STORAGE':
                result = {
                    ...state,
                    // @ts-ignore
                    ...action.payload
                }
                saveToStorage(result)
                return result
            case 'SET_ACTIVE_MAG_ID':

                result = {
                    ...state,
                    activeMagID: action.payload as string
                }

                return result
            case 'SET_PAGES':
                result = {
                    ...state,
                    Pages: action.payload as PageState[]
                };

                saveToStorage(result)

                return result
            case 'ADD_PAGE':

                if (action.payload && typeof action.payload === 'object' && 'LayeredBlocks' in action.payload && !Array.isArray(action.payload)) {
                    result = {
                        ...state,
                    };

                    const payload = action.payload as PageState
                    const _pages = [...result.Pages]
                    _pages.push(payload)

                    result.Pages = uniqBy(_pages, 'ID')

                    result.activePage = payload.ID
                    saveToStorage(result)
                    return result
                }

                return state
            case 'REMOVE_PAGE':
                result = {
                    ...state,
                };


                const pageToRemove = state?.Pages?.find(page => page.ID === action.payload)
                if (!pageToRemove) {
                    return state
                }


                result.Pages = state.Pages.filter(page => page.ID !== action.payload)


                if (action.payload === state.activePage) {
                    const indexToRemove = state.Pages.indexOf(pageToRemove)
                    result.activePage = state.Pages[indexToRemove - 1] ? state.Pages[indexToRemove - 1].ID : state.Pages[0].ID
                }

                saveToStorage(result)

                return result
            case 'SET_COMPONENTS':
                if (!(typeof action.payload === 'object') || 'components' in action.payload || !Array.isArray(action.payload)) {
                    return state
                }

                const [firstItem] = action.payload

                if (firstItem && 'components' in firstItem) {
                    return state
                }


                if (typeof pageIndex === 'undefined' || !pageToUpdate) {
                    return state
                }


                const Pages = [...state.Pages]

                // @ts-ignore
                pageToUpdate.LayeredBlocks = action.payload
                Pages[pageIndex] = pageToUpdate

                result = {
                    ...state,
                    Pages
                }

                window.dispatchEvent((new CustomEvent('stop_loading')))

                saveToStorageDebouncers.thirty_seconds(result)

                return result
            case 'SET_BLOCKS':


                if (typeof pageIndex === 'undefined' || !pageToUpdate) {
                    return state
                }


                const ___Pages = [...state.Pages]

                // @ts-ignore
                pageToUpdate.Blocks = action.payload
                ___Pages[pageIndex] = pageToUpdate

                result = {
                    ...state,
                    Pages: ___Pages
                }

                window.dispatchEvent((new CustomEvent('stop_loading')))

                saveToStorageDebouncers.ten_seconds(result)

                return result
            case 'REMOVE_BLOCK_CONTENT':

                if (!pageIndex || !pageToUpdate) {
                    return state
                }


                const _allPages = [...state.Pages]


                const _blocks = [...pageToUpdate.Blocks]

                let _blockToUpdate = pageToUpdate.Blocks.find((comp: MagmaBlockEntity) => comp.ID === action.payload)

                if (!_blockToUpdate) {
                    return
                }

                const _blockIndex = _blocks.indexOf(_blockToUpdate)

                delete _blockToUpdate.Content

                if (_blockToUpdate) {
                    _blocks[_blockIndex] = _blockToUpdate
                }

                pageToUpdate.Blocks = _blocks

                _allPages[pageIndex] = pageToUpdate

                result = {
                    ...state,
                    Pages: _allPages
                }

                saveToStorage(result)

                return result
            case 'SET_BLOCK_CONTENT':

                const payload = action.payload as unknown as { ID: string, Content: MagmaBlockContent<unknown> }

                if (!pageIndex || !pageToUpdate) {
                    return state
                }

                const allPages = [...state.Pages]


                const blocks = [...pageToUpdate.Blocks]

                let blockToUpdate = pageToUpdate.Blocks.find((comp: MagmaBlockEntity) => comp.ID === payload.ID)

                if (!blockToUpdate) {
                    return
                }

                const blockIndex = blocks.indexOf(blockToUpdate)

                blockToUpdate = {...blockToUpdate, IsPlaceholder: false, Content: payload.Content}

                if (blockToUpdate) {
                    blocks[blockIndex] = blockToUpdate
                }

                pageToUpdate.Blocks = blocks

                allPages[pageIndex] = pageToUpdate

                result = {
                    ...state,
                    Pages: allPages
                }

                window.dispatchEvent((new CustomEvent('stop_loading')))

                saveToStorageDebouncers.ten_seconds(result)

                return result
            case 'ADD_BLOCK_CONTENT':


                if (!pageIndex || !pageToUpdate) {
                    return state
                }

                const __allPages = [...state.Pages]


                pageToUpdate.Blocks.push(toPlainObject(action.payload) as MagmaBlockEntity)

                __allPages[pageIndex] = pageToUpdate

                result = {
                    ...state,
                    Pages: __allPages
                }
                window.dispatchEvent((new CustomEvent('stop_loading')))

                saveToStorage(result)

                return result
            case 'DELETE_BLOCK':
                if (!pageIndex || !pageToUpdate) {
                    return state
                }

                const ___allPages = [...state.Pages]


                const afterDeleteBlocks = pageToUpdate.Blocks.filter(block => block.ID !== action.payload)
                pageToUpdate.Blocks = afterDeleteBlocks
                ___allPages[pageIndex] = pageToUpdate


                result = {
                    ...state,
                    Pages: ___allPages
                }

                saveToStorage(result)

                return result
            case 'SET_PROOFS':
                let _newProofs = Array.isArray(state.Proofs) ? [...state.Proofs] : []


                const proofPayload = action.payload as unknown as { proof: IProof, pageID: string | undefined, ignoreBlocks?: boolean }

                proofPayload.pageID = window._SNAPSHOT_PAGE_ID
                if (!proofPayload.pageID) {
                    return state
                }

                const pageIndexToEdit = getPageIndex(proofPayload.pageID, state.Pages)

                _newProofs[pageIndexToEdit] = proofPayload.proof
                const _Pages = [...state.Pages]

                if (!_Pages || !_Pages[pageIndexToEdit] || !_Pages[pageIndexToEdit].Blocks || !(_Pages[pageIndexToEdit].Blocks.length > 1)) {
                    const block = createMasterBlock(_newProofs[pageIndexToEdit].preview)
                    if (!Array.isArray(_Pages[pageIndexToEdit].Blocks)) {
                        _Pages[pageIndexToEdit].Blocks = []
                    }

                    _Pages[pageIndexToEdit].Blocks[0] = block
                }

                _newProofs = _newProofs.filter(val => !isNil(val))

                result = {
                    ...state,
                    Pages: _Pages,
                    Proofs: _newProofs
                }

                saveToStorage(result)
                return result
            case 'SWITCH_PAGE':
                result = {
                    ...state,
                    activePage: action.payload as string
                }

                // @ts-ignore
                saveToStorage(result)
                return result
            default:
                return state;
        }
    }


    return state
};

export default PageReducer