import {RawDraftContentState} from "draft-js";

export type MagmaTextFormat = 'plainText' | 'html' | 'markdown'

type TMagmaTextEntity = {
    ID: string
    Text: string
    TextFormat?: MagmaTextFormat
    Alignment?: string
    Font?: string
    TextRaw?: RawDraftContentState
}

export const defaultMagmaTextFormat = 'html'


export default class MagmaTextEntity {
    ID: string
    Text: string
    TextFormat?: MagmaTextFormat
    Font?: string
    Alignment?: string
    TextRaw?: RawDraftContentState

    constructor({
                    ID,
                    Text,
                    TextFormat = defaultMagmaTextFormat, Font = "IBMPlex", Alignment = "natural",
                    TextRaw
                }: TMagmaTextEntity) {

        this.ID = ID
        this.Text = Text
        this.TextFormat = TextFormat
        this.Alignment = Alignment
        this.Font = Font

        if (TextRaw) {
            this.TextRaw = TextRaw
        }
    }
}

