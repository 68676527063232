import {MagData} from "../lib/Models/Mag";
import {FC, useContext} from "react";
import styled from "styled-components";
import {navigate} from "@reach/router";
import {PagesContext} from "../Providers/Page/PageProvider";

interface MagCard_Props {
    mag: MagData
    isPublished?: boolean
}


const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
`

const Card = styled.article`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }
`

const Image = styled.img`
  border-radius: 8px;
`

const MagCard: FC<MagCard_Props> = ({mag, isPublished}) => {
    // @ts-ignore
    const pagesStateDispatch = useContext(PagesContext)[1]

    return (
        <Card data-id={mag.ID} onClick={async () => {

            if (isPublished) {
                window.open(`https://www.magmanow.com/@${mag.Publisher.Username.toLowerCase()}/${mag.slug}`, '_blank')
                return
            }

            pagesStateDispatch({
                type: 'SET_ACTIVE_MAG_ID',
                payload: mag.ID
            })

            await navigate(`/create/${mag.ID}`)
        }}>
            <Image loading="lazy" alt={mag.Caption || ''}
                   width={300}
                   height={480}
                   src={mag.Thumbnail ? `${mag.Thumbnail}?tr=w-300,q-50` : `https://ik.imagekit.io/nginr/mg_zCdadca2h.png?tr=w-300,h-480,q-1`}/>
            <Overlay/>
        </Card>
    )
}

export default MagCard