import {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {get, groupBy, keys} from 'lodash'
import {getChildIndex} from "../helpers";
import {ChevronLeft} from "../icons";

type TSimpleCarousel<T = any> = {
    items: T[]
    labelKey?: string
    imageKey?: string
    itemsPerSlide?: number
    onSelect?: (item: T) => void
}

const NavItem = styled.button<{ active?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  color: ${props => props.active ? props.theme.colors.secondary.bg : props.theme.colors.textOnOnboard};
  font-weight: 600;

  &:hover {
    opacity: ${props => props.active ? 1 : 0.5};
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 60px;
`

const CarouselSlide = styled.div<{ itemsPerSlide: number }>`
  scroll-snap-align: center;
  width: ${props => 100 / props.itemsPerSlide}%;
  height: auto;
  flex-shrink: 0;
  margin: 0 3px;
  opacity: 0.5;
  pointer-events: none;

  & > * {
    transform: scale(0.6);
    transition: .2s ease;
  }
`

const CarouselContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ${CarouselSlide}.active {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    & > * {
      transform: scale(1);
    }

    &:hover {
      & > * {
        transform: scale(1.1);
      }
    }
  }
`
const Wrapper = styled.div`
  position: relative;
  padding: 0 30px;

`

const ActionButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  color: #000;
  border-radius: 50%;
  background: #fff;
  padding: 6px;

  &[disabled] {
    opacity: 0.3;
    cursor: auto;
  }
`

const NextButton = styled(ActionButton)`
  right: -50px;

  svg {
    transform: rotate(190deg);
  }
`
const PrevButton = styled(ActionButton)`
  left: -50px;
`

const SimpleCarousel: FC<TSimpleCarousel> = ({
                                                 items,
                                                 labelKey = 'label',
                                                 onSelect,
                                                 imageKey = 'image',
                                                 itemsPerSlide = 5
                                             }) => {


    const centerIndex = useMemo(() => {
        return Math.round(itemsPerSlide / 2) - 1
    }, [itemsPerSlide])

    const fillerSlidesCount = useMemo(() => {
        return itemsPerSlide - centerIndex - 1
    }, [itemsPerSlide, centerIndex])

    const allLabels = useMemo(() => {

        return keys(groupBy(items, labelKey))
    }, [items, labelKey])

    const CarouselRef = useRef<HTMLDivElement>(null)

    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [activeLabel, setActiveLabel] = useState<string>('All')


    const removeAllActiveClasses = () => {
        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const activeSlides = sliderContainer.querySelectorAll('.active')

        activeSlides.forEach(el => el.classList?.remove('active'))

    }


    const findAllActiveElements = useCallback(() => {
        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const activeSlides = sliderContainer.querySelectorAll('.visible')
        removeAllActiveClasses()

        const activeEl = Array.from(activeSlides)[centerIndex]
        if (activeEl) {
            setActiveIndex(getChildIndex(activeEl))
            activeEl?.classList?.add('active')

            setActiveLabel(activeEl.getAttribute('data-label') || 'All')

        }
    }, [centerIndex])


    const trackScroll = useCallback(() => {
        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const slides = sliderContainer.children

        Array.from(slides).map(slide => {

            const observer = new IntersectionObserver(function (entries) {

                if (entries[0].isIntersecting) {
                    slide.classList.add('visible')
                } else {
                    slide.classList.remove('visible')
                }

                findAllActiveElements()

            });

            observer.observe(slide);

            return {}
        })
    }, [findAllActiveElements])

    const canGoForward = useCallback(() => {

        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const elements = sliderContainer.children

        return activeIndex < elements.length - (fillerSlidesCount + 1)
    }, [activeIndex, fillerSlidesCount])

    const canGoBack = useCallback(() => {

        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return


        return activeIndex > fillerSlidesCount
    }, [activeIndex, fillerSlidesCount])


    const goToLabel = (label: string) => {

        const sliderContainer = CarouselRef.current

        const firstLabelItem = sliderContainer?.querySelector(`[data-label="${label}"]`)

        if (!firstLabelItem || !sliderContainer) return

        const elements = sliderContainer.children

        const firstLabelItemIndex = getChildIndex(firstLabelItem)

        setTimeout(() => {

            const indexesToShift = firstLabelItemIndex > activeIndex ? firstLabelItemIndex + fillerSlidesCount : firstLabelItemIndex - fillerSlidesCount
            elements[indexesToShift].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            })
        }, 0)


    }


    const goNext = useCallback(() => {


        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const elements = sliderContainer.children
        if (canGoForward()) {
            setTimeout(() => {
                elements[activeIndex + (fillerSlidesCount + 1)].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                })
            }, 0)

        }
    }, [activeIndex, canGoForward, fillerSlidesCount])

    const goPrevious = useCallback(() => {
        const sliderContainer = CarouselRef.current

        if (!sliderContainer) return

        const elements = sliderContainer.children
        if (canGoBack()) {
            setTimeout(() => {
                elements[activeIndex - (fillerSlidesCount + 1)].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            }, 0)

        }
    }, [activeIndex, canGoBack, fillerSlidesCount])


    useEffect(() => {

        trackScroll()

    }, [trackScroll])

    const handleSelection = useCallback((item) => {
        if (onSelect) {
            onSelect(item)
        }
    }, [onSelect])

    useEffect(() => {
        setTimeout(() => {
            CarouselRef?.current?.children[0].scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }, 0)
    }, [])

    return (
        <Wrapper>
            <Nav>
                {allLabels.map((label, idx) => <NavItem
                    onClick={() => goToLabel(label)}
                    active={label === activeLabel}
                    key={`nav-${idx}`}>
                    {label}
                </NavItem>)}
            </Nav>
            <CarouselContainer className={"carouselSlider"} ref={CarouselRef}>
                {[...Array(fillerSlidesCount)].map((n, idx) => (
                    <CarouselSlide itemsPerSlide={itemsPerSlide} key={`startMock${idx}`}/>
                ))}

                {items.map((item, idx) => (
                    <CarouselSlide itemsPerSlide={itemsPerSlide} key={idx}
                                   onClick={() => handleSelection(item)}
                                   data-label={get(item, labelKey, 'All')}>
                        <img src={get(item, imageKey)} loading={"lazy"}
                             alt={get(item, labelKey, '') + ' template thumbnail'}/>
                    </CarouselSlide>
                ))}

                {[...Array(fillerSlidesCount)].map((n, idx) => (
                    <CarouselSlide itemsPerSlide={itemsPerSlide} key={`mock${idx}`}/>
                ))}
            </CarouselContainer>
            <PrevButton disabled={!canGoBack()} onClick={() => goPrevious()}><ChevronLeft/></PrevButton>
            <NextButton disabled={!canGoForward()} onClick={() => goNext()}><ChevronLeft/></NextButton>
        </Wrapper>
    )
}

export default SimpleCarousel