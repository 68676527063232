import {FC, MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import ClickAwayListener from "react-click-away-listener";
import mousetrap from 'mousetrap'
import interact from 'interactjs'
import {inRange} from 'lodash'
import {useBus, useListener} from "react-bus";
import {
    getDragAngle,
    normalizeStorageRotation,
    radians_to_degrees,
    resizeAndStoreElement,
    translateAndStoreElement
} from "../helpers";
import {DEFAULT_TRANSFORM, IBounds} from "../lib/Models/Block";
import {fromString} from 'transformation-matrix'
import {convertPixelsToPoints, convertPointsToPixels} from "../lib/Models/Component";
import {stopScreenshotTimer} from "../screenshotUtils";


interface ModuleWrapper_Props {
    id: string
    onRemoveButtonClick: () => void
    onActive: () => void
    onInactive: () => void
    onUpdate: (transform: ITransform) => void
    draggable?: boolean
    resizable?: boolean
    rotatable?: boolean
    background?: boolean
    onDoubleClick?: (ev: MouseEvent<any>) => void
    resizableEdges?: {
        left: boolean
        right: boolean
        top: boolean
        bottom: boolean
    }
    initialSize?: [string, string]
    initialPos?: [number, number]
    componentMeta?: any
    sequence: number
}

const CenterGridX = styled.div<{ active?: boolean }>`
  display: block;
  height: auto;
  position: absolute;
  left: 50%;
  opacity: ${props => props.active ? 1 : 0};
  top: -100%;
  width: 1px;
  bottom: -100%;
  border-right: 2px dashed transparent;
  border-color: ${props => props.theme.colors.secondary.bg};
`

const Wrapper = styled.div`

  display: block;
  height: 100%;
  //* {
  //  user-select: none;
  //  pointer-events: none;
  //}
`

declare interface ITransform {
    bounds: IBounds
    frame: IBounds
    transform: number[]
}

const RootWrapper = styled.div<{ active: boolean, size?: string[], background?: boolean }>`

  transform-origin: center center;
  position: absolute;
  width: ${props => (props.size && props.size[0]) || '300px'};
  height: ${props => (props.size && props.size[1]) || '300px'};
  box-sizing: border-box;


  z-index: ${props => props.background ? 0 : 1};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-color: ${props => props.active ? props.theme.colors.secondary.bg : 'transparent'};
    border-style: solid;
    border-width: 3px;
  }

  ${props => !props.active &&
          `&:hover:after {
    border-style: dashed;
    border-color: ${props.theme.colors.secondary.bg};
  }`
  }
`

const ResizeButton = styled.div`
  width: 18px;
  height: 18px;
  background: #fff;
  border: 4px solid #fff;
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(53 71 90 / 20%);

  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  z-index: 11;

  &.top-0.left-0 {
    transform: translate(-50%, -50%);
  }

  &.top-0.right-0 {
    transform: translate(50%, -50%);
  }

  &.bottom-0.right-0 {
    transform: translate(50%, 50%);
  }

  &.bottom-0.left-0 {
    transform: translate(-50%, 50%);
  }
`

const RotateButton = styled.button<{ active: boolean }>`
  border: 4px solid #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='M15.25 18.48V15a.75.75 0 1 0-1.5 0v4c0 .97.78 1.75 1.75 1.75h4a.75.75 0 1 0 0-1.5h-2.6a8.75 8.75 0 0 0-2.07-15.53.75.75 0 1 0-.49 1.42 7.25 7.25 0 0 1 .91 13.34zM8.75 5.52V9a.75.75 0 0 0 1.5 0V5c0-.97-.78-1.75-1.75-1.75h-4a.75.75 0 0 0 0 1.5h2.6a8.75 8.75 0 0 0 2.18 15.57.75.75 0 0 0 .47-1.43 7.25 7.25 0 0 1-1-13.37z'/%3E%3C/svg%3E");
  background-position: center;
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-size: 11px;
  opacity: ${props => props.active ? 1 : 0};
  box-shadow: 0 0 5px 1px rgb(57 76 96 / 15%), 0 0 0 1px rgb(57 76 96 / 15%);
  z-index: 1;
  transform: translate(-50%, 30px);
  cursor: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'%3E%3Cdefs%3E%3Cfilter id='a' width='266.7%25' height='156.2%25' x='-75%25' y='-21.9%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 24V0h24v24z'/%3E%3Cg fill-rule='nonzero' filter='url(%23a)' transform='rotate(90 5.25 14.75)'%3E%3Cuse fill='%23000' fill-rule='evenodd' xlink:href='%23b'/%3E%3Cpath stroke='%23FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 12 12, auto;
  left: 50%;
  top: 100%;

`

const ModuleWrapper: FC<ModuleWrapper_Props> = ({
                                                    id,
                                                    onDoubleClick,
                                                    resizable,
                                                    rotatable,
                                                    draggable = true,
                                                    initialSize,
                                                    resizableEdges,
                                                    onRemoveButtonClick,
                                                    onActive,
                                                    onInactive,
                                                    componentMeta,
                                                    children,
                                                    onUpdate,
                                                    background,
                                                    initialPos = [0, 0],
                                                    sequence
                                                }) => {

    const bus = useBus()

    const [moduleActive, setModuleActive] = useState<boolean>(false)
    const [scale, setScale] = useState<number>(0.7)
    const [straight, setStraight] = useState<boolean>(false)

    const targetEl = useRef<HTMLDivElement>(null)
    const rotateEl = useRef<HTMLButtonElement>(null)

    useListener('scaleChange', val => setScale(val))

    const sendDeleteCommand = useCallback(() => {
        onRemoveButtonClick()
        mousetrap.unbind('backspace')
        return false
    }, [onRemoveButtonClick])

    const beginMoveHook = useCallback(() => {

        stopScreenshotTimer()

        !moduleActive && setModuleActive(true) && onActive()

    }, [moduleActive, onActive])

    const endHook = useCallback(() => {


        if (!targetEl.current) {
            return
        }

        const {x, y, w, h, matrix} = targetEl.current.dataset

        const _matrix = Object.values(fromString(
            matrix || `matrix(${DEFAULT_TRANSFORM.join(',')})`
        ))

        onUpdate({

            bounds: {
                Origin: [
                    convertPixelsToPoints(Number(x)),
                    convertPixelsToPoints(Number(y))
                ],
                Size: [
                    convertPixelsToPoints(Number(w)),
                    convertPixelsToPoints(Number(h))
                ]
            },
            frame: {
                Origin: [
                    convertPixelsToPoints(Number(x)),
                    convertPixelsToPoints(Number(y))
                ],
                Size: [
                    convertPixelsToPoints(Number(w)),
                    convertPixelsToPoints(Number(h))
                ]
            },
            transform: _matrix,
        })


    }, [onUpdate])

    const endMoveHook = useCallback(() => {
        bus.emit('centerX', false)

        endHook()
    }, [bus, endHook])

    const endResizeHook = useCallback(() => {
        endHook()
    }, [endHook])


    const dragMoveListener = useCallback((event: { target: any; dx: number; dy: number; speed: number; }) => {
        beginMoveHook()

        const target = event.target

        // keep the dragged position in the data-x/data-y attributes
        let x = (parseFloat(target.getAttribute('data-x')) || 0) + (event.dx / scale)
        const y = (parseFloat(target.getAttribute('data-y')) || 0) + (event.dy / scale)

        const inCenterRange = () => {
            if (!targetEl.current?.parentElement || event.speed > 100) {
                return false
            }

            let distance = x
            if (distance < 0) {
                distance = distance * -1
            }

            const centerPixel = targetEl.current.parentElement.offsetWidth / 2 - (target.offsetWidth / 2)

            if (inRange(distance, centerPixel - 10, centerPixel + 10)) {
                return true
            } else {
                bus.emit('centerX', false)
            }
        }

        if (inCenterRange() && targetEl.current?.parentElement) {
            x = targetEl.current.parentElement.offsetWidth / 2 - (target.offsetWidth / 2)
            bus.emit('centerX', true)
        }

        translateAndStoreElement({target, x, y})


        if (!resizable) {
            // if (componentMeta.label) {
            //     const w = target.firstChild.firstChild.clientWidth
            //     const h = target.firstChild.firstChild.clientHeight
            //
            //     resizeAndStoreElement({target, w, h})
            // }
        }


    }, [beginMoveHook, bus, resizable, scale])


    const makeItActive = useCallback(() => {
        onActive()
        mousetrap.bind('backspace', sendDeleteCommand)
        setModuleActive(true)
    }, [onActive, sendDeleteCommand])

    const makeItInteract = useCallback((): HTMLElement | undefined => {
        if (!targetEl.current) {
            return
        }

        let interactionFunc

        if (draggable) {
            interactionFunc = interact(targetEl.current)
                .draggable({
                    // disable inertial throwing
                    inertia: false,

                    // enable autoScroll
                    autoScroll: true,
                    listeners: {
                        // call this function on every dragmove event
                        move: dragMoveListener,
                        end: endMoveHook

                    }
                })
        }

        if (resizable) {
            interactionFunc?.resizable({
                preserveAspectRatio: false,
                edges: resizableEdges || {left: true, right: true, bottom: true, top: true},

                listeners: {
                    move(event) {
                        beginMoveHook()

                        const target = event.target
                        let x = (parseFloat(target.getAttribute('data-x')) || 0)
                        let y = (parseFloat(target.getAttribute('data-y')) || 0)

                        // translate when resizing from top or left edges
                        x += event.deltaRect.left * 2;
                        y += event.deltaRect.top * 2;


                        const w = event.rect.width * 2
                        const h = event.rect.height * 2


                        resizeAndStoreElement({target, w, h})
                        translateAndStoreElement({target, x, y})
                    },
                    end: endResizeHook
                },
                modifiers: [
                    interact.modifiers.restrictSize({
                        min: {width: 50, height: 50}
                    })
                ],

                inertia: false
            })
        }

        if (rotatable && rotateEl?.current) {
            interact(rotateEl.current)
                .draggable({

                    onstart: function (event) {
                        beginMoveHook()
                        if (!targetEl?.current) {
                            return
                        }
                        let box = targetEl?.current;
                        let rect = box.getBoundingClientRect();

                        // store the center as the element has css `transform-origin: center center`
                        // @ts-ignore
                        box.setAttribute('data-center-x', rect.left + rect.width / 2);
                        // @ts-ignore
                        box.setAttribute('data-center-y', rect.top + rect.height / 2);
                        // get the angle of the element when the drag starts
                        // @ts-ignore
                        box.setAttribute('data-angle', getDragAngle(event, box));
                    },
                    onmove: function (event) {
                        if (!targetEl?.current) {
                            return
                        }
                        let box = targetEl.current;

                        let pos = {
                            x: parseFloat(box.getAttribute('data-x') as string) || 0,
                            y: parseFloat(box.getAttribute('data-y') as string) || 0
                        };

                        let angle = getDragAngle(event, box);
                        let angleInDegrees = radians_to_degrees(angle)

                        if (angleInDegrees > -10 && angleInDegrees < 10) {
                            setStraight(true)
                            angle = 0
                        } else if (angleInDegrees > 80 && angleInDegrees < 100) {
                            setStraight(true)
                            angle = 1.5708
                        } else if (angleInDegrees < -80 && angleInDegrees > -100) {
                            setStraight(true)
                            angle = -1.5708
                        } else if (angleInDegrees < 190 && angleInDegrees > 170) {
                            setStraight(true)
                            angle = 3.14159
                        } else {
                            setStraight(false)
                        }

                        translateAndStoreElement({target: box, x: pos.x, y: pos.y})

                        // update transform style on dragmove
                        box.style.transform = `rotate( ${angle}rad)`;
                    },
                    onend: (e) => {
                        if (!targetEl?.current) {
                            return
                        }
                        makeItActive()
                        setStraight(false)


                        let box = targetEl.current;

                        box.setAttribute('data-angle', String(getDragAngle(e, box)));
                        box.setAttribute('data-matrix', getComputedStyle(box).transform);

                        endResizeHook()
                    }
                })
        }

        if (interactionFunc) {
            return interactionFunc.target as HTMLElement
        }
    }, [beginMoveHook, dragMoveListener, draggable, endMoveHook, endResizeHook, makeItActive, resizable, resizableEdges, rotatable])


    const makeItInActive = useCallback((ev: any) => {

        if (ev.target.dataset.disableMakingInactive === id) {
            return
        }

        mousetrap.unbind('backspace')
        setModuleActive(false)
        onInactive()
    }, [id, onInactive])

    const _onDoubleClick = (ev: MouseEvent<HTMLDivElement>) => {
        if (onDoubleClick) {
            onDoubleClick(ev)
        }
    }

    useEffect(() => {

        const targetEl = makeItInteract()

        if (targetEl) translateAndStoreElement({target: targetEl, x: initialPos[0], y: initialPos[1]})
        if (componentMeta && targetEl) {

            if (componentMeta.frame?.Origin) {
                translateAndStoreElement({
                    target: targetEl,
                    x: convertPointsToPixels(componentMeta.frame.Origin[0]),
                    y: convertPointsToPixels(componentMeta.frame.Origin[1])
                })
            }
            if (componentMeta.frame?.Size) {
                resizeAndStoreElement({
                    target: targetEl,
                    w: convertPointsToPixels(componentMeta.frame.Size[0]),
                    h: convertPointsToPixels(componentMeta.frame.Size[1])
                })
            }
            if (componentMeta.transform && Array.isArray(componentMeta.transform)) {
                normalizeStorageRotation({target: targetEl, transform: componentMeta.transform})
            }
        }

        onActive()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ClickAwayListener onClickAway={makeItInActive} mouseEvent={'mousedown'} touchEvent={'touchstart'}>
                <>
                    <RootWrapper
                        style={{zIndex: background ? 0 : 20 + sequence}}
                        data-disable-making-inactive={id}
                        size={initialSize}
                        onDoubleClick={_onDoubleClick}
                        onClick={makeItActive}
                        active={moduleActive}
                        ref={targetEl}
                        background={background}>
                        <Wrapper>
                            {children}
                        </Wrapper>

                        {resizable && moduleActive &&
                        <>
                            <ResizeButton data-html2canvas-ignore className={'top-0 left-0'}/>
                            <ResizeButton data-html2canvas-ignore className={'top-0 right-0'}/>
                            <ResizeButton data-html2canvas-ignore className={'bottom-0 right-0'}/>
                            <ResizeButton data-html2canvas-ignore className={'bottom-0 left-0'}/>
                        </>}

                        {rotatable && <>
                            <CenterGridX data-html2canvas-ignore active={straight}/>
                            <RotateButton data-html2canvas-ignore active={moduleActive} ref={rotateEl}/>
                        </>}

                    </RootWrapper>
                </>
            </ClickAwayListener>
        </>
    )

}

export default ModuleWrapper