import {FC, useCallback, useContext, useState} from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import {convertScalePercentageToDecimal} from "../helpers";
import {useBus, useListener} from "react-bus";
import {Fullscreen} from "../icons";
import {PagesContext} from "../Providers/Page/PageProvider"
import {round} from 'lodash'
import {navigate} from "@reach/router";

declare interface ICanvasControls_Props {
    onScaleChange?: (scale: number) => void
    onPublishingMag?: () => void
    visibleSidebar: boolean
}

const RootWrapper = styled.section<{ visibleSidebar: boolean }>`
  position: fixed;
  top: 76px;
  left: ${props => props.visibleSidebar ? '400px' : 0};
  right: 0;
  background: ${props => props.theme.colors.canvasControlsBackground};
  text-align: center;
  padding: 10px 0;
  transform: translate3d(0);
  transition: .3s ease;
  ${props => props.theme.name === 'lightTheme' && `
    border-bottom: 1px solid #394c6026;
  `}


`
const SliderRoot = styled(Slider)`

`

const FullScreenButton = styled.div`
  cursor: pointer;
  font-size: 1em;
  vertical-align: middle;

  &:hover {
    background: #fff;
    color: #000;
  }
`
const DoneButton = styled.button`
  cursor: pointer;
  vertical-align: middle;
  position: absolute;
  right: ${props => props.theme.name === 'darkTheme' ? '115px' : '100px'};
  background: ${props => props.theme.colors.donButtonBg};
  padding: 4px 15px;
  margin: -4px 0;

  &:hover {
    background: #000;
    color: ${props => props.theme.colors.secondary.bg}
  }
`

const PublishButton = styled.button<{ active: boolean }>`
  vertical-align: middle;
  position: absolute;
  right: 15px;
  padding: 4px 15px;
  margin: -4px 0;
  background: ${props => props.active ? props.theme.colors.donButtonBg : 'rgba(255,255,255,0)'};

  opacity: ${props => props.active ? '1' : '0.4'};
  touch-action: ${props => props.active ? 'auto' : 'none'};
  cursor: ${props => props.active ? 'pointer' : 'default'};

  &:hover {
    background: ${props => props.active ? '#000' : 'transparent'};
    color: ${props => props.active ? props.theme.colors.secondary.bg : 'inherit'}
  }
`


const SizeWrapper = styled.div`
  padding: 0 20px;
  position: relative;
  width: 200px;
  display: inline-block;
  margin: 0 auto;
  transform: translateY(2px);

  &:before {
    content: '-';
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
  }

  &:after {
    content: '+';
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
  }
`

const CanvasControls: FC<ICanvasControls_Props> = ({onScaleChange, onPublishingMag, visibleSidebar}) => {

    // @ts-ignore
    const [pagesState] = useContext(PagesContext)

    const [zoom, setZoom] = useState<number>(100)

    const bus = useBus()

    const isMagPublishable = () => {

        if (!Array.isArray(pagesState?.Pages)) {
            return false
        }

        return pagesState?.Pages.length >= 2
    }

    useListener('scaleChange', val => {
        setZoom(val * 100)
        if (onScaleChange) {

            setTimeout(() => {
                onScaleChange(convertScalePercentageToDecimal(val))

            }, 200)
        }
    })

    useListener('focusModeEnabled', val => {
        bus.emit('scaleChange', val)
    })

    const handleZoom = useCallback((val: number) => {

        setZoom(val)
        bus.emit('scaleChange', convertScalePercentageToDecimal(val))

        if (onScaleChange) {

            setTimeout(() => {
                onScaleChange(convertScalePercentageToDecimal(val))

            }, 200)
        }

    }, [onScaleChange, bus])

    const handlePublish = useCallback(() => {

        if (typeof onPublishingMag !== 'function') {
            return
        }
        onPublishingMag()

    }, [onPublishingMag])


    const handleDone = useCallback(() => {
        setTimeout(() => {
            navigate('/drafts')
        }, 0)
    }, [])

    return (
        <RootWrapper visibleSidebar={visibleSidebar}>
            <FullScreenButton className={"inline-block mr-4"} onClick={() => handleZoom(100)}>
                <Fullscreen/>
            </FullScreenButton>
            <SizeWrapper>
                <SliderRoot min={30} max={200}
                            onChange={handleZoom}
                            railStyle={{
                                background: '#c1c1c1',
                                height: '2px'
                            }}
                            handleStyle={{
                                background: '#c1c1c1',
                                border: 'none',
                                width: '10px',
                                height: '10px',
                            }}
                            trackStyle={{
                                background: '#c1c1c1',
                                height: '2px'
                            }}

                            value={zoom}
                />
            </SizeWrapper>
            <span className={"ml-4 inline-block -mb-1 w-8 text-sm"}>
            {round(zoom)}%
            </span>

            <PublishButton onClick={handlePublish} active={isMagPublishable()} disabled={!isMagPublishable()}>
                Publish
            </PublishButton>

            <DoneButton onClick={handleDone}>
                Save & Exit
            </DoneButton>
        </RootWrapper>
    )
}

export default CanvasControls