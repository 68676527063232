import {FC, useEffect, useState} from "react";

import {FilePond, registerPlugin} from 'react-filepond'

import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {FilePondFile} from "filepond";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode)

interface IUploader_Props {
    onUpdateFiles: (file: FilePondFile | null) => void
    url: string
}

const Uploader: FC<IUploader_Props> = ({onUpdateFiles, url}) => {

    const [files, setFiles] = useState<any[]>([])

    const handleFileUpload = (file: FilePondFile) => {

        if (!file) return

        if (url && url.indexOf(file.filename) > -1) {
            return
        }

        if (file.filename.indexOf('assets.magma.sh/templates') > -1) {
            return
        }

        onUpdateFiles(file)

    }

    useEffect(() => {

        url && setFiles([url])
    }, [url])


    return (
        <div>
            <FilePond
                files={files}
                onaddfile={(error, file) => handleFileUpload(file)}
                allowReplace={true}
                allowMultiple={false}
                onremovefile={() => onUpdateFiles(null)}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        </div>
    )
}

export default Uploader