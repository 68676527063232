import MagmaBlockEntity from "../MagmaBlockEntity";
import cuid from "cuid";
import MagmaBlockLayout from "../MagmaBlockLayout";
import MagmaBlockInsets from "../MagmaBlockInsets";
import MagmaBlockLayoutMode from "../MagmaBlockLayoutMode";
import MagmaBlockContent from "../Content/MagmaBlockContent";
import MagmaTextEntity from "../Content/MagmaTextEntity";

const PageTemplatesCategories = [
    {
        name: 'Article',
        keyword: 'article',
        templates: [
            {
                id: "article1a",
                thumbnail: `/Assets/PageTemplates/template_article1a.imageset/template_article1a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 10, Right: 10, Bottom: 5, Left: 10}),
                            AspectRatio: [1, 1],
                            Mode: new MagmaBlockLayoutMode({Width: 0})
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                    <p>Scourge of the seven seas walk the plank red ensign chandler blow the man down gangplank lad warp maroon pinnace. Yardarm hulk rigging long clothes boom run a rig pink handsomely hornswaggle spanker. Case shot mutiny spirits furl scuppers hang the jib ahoy spike galleon broadside.</p>
                                    <p>Parley Admiral of the Black log cog hulk pillage deadlights coffer Brethren of the Coast brig. Dead men tell no tales blow the man down boom pinnace yawl scuttle brig Nelsons folly starboard killick. Crow's nest measured fer yer chains ahoy loot lateen sail nipperkin scuppers Jolly Roger crack Jennys tea cup run a shot across the bow.</p>
                                `

                            })
                        })
                    })
                ]
            },
            {
                id: "article1b",
                thumbnail: `/Assets/PageTemplates/template_article1b.imageset/template_article1b.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 20, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <h1>Title goes here</h1>
                                     <h2>Subtitle goes here</h2>
                                 `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({vertical: 5, horizontal: 10}),
                            AspectRatio: [1, 1],
                            Mode: new MagmaBlockLayoutMode({Width: 0})
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                        <p>Hail-shot marooned aft driver spanker rigging Privateer Sink me Yellow Jack stern. Knave deadlights plunder hail-shot killick jury mast American Main interloper code of conduct pressgang. Heave down gabion Jack Tar ho galleon yardarm me red ensign rutters jib.</p>
                        <p>Trysail run a rig bilged on her anchor six pounders boom hornswaggle barkadeer gangway jury mast chase guns. Strike colors line topsail Nelsons folly piracy coffer parrel Privateer Davy Jones' Locker squiffy. Jury mast gangway interloper brigantine skysail lad Nelsons folly hearties belaying pin barkadeer.</p>
    `

                            })
                        })
                    })
                ]
            },
            {
                id: "article2a",
                thumbnail: `/Assets/PageTemplates/template_article2a.imageset/template_article2a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 10, Right: 10, Bottom: 5, Left: 10}),
                            AspectRatio: [4, 3],
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({vertical: 15, horizontal: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                    <p>Bounty keel long clothes hang the jib bowsprit cutlass starboard boatswain crack Jennys tea cup Yellow Jack. Topsail sheet spirits pirate jib case shot keel gally lee booty. Avast fire in the hole Blimey scourge of the seven seas gibbet handsomely me cable overhaul hardtack.</p>
                                `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({vertical: 5, horizontal: 10}),
                            AspectRatio: [1, 1],
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <p>Davy Jones' Locker spike bilge water shrouds booty marooned plunder sutler Barbary Coast starboard. Mizzen prow cable Chain Shot ho chantey Buccaneer hail-shot brig gangway.</p>
                                `
                            })
                        })
                    })
                ]
            },
            {
                id: "article2b",
                thumbnail: `/Assets/PageTemplates/template_article2b.imageset/template_article2b.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 20, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 10}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({vertical: 15, horizontal: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <p>Aft Barbary Coast six pounders Shiver me timbers Sail ho wench boom tackle barkadeer belaying pin. Blow the man down salmagundi crack Jennys tea cup yard yawl smartly Nelsons folly Admiral of the Black come about aye. Take a caulk Shiver me timbers capstan ye landlubber or just lubber crimp Jack Tar yo-ho-ho hang.</p>
                                `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({vertical: 5, horizontal: 10}),
                            AspectRatio: [4, 3],
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <p>Shiver me timbers bring a spring upon her cable jolly boat Sea Legs bounty Gold Road ahoy to go on account hogshead fluke. Rutters pinnace Blimey case shot ahoy port jolly boat sheet reef sails prow.</p>
                                `
                            })
                        })
                    })
                ]
            },
            {
                id: "article2c",
                thumbnail: `/Assets/PageTemplates/template_article2c.imageset/template_article2c.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 10, Left: 20, Bottom: 5, Right: 5}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <p>Prow reef gaff topmast bring a spring upon her cable chandler cable yard furl Privateer. Hearties jolly boat keelhaul black jack list grog blossom gabion me Gold Road aft.</p>
                                `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 10, Left: 5, Bottom: 5, Right: 10}),
                            AspectRatio: [5, 8],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5}),
                            AspectRatio: [5, 8],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <p>Cog walk the plank lugger sloop overhaul yawl draught hulk aye fire in the hole. Boom Arr chantey avast hardtack brigantine spirits spanker capstan aye. Pieces of Eight red ensign bucko rutters.</p>
                                `

                            })
                        })
                    })
                ]
            },
            {
                id: "article3a",
                thumbnail: `/Assets/PageTemplates/template_article3a.imageset/template_article3a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 20, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                     <h2>Subtitle goes here</h2>
                                     <p>Gangplank parrel Barbary Coast hempen halter Admiral of the Black skysail belaying pin black spot gangway bilged on her anchor. </p>         
                                `

                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 10}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 5, Bottom: 5, Left: 10}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 5}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        IsPlaceholder: true,
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                     <p>Draught galleon Sail ho hogshead Pieces of Eight aft scuppers dance the hempen jig Sink me clipper. Scallywag lee hang the jib wherry schooner swing the lead jolly boat cackle fruit dance the hempen jig coffer. Lee Sail ho nipperkin galleon fire ship skysail scourge of the seven seas bucko Davy Jones' Locker fluke.</p>
                        <p>Rutters gangway come about long boat cable aye carouser topmast galleon strike colors.</p>
                                `
                            })
                        })
                    })
                ]
            },
            {
                id: "article3b",
                thumbnail: `/Assets/PageTemplates/template_article3b.imageset/template_article3b.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                    <p>Gunwalls gabion haul wind heave down flogging Pieces of Eight nipper black jack scurvy Yellow Jack. Chase guns starboard come about reef sails stern ye weigh anchor black spot lateen sail strike colors. Red ensign bowsprit flogging warp trysail lad shrouds wench grog blossom plunder.</p>         
                                `
                            })
                        })

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 5}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({
                                Width: 2
                            }),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 5, Bottom: 5, Left: 10}),
                            AspectRatio: [1, 1],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <p>Keel lugger tackle stern heave down lateen sail grapple shrouds brig jib. Belay Davy Jones' Locker main sheet clipper capstan salmagundi crack Jennys tea cup come about lugsail lanyard. Coxswain lateen sail lad fore rope's end avast case shot square-rigged list grog blossom.</p>
                        <p>Snow crack Jennys tea cup American Main bounty run a rig coxswain Gold Road piracy to go on account line.</p>         
                                `
                            })
                        })

                    }),
                ]
            },
            {
                id: "article3c",
                thumbnail: `/Assets/PageTemplates/template_article3c.imageset/template_article3c.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                    <p>Tackle Pirate Round ho avast dead men tell no tales lanyard weigh anchor careen flogging jolly boat. Sink me hulk grapple main sheet gunwalls sutler chase guns warp to go on account lad. Cutlass fathom bilge rat topmast hempen halter splice the main brace fire ship carouser black spot wench.</p>         
                                `
                            })
                        })

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 10}),
                            AspectRatio: [4, 3],
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 10, Left: 10}),
                            AspectRatio: [4, 3],
                        }),
                    })

                ]
            },
            {
                id: "article4a",
                thumbnail: `/Assets/PageTemplates/template_article4a.imageset/template_article4a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 20, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>         
                                `
                            })
                        })

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <p>Gunwalls gabion haul wind heave down flogging Pieces of Eight nipper black jack scurvy Yellow Jack. Chase guns starboard come about reef sails stern ye weigh anchor black spot lateen sail strike colors. Red ensign bowsprit flogging warp trysail lad shrouds wench grog blossom plunder.</p>         
                                `
                            })
                        })

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 10}),
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 10, Bottom: 5, Left: 5}),
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Right: 5, Bottom: 5, Left: 10}),
                        }),
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <p>Keel lugger tackle stern heave down lateen sail grapple shrouds brig jib. Belay Davy Jones' Locker main sheet clipper capstan salmagundi crack Jennys tea cup come about lugsail lanyard. Coxswain lateen sail lad fore rope's end avast case shot square-rigged list grog blossom.</p>         
                                `
                            })
                        })

                    }),
                ]
            },
            {
                id: "article5a",
                thumbnail: `/Assets/PageTemplates/template_article5a.imageset/template_article5a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 15, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                    <h1>Title goes here</h1>
                        <h2>Subtitle goes here</h2>
                        <p>Gally handsomely lanyard Jack Tar heave to dead men tell no tales lateen sail rigging grog blossom parley. Heave down yardarm strike colors Nelsons folly Sail ho dead men tell no tales me snow fathom pinnace. Black jack parrel code of conduct sutler lookout Davy Jones' Locker long clothes handsomely.</p>
                                `
                            })
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10}),
                        }),
                        IsPlaceholder: true,
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "text",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                        }),
                        Content: new MagmaBlockContent<MagmaTextEntity>({
                            type: "Text",
                            content: new MagmaTextEntity({
                                ID: cuid(),
                                Text: `
                                      <p>Salmagundi rigging lookout scourge of the seven seas league mutiny dead men tell no tales hogshead American Main skysail. Cutlass reef flogging knave weigh anchor bilged on her anchor mizzen sloop come about long boat. Execution dock run a rig clap of thunder bilge dead men tell no tales.</p>
                                `
                            })
                        })
                    }),
                ]
            },

        ]
    },
    {
        name: 'Gallery',
        keyword: 'gallery',
        templates: [
            {
                id: "gallery1a",
                thumbnail: `/Assets/PageTemplates/template_gallery1a.imageset/template_gallery1a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Insets: new MagmaBlockInsets({edges: 10}),
                            AspectRatio: [5, 8],
                            Mode: new MagmaBlockLayoutMode({Width: 0})
                        }),

                    })
                ]
            },
            {
                id: "gallery2a",
                thumbnail: `/Assets/PageTemplates/template_gallery2a.imageset/template_gallery2a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10})
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 10})
                        }),

                    })
                ]
            },
            {
                id: "gallery3a",
                thumbnail: `/Assets/PageTemplates/template_gallery3a.imageset/template_gallery3a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10})
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5})
                        }),

                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 10})
                        }),

                    })
                ]
            },
            {
                id: "gallery4a",
                thumbnail: `/Assets/PageTemplates/template_gallery4a.imageset/template_gallery4a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [5, 8],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [5, 8],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [5, 8],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [5, 8],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 10})
                        })
                    })
                ]
            },
            {
                id: "gallery4b",
                thumbnail: `/Assets/PageTemplates/template_gallery4b.imageset/template_gallery4b.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 10})
                        })
                    }),
                ]
            },
            {
                id: "gallery4c",
                thumbnail: `/Assets/PageTemplates/template_gallery4c.imageset/template_gallery4c.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [4, 3],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 10})
                        })
                    }),
                ]
            },
            {
                id: "gallery5a",
                thumbnail: `/Assets/PageTemplates/template_gallery5a.imageset/template_gallery5a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 10})
                        })
                    }),

                ]
            },
            {
                id: "gallery5b",
                thumbnail: `/Assets/PageTemplates/template_gallery5b.imageset/template_gallery5b.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 3}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 3}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 3}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 0}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 10})
                        })
                    }),
                ]
            },
            {
                id: "gallery6a",
                thumbnail: `/Assets/PageTemplates/template_gallery6a.imageset/template_gallery6a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [3, 4],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 10})
                        })
                    }),
                ]
            },
            {
                id: "gallery8a",
                thumbnail: `/Assets/PageTemplates/template_gallery8a.imageset/template_gallery8a.jpg`,
                blocks: [
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 10, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 5, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 5, Right: 10})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 10, Bottom: 10, Right: 5})
                        })
                    }),
                    new MagmaBlockEntity({
                        ID: cuid(),
                        Kind: "media",
                        Layout: new MagmaBlockLayout({
                            Mode: new MagmaBlockLayoutMode({Width: 2}),
                            AspectRatio: [1, 1],
                            Insets: new MagmaBlockInsets({Top: 5, Left: 5, Bottom: 10, Right: 10})
                        })
                    }),
                ]
            }

        ]
    }

]

export default PageTemplatesCategories