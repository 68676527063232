import React, {FC, useCallback, useContext, useMemo} from 'react'
import BlockLayout from "./BlockLayout";
import BlockContent from "./BlockContent";
import MediaWrapper from "./MediaWrapper";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import {PagesContext} from "../../Providers/Page/PageProvider";
import MagmaBlockEntity from "../../lib/MagmaBlockEntity";
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
import {placeholderAsset} from "../../helpers";
import TextWrapper from './TextWrapper';
import {isNil, omitBy} from "lodash";

const Block: FC<MagmaBlockEntity & { snapshot?: boolean }> = ({
                                                                  children,
                                                                  IsPlaceholder,
                                                                  ID,
                                                                  Kind,
                                                                  Content,
                                                                  Layout,
                                                                  snapshot
                                                              }) => {

    // @ts-ignore
    const dispatchPagesState = useContext(PagesContext)[1]

    const handleContentUpdate = (content: MagmaBlockContent<unknown>) => {
        const plainContent = omitBy(content, isNil)

        dispatchPagesState({
            type: 'SET_BLOCK_CONTENT',
            payload: {
                ID,
                Content: plainContent
            }
        })
    }

    const handleContentDelete = () => {
        dispatchPagesState({
            type: 'REMOVE_BLOCK_CONTENT',
            payload: ID
        })
    }

    const handleDeleteBlock = useCallback(() => {
        dispatchPagesState({
            type: 'DELETE_BLOCK',
            payload: ID
        })
    }, [ID, dispatchPagesState])


    const isPlaceholder = useMemo(() => {
        return IsPlaceholder || !Content
    }, [IsPlaceholder, Content])

    const isMediaBlock = useMemo(() => {
        return Kind === 'media'
    }, [Kind])

    return (
        <BlockLayout {...Layout} Content={Content}>

            {isMediaBlock && !snapshot &&
            <MediaWrapper onDeleteBlock={() => handleContentDelete()} Content={Content}
                          updateContent={handleContentUpdate}>
                {isPlaceholder ?
                    <BlockContent
                        snapshot={snapshot}
                        IsPlaceholder={IsPlaceholder}
                        Photo={new MagmaPhotoEntity({ID: "saddsa", TaggedUsers: [], Asset: placeholderAsset})}/>
                    : <BlockContent snapshot={snapshot} onUpdate={handleContentUpdate} IsPlaceholder={IsPlaceholder}
                                    {...Content}/>}
            </MediaWrapper>}

            {isMediaBlock && snapshot &&
            (isPlaceholder ?
                <BlockContent
                    snapshot={snapshot}
                    IsPlaceholder={IsPlaceholder}
                    Photo={new MagmaPhotoEntity({ID: "saddsa", TaggedUsers: [], Asset: placeholderAsset})}/>
                : <BlockContent snapshot={snapshot} onUpdate={handleContentUpdate} IsPlaceholder={IsPlaceholder}
                                {...Content}/>)
            }

            {!isMediaBlock &&
            <TextWrapper><BlockContent
                snapshot={snapshot}
                onDeleteBlock={handleDeleteBlock} {...Content} IsPlaceholder={isPlaceholder}
                onUpdate={handleContentUpdate}/></TextWrapper>}
        </BlockLayout>
    )
}

export default Block
