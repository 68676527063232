import {FC, MouseEventHandler, useCallback, useRef, useState} from "react";
import styled from "styled-components";
import {IPrivacy, PrivacyOptions} from "../lib/Models/Privacy";
import ClickAwayListener from "react-click-away-listener";
import {CheckBold, ChevronLeft} from "../icons";

declare interface IPrivacySelector_Props {
    onSelectionChange?: (privacy: IPrivacy) => void
    initialValue?: string
}

const RootContainer = styled.div`
  position: relative;
  z-index: 96;
`
const InputWrapper = styled.div`
  position: relative;
  display: block;
`
const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  background: ${props => props.theme.colors.textFieldBg};
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  cursor: pointer;
`;

const DropArrow = styled.div<{ active: boolean }>`
  transform: ${props => props.active ? 'rotate(90deg) translateX(-50%)' : 'rotate(-90deg) translateX(50%)'};
  display: inline-block;
  transform-origin: center;
  position: absolute;
  right: 12px;
  top: 50%;
  z-index: 9;
  font-size: 28px;
  color: #8e8e8e;
  cursor: pointer;
  pointer-events: ${props => props.active ? 'auto' : 'none'};

  &:hover {
    color: ${props => props.active ? '#5a5a5a' : '#8e8e8e'};
  }
`

const OptionsContainer = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.overlayTextFieldBg};
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 1px rgb(64 87 109 / 7%), 0 2px 12px rgb(53 71 90 / 20%);
  max-height: 400px;
  overflow: auto;
`

const SlotContainer = styled.div<{ active: boolean }>`
  padding: 14px 15px;
  cursor: pointer;
  position: relative;

  .label {
    font-weight: 600;
  }

  p {
    font-size: 15px;
    color: #777777;
  }

  &:hover {
    background: ${props => props.active ? `rgba(${props.theme.colors.secondary.bgColor}, 0.1)` : '#414141'};
    ${props => props.active ? '' : 'color: #fff;'}

  }

  background: ${props => props.active ? `rgba(${props.theme.colors.secondary.bgColor}, 0.1)` : 'transparent'};
`
const ActiveCheck = styled.div`
  position: absolute;
  right: 10px;
  font-size: 1.3em;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.secondary.bg}
`


const Slot: FC<{ privacy: IPrivacy, active: boolean, onClick: MouseEventHandler<HTMLDivElement> }> = ({
                                                                                                          privacy,
                                                                                                          active,
                                                                                                          onClick
                                                                                                      }) => {
    return (
        <SlotContainer active={active} onClick={(e) => {
            onClick(e)
            e.preventDefault()
        }}>

            <div className={"label"}>
                {privacy.Label || privacy.Name}
            </div>
            <p>
                {privacy.Description}
            </p>

            {active && <ActiveCheck><CheckBold/></ActiveCheck>}
        </SlotContainer>
    )
}

const Options: FC<{ options: IPrivacy[], selected: IPrivacy | null | undefined, onSelect: (privacy: IPrivacy) => void }> = ({
                                                                                                                                options,
                                                                                                                                selected,
                                                                                                                                onSelect
                                                                                                                            }) => {

    const handleItemSelection = (privacy: IPrivacy) => {
        onSelect(privacy)
    }

    return (
        <OptionsContainer>
            {options.map((privacy, idx) => {
                return (
                    <Slot active={privacy === selected} onClick={() => handleItemSelection(privacy)} privacy={privacy}
                          key={idx}/>
                )
            })}
        </OptionsContainer>
    )
}

const PrivacySelector: FC<IPrivacySelector_Props> = ({onSelectionChange, initialValue}) => {

    const [active, setActive] = useState<boolean>(false)
    const [userSelection, setUserSelection] = useState<IPrivacy | undefined>(PrivacyOptions.find(opt => opt.Name === initialValue))
    const InputRef = useRef<HTMLInputElement>(null)


    const handleUserSelection = useCallback((privacy: IPrivacy) => {

        if (InputRef?.current) {
            InputRef.current.value = privacy.Label || privacy.Name
        }
        setActive(false)
        setUserSelection(privacy)

        if (onSelectionChange) {
            onSelectionChange(privacy)
        }

    }, [onSelectionChange])


    return (
        <RootContainer>
            <ClickAwayListener onClickAway={() => setActive(false)}>
                <InputWrapper>
                    <Input defaultValue={userSelection?.Label || userSelection?.Name} required ref={InputRef}
                           readOnly={true} placeholder={"Pick A Privacy Setting"}
                           onFocus={() => setActive(true)}/>
                    <DropArrow onClick={() => setActive(false)} active={active}>
                        <ChevronLeft/>
                    </DropArrow>
                    {active && <Options
                        onSelect={handleUserSelection} selected={userSelection} options={PrivacyOptions}/>}
                </InputWrapper>
            </ClickAwayListener>
        </RootContainer>
    )
}

export default PrivacySelector