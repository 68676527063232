import React, {FunctionComponent} from 'react'
import styled from "styled-components";
import MagmaLocationEntity from "../../lib/Content/MagmaLocationEntity";

const Wrapper = styled.div<{ hasCaption: boolean }>`
  height: 100%;
`

const Figure = styled.figure`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`


const LocationContent: FunctionComponent<MagmaLocationEntity> = ({Attributes, Thumbnail, Caption}) => (
    <>
        <Wrapper hasCaption={!!Caption}>
            <Figure>
                <Image
                    draggable={false}
                    src={Attributes ? Attributes.Thumbnail.URL : Thumbnail || ''}
                    alt={Caption || ''}
                />
            </Figure>
        </Wrapper>
    </>
)

export default LocationContent
