import MagmaDesignEntity from "../../LayeredBlocks/MagmaDesignEntity";
import MagmaLabelEntity from "../../LayeredBlocks/MagmaLabelEntity";
import { MagmaLayeredBlockMetaEntity } from "../../LayeredBlocks/MagmaLayeredBlockEntity";

const TravelCategory: Record<string, Record<string, MagmaLayeredBlockMetaEntity>> = {
    travel_1: {
        badge: new MagmaDesignEntity({
            editable: true,
            asset: {
                ID: "travel-1/travel_badge",
                URL: "https://assets.magma.sh/templates/travel-1/travel_badge.png",
                FileType: "png",
                Directory: "travel-1/travel-bkg-image"
            },
            isLogo: false,
            scale: 1,
            id: "travel-1/travel_badge"
        }),
        background: new MagmaDesignEntity({
            editable: true,
            isLogo: false,
            id: "travel-1/travel-bkg-image",
            asset: {
                ID: "travel-1/travel-bkg-image",
                URL: "https://assets.magma.sh/templates/travel-1/travel-bkg-image.png",
                Directory: "travel-1/travel-bkg-image",
                FileType: "png"
            },
            scale: 1
        }),
        label_1: new MagmaLabelEntity({
            numberOfLines: 2,
            fontSize: 32,
            lineHeight: 1.1,
            text: "A TRIP ACROSS \n AMERICA",
            fontColor: {
                green: 1.000072956085205,
                alpha: 1,
                blue: 0.9997498393058777,
                red: 0.9997816681861877
            },
            fontName: "Poppins-Regular",
            characterSpacing: 2
        }),
        label_2: new MagmaLabelEntity({
            text: "TRANSIT",
            characterSpacing: 12,
            fontColor: {
                alpha: 0.725,
                red: 1.0649254322052002,
                blue: 0.44274359941482544,
                green: 0.5708054900169373
            },
            fontSize: 153,
            lineHeight: 1,
            fontName: "SixCaps",
        })
    },
    travel_2: {
        background: new MagmaDesignEntity({
            id: "travel-2/bkg-image",
            asset: {
                ID: "travel-2/bkg-image",
                URL: "https://assets.magma.sh/templates/travel-2/bkg-image.png",
                Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                FileType: "png"
            },
            scale: 1,
            isLogo: false,
            editable: true
        }),
        bottom_graphic: new MagmaDesignEntity({
            id: "travel-2/bottom_graphic",
            isLogo: false,
            editable: true,
            scale: 1,
            asset: {
                ID: "travel-2/bottom_graphic",
                FileType: "png",

                Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                URL: "https://assets.magma.sh/templates/travel-2/bottom_graphic.png"
            },
        }),
        small: new MagmaLabelEntity({
            text: "SMALL",
            textAlignment: 1,
            fontColor: {
                green: 1.0000687837600708,
                alpha: 1,
                blue: 0.9998798966407776,
                red: 0.9999018311500549
            },
            fontSize: 195,
            numberOfLines: 1,
            lineHeight: 0,
            fontName: "SixCaps"
        }),
        travels: new MagmaLabelEntity({
            textAlignment: 1,
            lineHeight: 0,
            text: "TRAVELS",
            fontSize: 60,
            fontColor: {
                blue: 0.9998798966407776,
                green: 1.0000687837600708,
                red: 0.9999018311500549,
                alpha: 1
            },
            fontName: "Jost-Regular",
            numberOfLines: 1
        }),
        portland: new MagmaLabelEntity({
            textAlignment: 1,
            numberOfLines: 1,
            fontName: "JostRoman-Medium",
            lineHeight: 0,
            fontColor: {
                alpha: 1,
                green: 0.3151560127735138,
                blue: 0.2606377899646759,
                red: -0.032940465956926346
            },
            text: "PORTLAND",
            fontSize: 30
        })
    }
}

export default TravelCategory