import React from 'react';
import {createPortal} from 'react-dom';
import ClickAwayListener from "react-click-away-listener";
import styled from "styled-components";
import {Plus} from "./icons";
// We get hold of the div with the id modal that we have created in index.html
const portalRoot = document.getElementById('portal');

interface IPortal {
    onClose: (p: false) => void
    bgColor?: string
}


const Close = styled.div`
  font-size: 2rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  transform: translate(-20%, 20%);
  color: #fff;
  background: #000;
`

const RootWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div<{ bgColor?: string }>`
  width: fit-content;
`

class Portal extends React.Component<IPortal> {
    render() {
        return createPortal(
            <>
                <RootWrapper id={"portalroot"}> <ClickAwayListener onClickAway={() => this.props.onClose(false)}>
                    <Wrapper bgColor={this.props.bgColor}>
                        <Close onClick={() => this.props.onClose(false)}>
                            <Plus style={{transform: `rotate(45deg)`}}/>
                        </Close>
                        <>
                            {this.props.children}
                        </>
                    </Wrapper>
                </ClickAwayListener>
                </RootWrapper>
            </>
            , portalRoot || document.body);
    }
}

export default Portal;