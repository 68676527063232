import styled from "styled-components";
import {FlightBlack24Dp, LinkBlack24Dp, LocalMallBlack24Dp, Plus} from "../icons";
import {FC, FormEventHandler, KeyboardEventHandler, ReactElement, useCallback, useMemo, useRef, useState} from "react";
import MagmaLinkEntity, {LinkType} from "../lib/Content/MagmaLinkEntity";
import cuid from "cuid";

const LINK_TYPES: { type: string, icon: ReactElement, key: LinkType }[] = [
    {
        type: 'Shopping',
        icon: <LocalMallBlack24Dp/>,
        key: 'shopping'
    },
    {
        type: 'Link',
        icon: <LinkBlack24Dp/>,
        key: 'standard'
    },
    {
        type: 'Travel',
        icon: <FlightBlack24Dp/>,
        key: 'travel'
    }
]

type TAddingLinkScreen = {
    defaultValue?: MagmaLinkEntity
    onChange?: (value: MagmaLinkEntity | undefined) => void
}

const DoneButton = styled.button`
  cursor: pointer;

  text-decoration: none;
  white-space: nowrap;
  background-color: ${props => props.theme.colors.secondary.bg};
  color: ${props => props.theme.colors.secondary.text};
  border: none;
  display: block;
  border-radius: 6px;
  line-height: 48px;
  padding: 0 22px;
  font-size: 20px;
  font-weight: 500;
  outline: none !important;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:active {
    box-shadow: none;
    transform: scale(.98);
    outline: none !important;

  }

  &:focus {
    box-shadow: 0 0 1px 2px ${props => `rgba(${props.theme.colors.secondary.bgColor}, 0.75)`}, 0 1px 1px rgb(0 0 0 / 15%);
    outline: none !important;

  }
`

const ErrorContainer = styled.div`
  color: red;
  height: 24px;
  margin-bottom: -8px;
`

const LinkItem = styled.label<{ active: boolean }>`
  text-align: center;

  color: ${props => props.active ? props.theme.colors.secondary.bg : 'inherit'};
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  svg {
    font-size: 5rem;
    margin: auto;
  }

  div {
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.05em;
  }
`

const TypeSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 10px;

`


const Heading = styled.h3`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.overlayPanelBg};
  backdrop-filter: blur(10px);
  padding: 60px;
`
const TextField = styled.input<{ errors?: boolean }>`
  outline: none;
  background: ${props => props.theme.colors.overlayTextFieldBg};
  padding: 14px 30px;
  min-width: 400px;
  transition: .3s ease;
  border-radius: 4px;
  border: 1px solid ${props => props.errors ? 'red' : 'transparent'};

  &:focus {
    border-radius: 50px;
  }
`
const TextFieldWrapper = styled.div`
  position: relative;

  svg {
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    right: 10px;
    font-size: 2rem;
    transition: .2s ease;

    &:hover {
      transform: translateY(-50%) rotate(45deg);
      opacity: 1;
    }
  }
`

const AddingLinkScreen: FC<TAddingLinkScreen> = ({defaultValue, onChange}) => {

    const defaultType = defaultValue?.linkType || 'travel'
    const defaultUrl = defaultValue?.Url || ''

    const [activeLinkKey, setActiveLinkKey] = useState<LinkType>(defaultType)
    const [inputValue, setInputValue] = useState<string>(defaultUrl)
    const [errors, setErrors] = useState<string[]>([])


    const inputRef = useRef<HTMLInputElement>(null)

    const handleSelectionChange = useCallback((key: LinkType) => {
        setActiveLinkKey(key)
        if (inputRef?.current) {
            inputRef.current.focus()
        }
    }, [setActiveLinkKey])


    const resetField = useCallback(() => {
        setErrors([])
        if (inputRef?.current) {
            setInputValue('')
            inputRef.current.value = ''
            inputRef.current.focus()
        }
    }, [])


    const hasErrors = useMemo(() => {
        return errors.length > 0
    }, [errors])

    const checkForErrors = useCallback(() => {
        let errors = []

        try {
            if (inputValue !== '') {
                new URL(inputValue);
            }
        } catch (_) {
            errors.push(`Not a valid URL`)
        }

        setErrors(errors)
        return errors

    }, [inputValue])


    const handleInput: FormEventHandler<HTMLInputElement> = useCallback((e) => {

        setInputValue(e.currentTarget.value)

        if (hasErrors) {
            checkForErrors()
        }

    }, [checkForErrors, hasErrors])

    const handleDone = useCallback(() => {
        const _errors = checkForErrors()

        if (_errors.length > 0) {
            return
        }

        if (onChange) {
            onChange(
                new MagmaLinkEntity({
                    Url: inputValue,
                    linkType: activeLinkKey,
                    ID: cuid()
                })
            )
        }
    }, [activeLinkKey, checkForErrors, inputValue, onChange])


    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {

        if (e.key === 'Enter') {
            handleDone()
        }

    }, [handleDone])

    const isValid = useMemo(() => {
        return inputValue !== '' && !hasErrors
    }, [hasErrors, inputValue])

    return (
        <Wrapper>
            <Heading>Select A Link Type</Heading>
            <TypeSelector>
                {LINK_TYPES.map((link, idx) => <LinkItem key={idx} onClick={() => handleSelectionChange(link.key)}
                                                         active={link.key === activeLinkKey}>
                    {link.icon}
                    <div>
                        {link.type}
                    </div>
                </LinkItem>)}
            </TypeSelector>
            <ErrorContainer>
                {hasErrors && <ul>
                    {errors.map((error, idx) => <li key={idx}>
                        {error}
                    </li>)}
                </ul>}
            </ErrorContainer>
            <TextFieldWrapper>
                <TextField
                    enterKeyHint={"done"}
                    onKeyPress={handleKeyPress}
                    errors={hasErrors}
                    onBlur={(e) => {
                        if (e.currentTarget.value === '') {
                            setErrors([])
                        }
                    }}
                    ref={inputRef} autoFocus defaultValue={inputValue} placeholder={'Enter URL'}
                    onInput={handleInput}/>
                {inputValue !== '' && <Plus onClick={resetField}/>}

            </TextFieldWrapper>

            <div>
                <DoneButton disabled={!isValid} onClick={handleDone}>
                    Done
                </DoneButton>
            </div>
        </Wrapper>
    )
}

export default AddingLinkScreen