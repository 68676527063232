type TMagmaLinkEntity = {
    ID: string
    Url: string
    Title?: string
    MainImage?: string
    linkType?: LinkType
}

export type LinkType = "shopping" | "standard" | "travel"

export default class MagmaLinkEntity {
    ID: string
    Url: string
    Title?: string
    MainImage?: string
    linkType?: LinkType

    constructor({ID, linkType, MainImage, Title = "", Url}: TMagmaLinkEntity) {
        this.ID = ID
        this.linkType = linkType
        this.MainImage = MainImage
        this.Title = Title
        this.Url = Url
    }
}