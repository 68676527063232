import {LightTheme} from 'styled-components';

const darkTheme: LightTheme = {
    name: 'darkTheme',
    colors: {
        headerBg: 'rgb(251,179,30)',
        borderColor: 'transparent',
        sidenavBg: 'rgb(26, 25, 26)',
        sidenavHover: 'rgba(0,0,0,0.6)',
        canvasWrapperBg: 'rgba(76, 76, 76, 1)',
        canvasWrapperBgFocus: '#000',
        magNavigatorBackground: 'rgba(0, 0, 0, 0.5)',
        canvasControlsBackground: 'rgba(0, 0, 0, 0.4)',
        textWrapperIconHoverBg: 'rgba(255, 255, 255, 0.2)',
        textOnOnboard: 'rgba(255,255,255,s0.5)',
        actionDropdownBg: 'rgba(65, 65, 65, 0.8)',
        donButtonBg: 'rgba(0, 0, 0, 0.6)',
        moduleOptionsBg: '#222222',
        overlayPanelBg: 'rgba(0, 0, 0, 0.7)',
        overlayTextFieldBg: '#414141',
        overlayTextFieldBgDark: '#353535',
        textWrapperBg: '#333',
        text: '#fff',
        background: '#000',
        darkText: '#fff',
        muted: '#f0f2f5',
        publishScreenBg: 'rgb(25, 26, 25)',
        loginScreenBg: 'rgb(14, 14, 14)',
        secondary: {
            bgColor: `251, 179, 30`,
            bg: 'rgb(251,179,30)',
            text: 'rgb(17, 17, 17)'
        },
        success: {
            bgColor: `90, 196, 96`,
            bg: 'rgb(90, 196, 96)',
            text: '#fff'
        },
        error: {
            bgColor: `172, 0, 32`,
            bg: '#b00020',
            text: '#fff'
        }
    },

};

export {darkTheme};