import {FC, useContext} from "react";
import Header from "../Layouts/Header";
import {UserContext} from "../Providers/Auth/UserProvider";
import {IUser} from "../lib/Models/User";
import {RouteComponentProps} from "@reach/router";
import UserMags from "../Components/UserMags";



const MyDrafts:FC<RouteComponentProps> = () => {
    // @ts-ignore
    const user: IUser = useContext(UserContext);


    return (
        <>
            <Header user={user}/>
            <UserMags type={"draft"} user={user}/>
        </>
    )

}

export default MyDrafts