import { FC } from "react"
import styled from "styled-components"
import { SIZE_HEIGHT_MULTIPLIER } from "../../../Components/MagCanvas"
import { buildCDNUrl } from "../../../helpers"
import { TextureData } from "../../../lib/Models/Texture"

const size = 32
const TextureWrapper = styled.div`
    cursor: pointer;
`

const Texture: FC<{ texture: TextureData, onSelection?: (texture: TextureData) => void }> = ({ texture, onSelection }) => {
    return (
        <TextureWrapper onClick={() => onSelection ? onSelection(texture) : null}>
            <img src={`${buildCDNUrl(texture.Asset.URL)}?tr=h-${size * SIZE_HEIGHT_MULTIPLIER}`} alt={`${texture.Category} texture`} loading={"lazy"} />
        </TextureWrapper>
    )
}

export default Texture