import React, {useCallback, useEffect, useMemo, useState} from 'react';
import UserProvider from "./Providers/Auth/UserProvider";
import PageProvider from './Providers/Page/PageProvider';
import Application from "./Components/Application";
import styled, {ThemeProvider} from "styled-components";
import {Provider as BusProvider} from 'react-bus'
import {darkTheme} from "./Themes/DarkTheme";
import {GLOBAL_EVENTS, off, on} from "./customEvents";
import {lightTheme} from "./Themes/LightTheme";

const Draggable = styled.div`
  height: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  -webkit-app-region: drag;
`

function App() {


    const storedTheme = useMemo(() => window.localStorage.getItem('magma_theme') ?? 'light', [])


    const [activeTheme, setActiveTheme] = useState(storedTheme)

    const handleThemeChange = useCallback(({detail: {theme}}) => {
        setActiveTheme(theme)
    }, [setActiveTheme])

    useEffect(() => {
        let bgColor = '#fff'
        let textColor = '#000'
        let textFieldBg = '#414141'
        if (activeTheme !== 'light') {
            bgColor = 'rgb(25,25,25)'
            textColor = '#fff'
            textFieldBg = '#fff'
        }
        (document.querySelector(':root') as HTMLElement).style.setProperty('--app-bg-color', bgColor);
        (document.querySelector(':root') as HTMLElement).style.setProperty('--app-text-color', textColor);
        (document.querySelector(':root') as HTMLElement).style.setProperty('--text-field-color', textFieldBg);

    }, [activeTheme])

    useEffect(() => {

        on(GLOBAL_EVENTS.THEME_CHANGE, handleThemeChange)

        return () => {
            off(GLOBAL_EVENTS.THEME_CHANGE, handleThemeChange)
        }
    }, [handleThemeChange])

    return (
        <ThemeProvider theme={activeTheme === 'dark' ? darkTheme : lightTheme}>
            <UserProvider>
                <BusProvider>
                    <PageProvider>
                        <div id={"appForTheme"}>
                            <Application/>
                            <Draggable/>
                        </div>
                    </PageProvider>
                </BusProvider>
            </UserProvider>
        </ThemeProvider>

    );
}

export default App;
