import BlankCategory from "./Categories/BlankCategory";
import FitnessCategory from "./Categories/FitnessCategory";
import TravelCategory from "./Categories/TravelCategory";
import BusinessCategory from "./Categories/BusinessCategory";
import SportCategory from "./Categories/SportCategory";
import FashionCategory from "./Categories/FashionCategory";
import BeautyCategory from "./Categories/BeautyCategory";

export interface ICoverTemplateUncategorized {
    title: string
    meta: any
    thumbnail: string
}

export interface ICoverTemplate extends ICoverTemplateUncategorized {
    category: string
}


const TemplateCategorizer = (templates: ICoverTemplateUncategorized[], category: string) => {
    return templates.map(template => {

        const templateWithCategory: ICoverTemplate = {
            ...template,
            category
        }


        return templateWithCategory
    })
}

const COVER_TEMPLATES: ICoverTemplate[] = [
    ...TemplateCategorizer(BlankCategory, 'blank'),
    ...TemplateCategorizer(TravelCategory, 'travel'),
    ...TemplateCategorizer(FitnessCategory, 'fitness'),
    ...TemplateCategorizer(SportCategory, 'sport'),
    ...TemplateCategorizer(BusinessCategory, 'business'),
    ...TemplateCategorizer(FashionCategory, 'fashion'),
    ...TemplateCategorizer(BeautyCategory, 'beauty')
]

export default COVER_TEMPLATES