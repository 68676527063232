import { ICoverTemplateUncategorized } from ".."
import { makeTemplateThumbnailURL } from "../../../helpers"

const BusinessCategory: ICoverTemplateUncategorized[] = [
    {
        title: 'business1',
        thumbnail: makeTemplateThumbnailURL('business1'),
        meta: []
    },
    {
        title: 'business2',
        thumbnail: makeTemplateThumbnailURL('business2'),
        meta: []
    }
]

export default BusinessCategory