import cuid from "cuid";
import { clone } from "lodash";
import { RGBObject } from "../../../Modules/Text/TextModule";
import { ITransform } from "../../Content/MagmaPhotoEntity";
import { DEFAULT_TRANSFORM, IBounds } from "../../Models/Block";
import MagmaDesignEntity from "./MagmaDesignEntity";
import MagmaImageEntity from "./MagmaImageEntity";
import MagmaLabelEntity from "./MagmaLabelEntity";

export type MagmaLayeredBlockMetaEntity = undefined | MagmaLabelEntity | MagmaDesignEntity | MagmaImageEntity

export interface MagmaLayeredBlockEntityProps {
    id?: string
    frame: IBounds
    bounds: IBounds
    center?: [number, number]
    tag: number
    transform?: ITransform
    isBackground?: boolean
    backgroundColor?: RGBObject
    design?: MagmaLayeredBlockMetaEntity
    image?: MagmaLayeredBlockMetaEntity
    label?: MagmaLayeredBlockMetaEntity
}

export default class MagmaLayeredBlockEntity {
    id: string
    frame: IBounds
    bounds: IBounds
    center: [number, number]
    tag: number
    transform: ITransform
    isBackground: boolean
    backgroundColor?: RGBObject
    design?: MagmaLayeredBlockMetaEntity
    image?: MagmaLayeredBlockMetaEntity
    label?: MagmaLayeredBlockMetaEntity

    constructor({
        id = cuid(),
        frame,
        bounds,
        center = [0, 0],
        tag,
        transform = clone(DEFAULT_TRANSFORM) as ITransform,
        isBackground = false,
        backgroundColor,
        design,
        image,
        label,
    }: MagmaLayeredBlockEntityProps) {
        this.id = id
        this.frame = frame
        this.bounds = bounds
        this.center = center
        this.tag = tag
        this.transform = transform
        this.isBackground = isBackground
        this.backgroundColor = backgroundColor

        design && (this.design = design)
        image && (this.image = image)
        label && (this.label = label)

    }
}