import { ICoverTemplate } from ".."
import {  makeTemplateThumbnailURL } from "../../../helpers"
import MagmaLabelEntity from "../LayeredBlocks/MagmaLabelEntity"
import MagmaLayeredBlockEntity from "../LayeredBlocks/MagmaLayeredBlockEntity"

const BeautyCategory: ICoverTemplate[] = [
    {
        title: 'beauty1',
        thumbnail: makeTemplateThumbnailURL('beauty1'),
        category: 'Beauty',
        meta: [
            
            new MagmaLayeredBlockEntity({
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        374,
                        598.6666666666666
                    ]
                },
                isBackground: false,
                center: [
                    187,
                    299.3333333333333
                ],
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                backgroundColor: {
                    green: 0,
                    red: 0,
                    blue: 0,
                    alpha: 0
                },
                tag: 0,
                frame: {
                    Size: [
                        374,
                        598.6666666666666
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                id: "ckttcmcvycv00lu1mvs6jeoeb"
            }),
            new MagmaLayeredBlockEntity({
                backgroundColor: {
                    blue: 1,
                    alpha: 1,
                    red: 1,
                    green: 1
                },
                center: [
                    186.5,
                    299.335
                ],
                id: "ckttcmcvzcw00lu1mbdskaeyr",
                design: {
                    id: "beauty-1/bkg_image",
                    editable: true,
                    scale: 1,
                    isLogo: false,
                    asset: {
                        ID: "beauty-1/bkg_image",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                        FileType: "png",
                        URL: "https://assets.magma.sh/templates/beauty-1/bkg_image.png"
                    }
                },
                tag: 1,
                bounds: {
                    Origin: [
                        -1,
                        -0.5245505617978097
                    ],
                    Size: [
                        375,
                        599.7191011235956
                    ]
                },
                isBackground: false,
                frame: {
                    Size: [
                        375,
                        599.7191011235956
                    ],
                    Origin: [
                        -1,
                        -0.5245505617978097
                    ]
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                center: [
                    261.8,
                    301.884875
                ],
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                frame: {
                    Origin: [
                        164.8792800318218,
                        25
                    ],
                    Size: [
                        193.8414399363564,
                        553.76975
                    ]
                },
                isBackground: false,
                id: "ckttcmcw0cx00lu1mhrmlk6o3",
                backgroundColor: {
                    red: 1,
                    alpha: 1,
                    blue: 1,
                    green: 1
                },
                bounds: {
                    Size: [
                        193.8414399363564,
                        553.76975
                    ],
                    Origin: [
                        164.8792800318218,
                        25
                    ]
                },
                design: {
                    scale: 1,
                    asset: {
                        URL: "https://assets.magma.sh/templates/beauty-1/photo.png",
                        ID: "beauty-1/photo",
                        FileType: "png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
                    },
                    editable: true,
                    isLogo: false,
                    id: "beauty-1/photo"
                },
                tag: 2
            }),
            new MagmaLayeredBlockEntity({
                backgroundColor: {
                    green: 1,
                    alpha: 1,
                    blue: 1,
                    red: 1
                },
                center: [
                    400.18,
                    0
                ],
                frame: {
                    Origin: [
                        280.88749705014754,
                        -104.76725
                    ],
                    Size: [
                        238.58500589970498,
                        209.5345
                    ]
                },
                isBackground: false,
                bounds: {
                    Size: [
                        238.58500589970498,
                        209.5345
                    ],
                    Origin: [
                        280.88749705014754,
                        -104.76725
                    ]
                },
                design: {
                    asset: {
                        ID: "beauty-1/shape-top",
                        URL: "https://assets.magma.sh/templates/beauty-1/shape-top.png",
                        FileType: "png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
                    },
                    isLogo: false,
                    scale: 1,
                    id: "beauty-1/shape-top",
                    editable: true
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                tag: 3,
                id: "ckttcmcw0cy00lu1mvq4n5i65"
            }),
            new MagmaLayeredBlockEntity({
                center: [
                    38.5,
                    572.4125438596491
                ],
                tag: 4,
                frame: {
                    Size: [
                        312.79347286821695,
                        199.55666666666662
                    ],
                    Origin: [
                        -117.89673643410848,
                        472.63421052631577
                    ]
                },
                isBackground: false,
                bounds: {
                    Size: [
                        312.79347286821695,
                        199.55666666666662
                    ],
                    Origin: [
                        -117.89673643410848,
                        472.63421052631577
                    ]
                },
                id: "ckttcmcw1cz00lu1ma2v0dg2f",
                backgroundColor: {
                    alpha: 1,
                    red: 1,
                    green: 1,
                    blue: 1
                },
                design: {
                    editable: true,
                    id: "beauty-1/shape-bottom",
                    isLogo: false,
                    scale: 1,
                    asset: {
                        ID: "beauty-1/shape-bottom",
                        FileType: "png",
                        URL: "https://assets.magma.sh/templates/beauty-1/shape-bottom.png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
                    }
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                isBackground: false,
                backgroundColor: {
                    green: 0,
                    blue: 0,
                    alpha: 0,
                    red: 0
                },
                label: new MagmaLabelEntity({
                    text: "BOLD & NEW",
                    textAlignment: 1,
                    fontSize: 80,
                    numberOfLines: 1,
                    lineHeight: 0,
                    fontColor: {
                        green: 1.0000001192092896,
                        red: 1.0000001192092896,
                        alpha: 1,
                        blue: 1.0000001192092896
                    },
                    fontName: "JostRoman-Bold"
                }),
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        598.67,
                        74.8
                    ]
                },
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                frame: {
                    Origin: [
                        59.7728378378378,
                        7.736171171171122
                    ],
                    Size: [
                        74.80000000000003,
                        598.67
                    ]
                },
                tag: 5,
                id: "ckttcmcw1d000lu1m9lcxhauj",
                center: [
                    97.17283783783782,
                    307.0711711711711
                ]
            }),
            new MagmaLayeredBlockEntity({
                label: new MagmaLabelEntity({
                    numberOfLines: 1,
                    lineHeight: 0,
                    textAlignment: 1,
                    text: "morning makeup routine",
                    fontSize: 42,
                    fontColor: {
                        red: 0.2790178060531616,
                        blue: 0.751497209072113,
                        green: 0.05675583332777023,
                        alpha: 1
                    },
                    fontName: "EBGaramond-Regular_Medium"
                }),
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                tag: 6,
                frame: {
                    Size: [
                        62.33333333333337,
                        478.936
                    ],
                    Origin: [
                        142.687298245614,
                        9.396848484848476
                    ]
                },
                center: [
                    173.85396491228067,
                    248.86484848484847
                ],
                isBackground: false,
                backgroundColor: {
                    green: 0,
                    alpha: 0,
                    blue: 0,
                    red: 0
                },
                bounds: {
                    Size: [
                        478.936,
                        62.333333333333336
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                id: "ckttcmcw2d100lu1ma55vwa3j"
            })
        ]
    }
]

export default BeautyCategory