import { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  display: relative;
`

const TextWrapper: FC = ({children}) => {

// TODO: possibly? move block type and style buttons here, move editorState here and prop drill setEditorState dispatch into TextContent
// remove children prop and simply move TextContent into this component instead of Block

    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default TextWrapper