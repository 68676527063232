import {FC, useRef, useState} from "react";
import styled from "styled-components";
import {getComponentName, ModuleResolver} from "../helpers";
import {useListener} from "react-bus";
import withSnapshotHelper from "../HOC/withSnapshotHelper";

export const INITIAL_CANVAS_SIZE = 226.2
export const SIZE_WIDTH_MULTIPLIER = 5
export const SIZE_HEIGHT_MULTIPLIER = 8


declare interface IMagCanvas_Props {
    components: any[]
    setActiveComponent: (data: { id: string | null, meta?: any }) => void
    removeModule: (id: string | null) => void
    centerX: boolean
}


const CenterGridX = styled.div<{ active?: boolean }>`
  display: block;
  height: 100%;
  position: absolute;
  left: 50%;
  opacity: ${props => props.active ? 1 : 0};
  top: 0;
  width: 1px;
  bottom: 0;
  background: ${props => props.theme.colors.secondary.bg};
  z-index: 999;
`


const CanvasWrapper = styled.div<{ size: number, scale?: number | undefined }>`
  width: ${props => props.size * SIZE_WIDTH_MULTIPLIER * (props.scale || 1)}px;
  height: ${props => props.size * SIZE_HEIGHT_MULTIPLIER * (props.scale || 1)}px;
  position: relative;
  margin: 120px auto;

`
const CanvasWrapperOverlay = styled.div<{ size: number, scale?: number | undefined }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  pointer-events: none;
`

const Canvas = styled.div<{ size: number, scale: number | undefined }>`
  min-width: ${props => props.size * SIZE_WIDTH_MULTIPLIER}px;
  min-height: ${props => props.size * SIZE_HEIGHT_MULTIPLIER}px;
  margin: auto;
  background: #fff;
  //overflow: hidden;
  position: relative;
  transform: scale(${props => props.scale ? props.scale : 1.0});
  transform-origin: 0 0;
  box-shadow: 0 2px 8px rgb(14 19 24 / 7%);
`

const MagCanvas: FC<IMagCanvas_Props> = ({components, setActiveComponent, removeModule, centerX}) => {
    const [canvasSize] = useState<number>(INITIAL_CANVAS_SIZE)

    const [scale, setScale] = useState<number>(0.5)

    const canvasRef = useRef<HTMLDivElement>(null)

    useListener(
        'scaleChange', val => setScale(val)
    )

    return (
        <CanvasWrapper size={canvasSize} scale={scale}>
            <Canvas size={canvasSize} ref={canvasRef} data-master-canvas scale={scale} id={"magmaCanvas"}>
                <div>
                    {
                        components.map((component, idx) => {

                            return <ModuleResolver key={component.id}
                                                   props={{
                                                       sequence: idx,
                                                       id: component.id,
                                                       meta: component,
                                                       onActive: (meta: any) => setActiveComponent({
                                                           id: component.id,
                                                           meta
                                                       }),
                                                       onInactive: () => null,
                                                       onRemove: () => removeModule(component.id)
                                                   }}

                                                   type={"component"}
                                                   component={getComponentName(component) || ''}/>
                        })
                    }
                </div>
                <CenterGridX active={centerX}/>

            </Canvas>
            <CanvasWrapperOverlay size={canvasSize} scale={scale}/>
        </CanvasWrapper>
    )
}

export default withSnapshotHelper(MagCanvas)