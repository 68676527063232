import {FC, memo, useCallback, useEffect} from "react";
import styled from "styled-components";
// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {__MapboxGeocoder} from "../react-app-env";
import {ILocation} from "../lib/Models/Map";

const ACCESS_TOKEN = `pk.eyJ1Ijoic2toYW5uYTEwMDUiLCJhIjoiY2tlNXE0YWx1MHQ1NjJ5cGV5ZW9tNmRnMSJ9.ihWPmR_cqrdmKTzIgYWbTw`;

const RootContainer = styled.div`
  position: relative;
  z-index: 97;
`

declare interface ILocationSelector_Props {
    onSelectionChange: (result: __MapboxGeocoder.Result) => void
    initialValue?: ILocation | undefined | null
}

const LocationSelector: FC<ILocationSelector_Props> = ({onSelectionChange, initialValue}) => {

    const dispatchSelectionChange = useCallback((e: { result: __MapboxGeocoder.Result }) => {
        onSelectionChange(e.result)
    }, [onSelectionChange])

    useEffect(() => {
        const geocoder = new MapboxGeocoder({
            accessToken: ACCESS_TOKEN,
            placeholder: 'Add A Location',
        });
        geocoder.addTo('#geocoder-container')


        geocoder.on('result', dispatchSelectionChange)


        const inputEl: HTMLInputElement | null = document.querySelector('.mapboxgl-ctrl-geocoder--input')
        if (initialValue && inputEl) {

            inputEl.value = initialValue.Name
        }

        return () => {
            geocoder.off('result', dispatchSelectionChange)
            // @ts-ignore
            document?.querySelector('#geocoder-container') && (document.querySelector('#geocoder-container').innerHTML = '')
        }
    }, [dispatchSelectionChange, initialValue])

    return (
        <RootContainer>
            <div id={"geocoder-container"}/>
        </RootContainer>
    )
}

export default memo(LocationSelector)