import React, {FC, MouseEventHandler, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import {auth} from "../../Integrations/firebase";
import firebase from "firebase/app";
import AuthWrapper from "../../Layouts/Auth/Wrapper";
import Input from "../../HOC/Input";
import Button from "../../HOC/Button";
import Card from "../../HOC/Card";
import styled from "styled-components";

const Action = styled.div`
  text-align: center;
  padding: 20px 0;
`

const SignIn: FC<RouteComponentProps> = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const [usingPhoneMethod, setUsingPhoneMethod] = useState(false)

    const signInWithPhoneNumber = (event: React.FormEvent<HTMLFormElement>, phone: string) => {

    }

    const signInWithEmailAndPasswordHandler = (event: React.FormEvent<HTMLFormElement>, email: string, password: string) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password).catch(error => {
            setError("Error signing in with password and email!");
            console.error("Error signing in with password and email", error);
        });
    };

    const signInWithAppleID: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        const provider = new firebase.auth.OAuthProvider('apple.com');

        firebase
            .auth()
            .signInWithPopup(provider)
            .catch((error) => {
                console.error("Error signing in with apple id", error);
            });

    }


    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        } else if (name === 'userPassword') {
            setPassword(value);
        }
    };

    return (
        <AuthWrapper>
            <div className="grid lg:grid-cols-5 gap-3 items-center">
                <div className={"lg:col-span-3"}>
                    <h1 className={"text-6xl"}>
                        Magma.
                    </h1>
                    <h2 className={"mt-3 text-xl max-w-md font-light"}>
                        Let your creativity and content live free and powerful. Page after page.
                    </h2>
                </div>
                <div className={"lg:col-span-2"}>
                    <Card>
                        <>
                            {error !== null &&
                            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
                            <form onSubmit={(event) => {
                                signInWithEmailAndPasswordHandler(event, email, password)
                            }}>
                                <div>
                                    <Input
                                        stacked
                                        type="email"
                                        name="userEmail"
                                        value={email}
                                        placeholder="Email"
                                        id="userEmail"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div>
                                    <Input
                                        stacked
                                        type="password"
                                        name="userPassword"
                                        value={password}
                                        placeholder="Password"
                                        id="userPassword"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div>
                                    <Button block>
                                        Sign in
                                    </Button>
                                </div>
                            </form>
                            <div className={"text-center mt-4"}>
                                <Link to="passwordReset" className="text-blue-500 hover:text-blue-600">
                                    Forgot Password?
                                </Link>
                            </div>
                            <hr className={"my-4"}/>
                            <div className={"text-center"}>
                                <button onClick={signInWithAppleID} id={"sign-in"}>
                                    <img src={"/Assets/apple-id-sign-in-with.png"} className={"mx-auto"}
                                         alt={"sign in with apple button"}/>
                                </button>
                            </div>

                        </>

                    </Card>
                    <Action>
                        {/*<Link to={"/signUp"}>*/}
                        {/*    Create An Account*/}
                        {/*</Link>*/}

                    </Action>
                </div>
            </div>
        </AuthWrapper>
    );
};
export default SignIn;