import {FC} from "react";
import styled from "styled-components";
import {MagData} from "../lib/Models/Mag";
import {Button} from "@geist-ui/react";


const Panel = styled.div`
  padding: 40px 50px;
  background: #000;
`

const Root = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 20px;
    letter-spacing: 0.1px;
    color: #fff;
  }

  img {
    width: ${5 * 50}px;
    height: ${8 * 50}px;
    margin: 30px auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`

const Prompt: FC<{ mag: MagData, onCancel: () => void, onAgree: () => void }> = ({mag, onAgree, onCancel}) => {
    return (
        <Root>
            <Panel>
                <h2>Are you sure you want to delete this mag?</h2>
                <div>
                    <img src={mag.Thumbnail} alt=""/>
                </div>
                <div style={{textAlign: 'center'}}>
                    <Button onClick={onCancel} ghost auto>
                        Cancel
                    </Button>
                    <Button type="error" onClick={onAgree} auto style={{marginLeft: 20}}>
                        Delete Mag
                    </Button>
                </div>

            </Panel>
        </Root>
    )
}

export default Prompt