import {FC, KeyboardEvent, MouseEventHandler, useCallback, useEffect, useRef, useState} from "react";
import MagmaTextEntity, {defaultMagmaTextFormat, MagmaTextFormat} from "../../lib/Content/MagmaTextEntity";
import styled from "styled-components";
import Draft, {
    CharacterMetadata,
    CompositeDecorator,
    ContentBlock,
    ContentState,
    convertFromHTML,
    convertFromRaw,
    convertToRaw,
    EditorState,
    Modifier,
    RichUtils
} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import SuggestionBox from "../SuggestionBox";
import {trimStart} from 'lodash'
import PortalSimple from "../../PortalSimple";
import {searchUsers} from "../../lib/Fetch/User";
import {IUser} from "../../lib/Models/User";
import {lightTheme} from "../../Themes/LightTheme";
import {DeleteOutlineBlack24Dp} from "../../icons";
import MagmaLinkEntity from "../../lib/Content/MagmaLinkEntity";
import AddingLinkScreen from "../AddingLinkScreen";
import Portal from "../../Portal";
import {stopScreenshotTimer} from "../../screenshotUtils";
import {Button, Popover, Select} from "@geist-ui/react";
import {
    AiOutlineCode,
    AiOutlineOrderedList,
    AiOutlineUnorderedList,
    BiHighlight,
    BsBlockquoteLeft,
    CgFormatStrike,
    IoIosRedo,
    IoIosUndo,
    MdHorizontalRule
} from "react-icons/all";

import '@draft-js-plugins/focus/lib/plugin.css';

import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createDividerPlugin from '@draft-js-plugins/divider';
import {ChevronDown} from "@geist-ui/react-icons";

const focusPlugin = createFocusPlugin();

const decorator = composeDecorators(focusPlugin.decorator);

const dividerPlugin = createDividerPlugin({decorator});
const {addDivider} = dividerPlugin;


const plugins = [focusPlugin, dividerPlugin];

const BlockDeleteWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 2rem;
  opacity: 0;
  transition: opacity .1s;
  visibility: hidden;

  &:hover {
    opacity: 1 !important;
  }
`

const LinkWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;


  & > div {
    display: grid;
    gap: 1rem;
  }
`


const TextBlockContent = styled.div<{ Font?: string }>`

  position: relative;
  padding-top: 64.8px;


  & p, & span {
    font-size: 1.2rem;

  }

  & h1, & h1 span {
    line-height: normal;
    font-size: 3rem;
    margin-bottom: 0.6rem;
  }

  & h2, & h2 span {
    line-height: normal;
    font-size: 2.5rem;
    margin-bottom: 0.6rem;
  }

  & h3, & h3 span {
    line-height: normal;
    font-size: 2rem;
    margin-bottom: 0.6rem;
  }

  & h4, & h4 span {
    line-height: normal;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }

  & h5, & h5 span {
    line-height: normal;
    font-size: 2.5rem;
    font-weight: 400;
    opacity: 0.9;
    margin-bottom: 0.6rem;
  }


  & h1,
  & h2,
  & p {
  }

  & a {
    color: #06c;
  }


  a:hover {
    border-bottom: 1px solid currentColor;


  }

  font-family: ${props => props.Font};

  &:not(:focus):hover {
    ${BlockDeleteWrapper} {
      opacity: 0.5;
      visibility: visible;
    }
  }
`
const StyleButtonWrapper = styled.button<{ active: boolean }>`

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-family: sans-serif;
  pointer-events: auto;
  color: ${props => props.active ? props.theme.colors.secondary.bg : 'inherit'};


  &:hover {
    background-color: ${props => props.theme.colors.textWrapperIconHoverBg};
  }

  svg {
    margin: auto;
  }

  span {
    font-size: 0.71rem;
  }
`
const RichTextControls = styled.div`
  gap: 0.5rem;
  display: flex;
  border-left: 1px solid rgba(0, 0, 255, 0.1);
  padding-left: 0.5rem;
`
const ToolbarWrapper = styled.div<{ left: number, top: number }>`
  text-align: center;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.textWrapperBg};
  display: flex;
  align-items: center;
  gap: 1rem;
  transform: scale(1);
  overflow-x: auto;
  ${props => props.theme.name === 'lightTheme' && `
    border-bottom:1px solid #e6e6e6;
    padding-bottom:10px;
    margin-bottom:6px;
  `}
`


const BLOCK_TYPES = [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading 1', style: 'header-one'},
    {label: 'Heading 2', style: 'header-two'},
    {label: 'Heading 3', style: 'header-three'},
    {label: 'Heading 4', style: 'header-four'},
];

const INLINE_STYLES_PRIMARY = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Strikethrough', style: 'STRIKETHROUGH'},
    {label: 'Highlight', style: 'HIGHLIGHT'},
];
const BLOCK_STYLES_SECONDARY = [
    {label: 'Code', style: 'code-block'},
];
const INLINE_STYLES_SECONDARY = [
    {label: 'Link', style: 'LINK'},
];

const styleMap = {
    STRIKETHROUGH: {
        textDecoration: 'line-through',
    },
    HIGHLIGHT: {
        background: '#FFFF00',
    },
};

type ToggleStyleFunction = (style: string) => void


interface StyleControlsProps {
    editorState: EditorState,
    onToggleInlineStyle?: ToggleStyleFunction | undefined
    onToggleBlockStyle?: ToggleStyleFunction | undefined
    onToggle?: ToggleStyleFunction | undefined
    onAction?: ToggleStyleFunction | undefined
}


const EditorWrapper = styled.div`
  padding: 10px ${props => props.theme.name === 'darkTheme' ? '30px' : 0};
`
const initializeMagmaTextFormat = (TextFormat?: MagmaTextFormat): MagmaTextFormat => TextFormat ? TextFormat : defaultMagmaTextFormat


const TextContent: FC<MagmaTextEntity &
    {
        onUpdate: (content: MagmaBlockContent<MagmaTextEntity>) => void,
        IsPlaceholder?: boolean, onDeleteBlock: () => void, snapshot?: boolean
    }> = ({
              ID,
              Text,
              Font,
              TextRaw,
              TextFormat,
              IsPlaceholder,
              onUpdate,
              onDeleteBlock

          }) => {

    const Link = ({entityKey, contentState, children}: any) => {
        let {url} = contentState.getEntity(entityKey).getData();
        return (
            <a
                style={{color: lightTheme.colors.secondary.bg, fontStyle: "italic"}}
                href={url}
                target="_blank" rel="noreferrer"
            >
                {children}
            </a>
        );
    };

    const findLinkEntities = (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {

        contentBlock.findEntityRanges((character: CharacterMetadata) => {

            const entityKey = character.getEntity();

            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "LINK"
            );
        }, callback);
    };

    const createLinkDecorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    const editor = useRef(null)


    const {contentBlocks, entityMap} = convertFromHTML(Text)

    const contentState = TextRaw ? convertFromRaw(TextRaw) : ContentState.createFromBlockArray(contentBlocks, entityMap)

    let initialState = EditorState.createWithContent(contentState, createLinkDecorator)


    const [users, setUsers] = useState<IUser[]>([])
    const [editorState, setEditorState] = useState(EditorState.moveSelectionToEnd(initialState));
    const [suggestionState, setSuggestionState] = useState<{
        left: number
        top: number
        text: string
        selectedIndex: number
    } | null>(null)


    const [urlState, setUrlState] = useState<{
        value: string
        visible: boolean
    } | null>(null)


    const handleUpdate = useCallback(({ID, Font, TextFormat}: MagmaTextEntity, contentState: ContentState) => {
        const Text = stateToHTML(contentState, {
            inlineStyles: {
                HIGHLIGHT: {style: {...styleMap.HIGHLIGHT}}
            },
        })

        const TextRaw = convertToRaw(contentState)

        onUpdate(new MagmaBlockContent({
            type: 'Text',
            content: {
                ...new MagmaTextEntity({
                    ID,
                    Text,
                    TextRaw,
                    Font,
                    TextFormat: initializeMagmaTextFormat(TextFormat)
                })
            }
        }))
    }, [onUpdate])


    const toggleBlockType = useCallback((blockType) => {
        setEditorState(_editorState => RichUtils.toggleBlockType(
            _editorState,
            blockType)
        );
    }, [])


    const addLink = (link?: MagmaLinkEntity) => {

        addDivider(editorState)

        if (!link) return

        const contentState = editorState.getCurrentContent();


        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {url: link.Url}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithEntity});

        setEditorState(_editorState => RichUtils.toggleLink(
            _editorState,
            newEditorState.getSelection(),
            entityKey
        ))
        setUrlState({value: '', visible: false})
    }

    const toggleInlineStyle = useCallback((inlineStyle) => {

        if (inlineStyle === 'LINK') {
            const selection = editorState.getSelection();
            if (!selection.isCollapsed()) {
                const contentState = editorState.getCurrentContent();
                const startKey = editorState.getSelection().getStartKey();
                const startOffset = editorState.getSelection().getStartOffset();
                const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

                let url = '';
                if (linkKey) {
                    const linkInstance = contentState.getEntity(linkKey);
                    url = linkInstance.getData().url;
                }

                setUrlState({value: url, visible: true})

            }
            return
        }
        setEditorState(_editorState => RichUtils.toggleInlineStyle(
                _editorState,
                inlineStyle
            )
        );
    }, [editorState])

    // const hasFocus = useMemo(() => {
    //     return editorState.getSelection().getHasFocus()
    // }, [editorState])


    const getRange = useCallback((trigger: string | '@' = '@') => {

        const hasEntityAtSelection = () => {

            const selection = editorState.getSelection();
            if (!selection.getHasFocus()) {
                return false;
            }

            const contentState = editorState.getCurrentContent();
            const block = contentState.getBlockForKey(selection.getStartKey());
            return !!block.getEntityAt(selection.getStartOffset() - 1);
        }

        const selection: Selection | null = window.getSelection();
        if (!selection) {
            return null
        }

        if (selection.rangeCount === 0) {
            return null;
        }

        if (hasEntityAtSelection()) {
            return null;
        }

        const range = selection.getRangeAt(0);
        let text = range.startContainer.textContent || '';

        text = text.substring(0, range.startOffset);

        const index = text.lastIndexOf(trigger);

        if (index === -1) {
            return null;
        }

        text = text.substring(index);

        return {
            text,
            start: index,
            end: range.startOffset
        };
    }, [editorState]);

    const onChange = useCallback(() => {
        const range = getRange();

        if (!range) {
            return null
        }

        const tempRange = window.getSelection()?.getRangeAt(0).cloneRange();

        if (!tempRange) {
            return null
        }

        tempRange.setStart(tempRange.startContainer, range.start);

        const rangeRect = tempRange.getBoundingClientRect();

        let [left, top] = [rangeRect.left, rangeRect.bottom];

        setSuggestionState({
            left,
            top,
            text: trimStart(range.text, '@'),
            selectedIndex: 0
        })
    }, [getRange])

    const shouldShowSuggestionBox = useCallback((): boolean => {
        return !(!suggestionState || users.length < 1);
    }, [suggestionState, users])


    useEffect(() => {

        if (suggestionState) {
            searchUsers(suggestionState.text).then(setUsers)
        }

    }, [suggestionState])


    useEffect(() => {

        const scrollCallback = () => {


            if (shouldShowSuggestionBox()) {
                onChange()
            }
        }

        /*
            Adds target=_blank and
            nofollow noopener to all
            external links
        */

        Array.prototype.slice.call(document.querySelectorAll('a')).forEach(function (a) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-restricted-globals
            location.hostname !== a.hostname && 0 !== a.hostname.length && (a.setAttribute('rel', 'nofollow noopener') && a.setAttribute('target', '_blank'))
        })


        window?.addEventListener('scroll', scrollCallback)
        return () => {
            window?.removeEventListener('scroll', scrollCallback)

        }

    }, [onChange, shouldShowSuggestionBox])


    const keyBindingFn = (e: KeyboardEvent) => {

        if (shouldShowSuggestionBox() && ['Enter', 'Tab', 'ArrowUp', 'ArrowDown', 'Escape'].includes(e.key)) {
            return 'void_command'

        }

        // This wasn't the delete key, so we return Draft's default command for this key
        return Draft.getDefaultKeyBinding(e)
    }


    const addSuggestion = ({editorState, start, end, text, user}: any) => {
        const currentContent = editorState.getCurrentContent();

        const decorator = createLinkDecorator;

        currentContent.createEntity('LINK', 'IMMUTABLE', {url: `magma://users/${user.ID}`});

        let entityKey = currentContent.getLastCreatedEntityKey();


        const currentSelectionState = editorState.getSelection();
        const mentionTextSelection = currentSelectionState.merge({
            anchorOffset: start,
            focusOffset: end
        });

        let insertingContent = Modifier.replaceText(
            editorState.getCurrentContent(),
            mentionTextSelection,
            text,
            // @ts-ignore
            null,
            entityKey
        );

        const blockKey = mentionTextSelection.getAnchorKey();
        const blockSize = editorState.getCurrentContent().getBlockForKey(blockKey).getLength();
        if (blockSize === end) {
            insertingContent = Modifier.insertText(
                insertingContent,
                insertingContent.getSelectionAfter(),
                ' '
            );
        }

        let newState = EditorState.createWithContent(insertingContent, decorator);

        newState = EditorState.forceSelection(newState, insertingContent.getSelectionAfter());

        setEditorState(newState)
    };

    const handleBlockDelete = useCallback(() => {
        if (onDeleteBlock) {
            onDeleteBlock()
        }
    }, [onDeleteBlock])

    const toolbarPosition = (): { top: number, left: number } => {
        const selection = window.getSelection && window.getSelection()

        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const {top} = range.getBoundingClientRect()

            const {left} = document.querySelector('.DraftEditor-root')?.getBoundingClientRect() || {left: 0}
            return {top, left}
        }

        return {
            top: -999,
            left: -999
        }
    }

    return (
        <>
            <ToolbarWrapper
                top={toolbarPosition().top}
                left={toolbarPosition().left}>
                <BlockStyleControls
                    onUndo={() => setEditorState(EditorState.undo(editorState))}
                    onRedo={() => setEditorState(EditorState.redo(editorState))}
                    editorState={editorState}
                    onToggle={toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggleInlineStyle={toggleInlineStyle}
                    onToggleBlockStyle={toggleBlockType}
                    onAction={(actionName) => {
                        switch (actionName) {
                            case 'divider':
                                setEditorState(addDivider(editorState))
                        }
                    }}
                />
            </ToolbarWrapper>
            <TextBlockContent Font={Font}>
                <BlockDeleteWrapper onClick={handleBlockDelete}>
                    <DeleteOutlineBlack24Dp/>
                </BlockDeleteWrapper>
                <EditorWrapper>
                    <Editor ref={editor}
                            plugins={plugins}
                            customStyleMap={styleMap}
                            keyBindingFn={keyBindingFn}
                            editorState={editorState}
                            onFocus={() => {
                                stopScreenshotTimer()
                                // TODO: only empty the current block, not full editor
                                // TODO: store set placeholder state on individual blocks
                                // TODO: fix - something is reverting text content after this runs
                                if (IsPlaceholder) {
                                    const contentState = editorState.getCurrentContent()
                                    const entityMap = contentState.getEntityMap()
                                    const clearedBlocks = contentState.getBlocksAsArray().map((block: ContentBlock) => {
                                        return new ContentBlock({
                                            key: block.getKey(),
                                            text: '',
                                            type: block.getType(),
                                            data: block.getData()
                                        })
                                    })

                                    const newContentState = ContentState.createFromBlockArray(clearedBlocks, entityMap)
                                    const newEditorState = EditorState.createWithContent(newContentState)

                                    setEditorState(newEditorState)
                                }
                            }}
                            onChange={newEditorState => {
                                setEditorState(newEditorState)
                                onChange()
                            }}
                            onBlur={() => {
                                handleUpdate({
                                    ID,
                                    Font,
                                    Text,
                                    TextFormat: initializeMagmaTextFormat(TextFormat),
                                }, editorState.getCurrentContent())
                            }}
                            key={ID}
                    />
                </EditorWrapper>


            </TextBlockContent>
            {urlState?.visible &&
            <Portal onClose={() => setUrlState({value: urlState.value, visible: false})}> <LinkWrapper><AddingLinkScreen
                onChange={addLink} defaultValue={{
                ID: 'ss',
                linkType: "standard",
                Url: urlState.value,
            }}/></LinkWrapper></Portal>}

            {suggestionState && users.length > 0 &&
            <PortalSimple><SuggestionBox onSelect={(user: IUser) => {
                const currentSelectionState = editorState.getSelection();
                const end = currentSelectionState.getAnchorOffset();
                const anchorKey = currentSelectionState.getAnchorKey();
                const currentContent = editorState.getCurrentContent();
                const currentBlock = currentContent.getBlockForKey(anchorKey);
                const blockText = currentBlock.getText();
                const start = blockText.substring(0, end).lastIndexOf('@');
                const newText = `@${user.Username}`

                const insert = {
                    editorState,
                    start,
                    end,
                    text: newText,
                    user
                }
                addSuggestion({...insert})
                setSuggestionState(null)
            }} users={users} position={{
                top: suggestionState.top,
                left: suggestionState.left
            }}/></PortalSimple>}
        </>
    )
}


const DropdownMenu = ({onAction}: { onAction: (actionName: string) => void }) => {
    return (
        <>
            <Popover.Item>
                <Button onClick={() => onAction('divider')} icon={<MdHorizontalRule/>} type={"abort"} auto>Add
                    Divider</Button>
            </Popover.Item>
        </>
    )
}
const StyleButton = ({
                         onToggle,
                         _style,
                         label,
                         active
                     }: { onToggle: ToggleStyleFunction, _style: string, label: string, active?: boolean }) => {

    const _onToggle: MouseEventHandler<HTMLButtonElement> = (e) => {

        e.preventDefault();
        onToggle(_style);

    };


    return (
        <StyleButtonWrapper active={active || false} onMouseDown={_onToggle}>
            {label === 'Bold' && <svg width="24" height="24" fill={'currentColor'}>
                <path
                    d="M10.308 17.993h-5.92l.11-.894.783-.12c.56-.11.79-.224.79-.448V5.37c0-.225-.113-.336-.902-.448H4.5l-.114-.894h6.255c4.02 0 5.58 1.23 5.58 3.13 0 1.896-1.78 3.125-3.79 3.463v.11c2.69.34 4.25 1.56 4.25 3.57 0 2.35-2.01 3.69-6.37 3.69l.02.01h-.02zm-.335-12.96H8.967V10.5h1.23c1.788 0 2.79-1.23 2.79-2.683 0-1.685-1.004-2.803-3.006-2.803v.02zm-.223 6.36h-.783v5.588l1.225.23h.22c1.67 0 3.01-1.004 3.01-2.792 0-2.122-1.566-3.016-3.69-3.016h.018z"
                    fillRule="evenodd"/>
            </svg>}
            {label === 'Italic' && <svg fill={"currentColor"} width="24" height="24">
                <path
                    d="M9.847 18.04c-.533 0-2.027-.64-1.92-.853l2.027-7.68-.64-.214-1.387 1.494-.427-.427c.534-1.173 1.707-2.667 2.774-2.667.533 0 2.24.534 2.133.854l-2.133 7.786.533.214 1.6-1.067.427.427c-.64 1.066-1.92 2.133-2.987 2.133zm2.347-11.733c-.96 0-1.387-.64-1.387-1.387 0-1.067.747-1.92 1.493-1.92.854 0 1.387.64 1.387 1.493-.107 1.067-.747 1.814-1.493 1.814z"
                    fillRule="evenodd"/>
            </svg>}
            {label === 'Underline' && <u>U</u>}
            {label === 'H1' && <b>H1</b>}
            {label === 'H2' && <b>H2</b>}
            {label === 'H3' && <b>H3</b>}
            {label === 'H4' && <b>H4</b>}
            {label === 'Normal' && <>P</>}
            {label === 'Code' && <AiOutlineCode size={24}/>}
            {label === 'Strikethrough' && <CgFormatStrike size={24}/>}
            {label === 'Highlight' && <BiHighlight size={24}/>}
            {label === 'Blockquote' && <BsBlockquoteLeft size={24}/>}
            {label === 'Ordered List' && <AiOutlineOrderedList size={24}/>}
            {label === 'Unordered List' && <AiOutlineUnorderedList size={24}/>}
            {label === 'Link' &&
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                    d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
            </svg>}

        </StyleButtonWrapper>
    );
}

const BlockStyleControls = ({editorState, onToggle = () => null, onUndo, onRedo}: any) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <>
            <div style={{display: 'flex'}}>
                <StyleButtonWrapper active={false} onClick={() => onUndo()}>
                    <IoIosUndo size={24}/>
                </StyleButtonWrapper>
                <StyleButtonWrapper active={false} style={{marginLeft: 10}}
                                    onClick={() => onRedo()}>
                    <IoIosRedo size={24}/>
                </StyleButtonWrapper>
            </div>
            <RichTextControls>
                <Select size={"medium"}
                        style={{width: 100, minWidth: 0}}
                        placeholder="Style"
                        value={blockType}
                        onChange={(val) => onToggle(val as string)}>
                    {BLOCK_TYPES.map((type) =>
                        <Select.Option key={type.style} value={type.style}>{type.label}</Select.Option>
                    )}
                </Select>
            </RichTextControls>
        </>
    );
};

const InlineStyleControls = ({
                                 editorState,
                                 onToggleInlineStyle,
                                 onToggleBlockStyle,
                                 onAction = () => null
                             }: StyleControlsProps) => {
    const currentStyle = editorState.getCurrentInlineStyle();

    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    return (
        <>
            <RichTextControls>
                {INLINE_STYLES_PRIMARY.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={onToggleInlineStyle ?? (() => null)}
                        _style={type.style}
                    />
                )}
            </RichTextControls>
            <RichTextControls>
                {BLOCK_STYLES_SECONDARY.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={blockType === type.style}
                        label={type.label}
                        onToggle={onToggleBlockStyle ?? (() => null)}
                        _style={type.style}
                    />
                )}
            </RichTextControls>
            <RichTextControls>
                {INLINE_STYLES_SECONDARY.map((type) =>
                    <StyleButton
                        key={type.label}

                        label={type.label}
                        onToggle={onToggleInlineStyle ?? (() => null)}
                        _style={type.style}
                    />
                )}
                <StyleButton
                    label={'Blockquote'}
                    active={blockType === 'blockquote'}
                    onToggle={onToggleBlockStyle ?? (() => null)}
                    _style={'blockquote'}
                />
                <StyleButton
                    label={'Unordered List'}
                    active={blockType === 'unordered-list-item'}
                    onToggle={onToggleBlockStyle ?? (() => null)}
                    _style={'unordered-list-item'}
                />
                <StyleButton
                    label={'Ordered List'}
                    active={blockType === 'ordered-list-item'}
                    onToggle={onToggleBlockStyle ?? (() => null)}
                    _style={'ordered-list-item'}
                />

            </RichTextControls>
            <RichTextControls>
                <Popover content={<DropdownMenu onAction={onAction}/>}>
                    <StyleButtonWrapper active={false}><ChevronDown/></StyleButtonWrapper>
                </Popover>
            </RichTextControls>
        </>
    );
};

export default TextContent