import styled from "styled-components";
import {FC, FormEventHandler, useCallback, useState} from "react";
import SuggestionBox from "./SuggestionBox";
import {IUser, IUserFragment} from "../lib/Models/User";
import {debounce, remove, toLower} from 'lodash'
import {searchUsers} from "../lib/Fetch/User";

type TTaggingUsersScreen = {
    defaultValue?: IUser[] | IUserFragment[]
    onChange?: (value: IUser[]) => void
}

const DoneButton = styled.button`
  cursor: pointer;

  text-decoration: none;
  white-space: nowrap;
  background-color: ${props => props.theme.colors.secondary.bg};
  color: ${props => props.theme.colors.secondary.text};
  border: none;
  display: block;
  border-radius: 6px;
  line-height: 48px;
  padding: 0 22px;
  font-size: 20px;
  font-weight: 500;
  outline: none !important;
  width: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:active {
    box-shadow: none;
    transform: scale(.98);
    outline: none !important;

  }

  &:focus {
    box-shadow: 0 0 1px 2px ${props => `rgba(${props.theme.colors.secondary.bgColor}, 0.75)`}, 0 1px 1px rgb(0 0 0 / 15%);
    outline: none !important;

  }
`

const SearchInput = styled.input`
  outline: none;
  background: ${props => props.theme.colors.overlayTextFieldBg};

  padding: 14px 30px;
  min-width: 400px;
  transition: .3s ease;
  border-radius: 4px;

  &:focus {
    border-radius: 50px;
  }
`


const Heading = styled.h3`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.overlayPanelBg};

  backdrop-filter: blur(10px);
  padding: 60px;
`

const TaggingUsersScreen: FC<TTaggingUsersScreen> = ({defaultValue = [], onChange}) => {


    const [selectedUsers, setSelectedUsers] = useState<IUser[]>(defaultValue as unknown as IUser[])
    const [searchedUsers, setSearchedUsers] = useState<IUser[]>([])


    const searchedUsersWithoutSelectedUser = () => {

        if (selectedUsers.length < 1) {
            return searchedUsers
        }

        return searchedUsers.filter(user => !selectedUsers.includes(user))
    }

    const handleDone = useCallback(() => {

        if (onChange) {
            onChange(selectedUsers)
        }
    }, [onChange, selectedUsers])


    const handleUserSelect = (user: IUser) => {
        let _users = [...selectedUsers]

        if (_users.includes(user)) {
            remove(_users, user)
            setSelectedUsers(_users)
            return
        }


        _users.push(user)

        setSelectedUsers(_users)
    }

    const handleInputChange: FormEventHandler<HTMLInputElement> = debounce((ev) => {
        const searchString = toLower(ev.target.value)
        searchUsers(searchString).then(setSearchedUsers)
    }, 500)

    return (
        <Wrapper>
            <Heading>Tag Someone</Heading>
            <SearchInput onInput={handleInputChange} placeholder={"Search Users"}/>

            <SuggestionBox position={{top: 0, left: 0}} onSelect={handleUserSelect}
                           users={searchedUsersWithoutSelectedUser()}/>


            {selectedUsers.length > 0 && <><h4>Tagged Users</h4><SuggestionBox position={{top: 0, left: 0}}
                                                                               onSelect={handleUserSelect}
                                                                               users={selectedUsers}/></>}
            <div>
                <DoneButton onClick={handleDone}>
                    Done
                </DoneButton>
            </div>
        </Wrapper>
    )
}

export default TaggingUsersScreen