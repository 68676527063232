import ImageModule from "./Image/ImageModule";
import {FormatSize, ImageOutline, TextureBox} from "../icons";
import {FC} from "react";
import {IModule, IModuleOptions} from "../lib/Models/Module";
import ImageModuleOptions from "./Image/ImageModuleOptions";
import TextModule from "./Text/TextModule";
import TextModuleOptions from "./Text/TextModuleOptions";
import BackgroundModule from "./Background/BackgroundModule";
import BackgroundModuleOptions from "./Background/BackgroundModuleOptions";

export interface IModuleDeclaration {
    name: string
    component: FC<IModule>
    icon: FC
    options: FC<IModuleOptions>
    defaultMeta?: any
}

const modules: IModuleDeclaration[] = [
    {
        name: 'Image',
        component: ImageModule,
        icon: ImageOutline,
        options: ImageModuleOptions,
        defaultMeta: {
            x: 0,
            y: 0,
            size: [570, 570]
        }
    },
    {
        name: 'Background',
        component: BackgroundModule,
        icon: TextureBox,
        options: BackgroundModuleOptions
    },
    {
        name: 'Text',
        component: TextModule,
        icon: FormatSize,
        options: TextModuleOptions,
        defaultMeta: {
            x: 100,
            y: 300
        }
    }
]

export default modules