import React, {useContext} from "react";
import {Router} from "@reach/router";
import SignIn from "./Auth/SignIn";
import SignUp from "./Auth/SignUp";
import PasswordReset from "./Auth/PasswordReset";
import {UserContext} from "../Providers/Auth/UserProvider";
import CreationSuite from "../Views/CreationSuite";
import Create from "../Views/Create";
import MyDrafts from "../Views/MyDrafts";
import Onboarding from "../Views/Onboarding";

function Application(): JSX.Element {
    const user = useContext(UserContext);

    if (user === null) {
        return (
            <div>Loading...</div>
        )
    }


    return (
        user ?
            <div style={{background: '--app-bg-color'}}>
                <Router>
                    <CreationSuite path={"/"}/>
                    <Onboarding path={"/create"}/>
                    <Create path={"/create/:magID"}/>
                    <MyDrafts path={"/drafts"}/>
                </Router>
            </div>
            :
            <Router>
                <SignUp path="signUp"/>
                <SignIn path="/"/>
                <PasswordReset path="passwordReset"/>
            </Router>

    );
}

export default Application;