import MagmaTextEntity from "./MagmaTextEntity";
import MagmaPhotoEntity from "./MagmaPhotoEntity";
import MagmaVideoEntity from "./MagmaVideoEntity";
import MagmaLocationEntity from "./MagmaLocationEntity";
import MagmaSocialMediaEntity from "./MagmaSocialMediaEntity";

export type MagmaBlockContentType = 'Map' | 'Master' | 'Photo' | 'Text' | 'Video' | 'Social'


interface TMagmaBlockContent<T> {
    type: MagmaBlockContentType
    content: T,
    IsPlaceholder?: boolean
    onUpdate?: (content: MagmaBlockContent<T>) => void
}

export default class MagmaBlockContent<T> {
    Text?: MagmaTextEntity
    Photo?: MagmaPhotoEntity

    Video?: MagmaVideoEntity
    Map?: MagmaLocationEntity
    Social?: MagmaSocialMediaEntity

    constructor({
                    type,
                    content,
                }: TMagmaBlockContent<T>) {

        if (type === 'Photo') {
            this.Photo = content as unknown as MagmaPhotoEntity
        }

        if (type === 'Text') {
            this.Text = content as unknown as MagmaTextEntity
        }

        if (type === 'Video') {
            this.Video = content as unknown as MagmaVideoEntity
        }

        if (type === 'Map') {
            this.Map = content as unknown as MagmaLocationEntity
        }
        if (type === 'Social') {
            this.Social = content as unknown as MagmaSocialMediaEntity
        }

    }

}