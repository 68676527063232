import {FC} from "react";
import ModuleOptionsWrapper from "../ModuleOptionsWrapper";
import Uploader from "../Image/Components/Uploader";
import {IModuleOptions} from "../../lib/Models/Module";
import {FilePondFile} from "filepond";
import UnsplashSearch from "../Image/Components/UnsplashSearch";
import {Basic} from "unsplash-js/dist/methods/photos/types";
import {urlToBlob} from "../../helpers";
import {uploadAssetToMag} from "../../lib/Models/Asset";
import {useParams} from "@reach/router";
import TexturePicker from "./Components/TexturePicker";
import {TextureData} from "../../lib/Models/Texture";

const BackgroundModuleOptions: FC<IModuleOptions> = ({onDone, id, meta, onUpdate, onRemove}) => {

    const {magID} = useParams()

    const updateFiles = (data: FilePondFile | null) => {

        if (data === null) {
            return
        }

        // @ts-ignore
        const url = data?.getFileEncodeDataURL()

        if (url) {
            urlToBlob(url).then(blob => {

                uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {

                    onUpdate({
                        id,
                        action: 'updateMeta',
                        data: {
                            design: {
                                asset
                            }
                        }
                    })
                })
            })
        }
    }

    const updateWithUnsplash = (data: Basic) => {

        urlToBlob(data?.urls.regular).then(blob => {
            uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {

                onUpdate({
                    id,
                    action: 'updateMeta',
                    data: {
                        // @ts-ignore
                        design: {
                            asset
                        }
                    }
                })
            })
        })
    }

    const updateTexture = (data: TextureData) => {


        onUpdate({
            id,
            action: 'updateMeta',
            data: {
                design: {
                    asset: data.Asset
                }
            }
        })
    }


    return (
        <ModuleOptionsWrapper name={"Background"} {...{onDone, onRemove}}>
            <Uploader url={meta?.design?.asset.URL} onUpdateFiles={updateFiles}/>
            <hr className={"my-8"}/>
            <UnsplashSearch onSelection={updateWithUnsplash}/>
            <TexturePicker onSelection={updateTexture}/>
        </ModuleOptionsWrapper>
    )
}


export default BackgroundModuleOptions