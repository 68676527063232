import {ChangeEventHandler, FC} from "react";
import styled from "styled-components";
import {useParams} from "@reach/router";
// @ts-ignore
import {readAndCompressImage} from 'browser-image-resizer';
import {uploadAssetToMag} from "../../lib/Models/Asset";
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
import cuid from "cuid";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";

// @ts-ignore
import isVideo from 'is-video'
import MagmaVideoEntity from "../../lib/Content/MagmaVideoEntity";
import VideoSnapshot from "video-snapshot";
import {urlToBlob} from "../../helpers";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 6px 12px;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.secondary.bg}
  }
`

const Text = styled.span`
  font-size: 1.2rem;
  margin-left: 18px;
`

const FileInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
`

const Uploader: FC<{ updateContent?: (content: MagmaBlockContent<unknown>) => void }> = ({updateContent}) => {

    const {magID} = useParams()


    const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {


        const files = event.currentTarget.files

        if (!files) {
            return
        }

        const [file] = Array.from(files)

        if (isVideo(file.name)) {

            const snapshoter = new VideoSnapshot(file);

            const placeholder = await readAndCompressImage(await urlToBlob(await snapshoter.takeSnapshot()), {
                quality: 0.8,
                maxWidth: 1400,
                maxHeight: 1400,
            })

            const storedPlaceholder = await uploadAssetToMag({magID, extension: 'jpg', file: placeholder})

            uploadAssetToMag({
                magID,
                extension: file.name.split('.').pop()?.toLowerCase() ?? 'mp4',
                file
            }).then(asset => {
                if (updateContent) {
                    updateContent(new MagmaBlockContent(
                        {
                            type: 'Video',
                            content: new MagmaVideoEntity({
                                Title: "",
                                Asset: asset,
                                Caption: "",
                                ID: cuid(),
                                Provider: 2,
                                URL: asset.URL,
                                Placeholder: storedPlaceholder,
                                Thumbnail: storedPlaceholder.URL
                            })
                        }
                    ))
                }
            })

            return

        }


        const compressedFile: Blob = await readAndCompressImage(file, {
            quality: 0.8,
            maxWidth: 1400,
            maxHeight: 1400,
        })

        uploadAssetToMag({magID, extension: 'jpg', file: compressedFile}).then(asset => {
            if (updateContent) {
                updateContent(new MagmaBlockContent(
                    {
                        type: 'Photo',
                        content: new MagmaPhotoEntity({
                            Caption: "",
                            TaggedUsers: [],
                            ShoppingLink: "",
                            ID: cuid(),
                            Asset: asset
                        })
                    }
                ))
            }
        })
    }

    return (
        <Wrapper>
            <FileInput type="file" onChange={handleFileChange}/>
            <img src={"/icon/white-camera-roll.png"} style={{height: 30}} alt={"white camera roll icon"}/>
            <Text>Upload From Computer</Text>
        </Wrapper>
    )
}

export default Uploader