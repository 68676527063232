export interface IPrivacy {
    Name: string
    Label?: string
    Description: string
}

export const PrivacyOptions: IPrivacy[] = [
    {
        Name: 'Public',
        Description: 'Anyone can see your Mag.'
    },
    {
        Name: 'Sub',
        Label: 'Subscribers',
        Description: 'Only people who subscribed to you can see your Mag.'
    },
    {
        Name: 'Private',
        Description: 'Only you and people you share a link with can see your Mag.'
    }
]