import {FC, memo, useCallback} from "react";
import styled from "styled-components";
import Logo from "../Components/Logo";
import Profile from "../Components/Header/Profile";
import {IUser} from "../lib/Models/User";
import {Link, Match} from "@reach/router";
import {Select} from "@geist-ui/react";
import {Moon, Sun} from '@geist-ui/react-icons'
import {GLOBAL_EVENTS, trigger} from "../customEvents";

const Wrapper = styled.header`
  position: sticky;
  top: 0;
  background: #000;
  border-bottom-width: 2px;
  border-style: solid;
  z-index: 999;
  border-color: ${props => props.theme.colors.borderColor};
`
const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
`

const LeftNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

`

const RightNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`


const NavLink = styled(Link)<{ active: boolean }>`
  font-size: 1.4rem;
  margin-left: 22px;
  display: inline-block;
  margin-top: 3px;
  color: #fff;
  font-weight: ${props => props.active ? 600 : 400}
`

declare interface Header_Props {
    user: IUser
}

const Header: FC<Header_Props> = ({user}) => {


    const changeTheme = useCallback((ev) => {
        const theme = ev === '2' ? 'dark' : 'light'
        trigger(GLOBAL_EVENTS.THEME_CHANGE, {theme})
    }, [])

    return (

        <Wrapper>
            <Container>
                <Nav>
                    <LeftNav>
                        <div className={"mr-12"}>
                            <Logo/>
                        </div>

                    </LeftNav>
                    <RightNav>
                        <Match path={"/"}>
                            {/* @ts-ignore   */
                                props => <NavLink to={"/"} active={props.match}>Mags</NavLink>}
                        </Match>
                        <Match path={"/drafts/*"}>
                            {/* @ts-ignore   */
                                props => <NavLink to={"/drafts"} active={props.match}>Drafts</NavLink>}
                        </Match>
                        <div style={{marginLeft: 20}}>
                            <Select placeholder="Choose one" initialValue={'1'} pure
                                    onChange={changeTheme}

                                    style={{width: 50, minWidth: '6rem'}}>
                                <Select.Option value="1">
                                    <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}><Sun
                                        size={16}/> Light
                                    </div>
                                </Select.Option>
                                <Select.Option value="2">
                                    <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}><Moon
                                        size={16}/> Dark
                                    </div>
                                </Select.Option>
                            </Select>
                        </div>
                        <Profile username={user.Username} src={user.PhotoUrl}/>
                    </RightNav>
                </Nav>
            </Container>
        </Wrapper>
    )
}

export default memo(Header)