export type widthLayoutMode =
    'fullWidth'
    | 'fullWidthIgnoringInsets'
    | 'halfWidth'
    | 'thirdWidth'
    | 'thirdWidth'
    | 'quarterWidth'
    | 'fifthWidth'
export type heightLayoutMode = 'dynamic' | 'stretch'


export interface IMagmaBlockLayoutMode {
    Width?: number
    Height?: number
}

export default class MagmaBlockLayoutMode implements IMagmaBlockLayoutMode {

    static DECODE_KEYS = {
        Width: {
            0: 'fullWidth',
            1: 'fullWidthIgnoringInsets',
            2: 'halfWidth',
            3: 'thirdWidth',
            4: 'thirdWidth',
            5: 'quarterWidth',
            6: 'fifthWidth',
        },
        Height: {
            0: 'dynamic',
            1: 'stretch'
        }
    }
    Width: number
    Height: number

    constructor({Width = 0, Height = 0}: { Width?: number, Height?: number }) {
        this.Width = Width
        this.Height = Height
    }

    static default = () => {
        return new MagmaBlockLayoutMode({Width: 0, Height: 1})
    }

}