interface MagmaBlockInsetsHorizontalVertical {
    horizontal: number
    vertical: number
}

export interface IMagmaBlockInsetsAllEdges {
    Top: number
    Right: number
    Bottom: number
    Left: number
}

interface MagmaBlockInsetsEdges {
    edges: number

}

export type  MagmaBlockInsetsProps =
    IMagmaBlockInsetsAllEdges
    | MagmaBlockInsetsEdges
    | MagmaBlockInsetsHorizontalVertical

export default class MagmaBlockInsets {
    Top: number = 0
    Right: number = 0
    Bottom: number = 0
    Left: number = 0


    constructor(props: MagmaBlockInsetsProps) {

        if ('Left' in props) {
            this.applyIndividualEdges && this.applyIndividualEdges(props)
        }

        if ('edges' in props) {
            this.applyEdges && this.applyEdges(props)
        }

        if ('horizontal' in props) {
            this.applyHorizontalVertical && this.applyHorizontalVertical(props)
        }
    }

    private applyEdges?({edges}: MagmaBlockInsetsEdges) {
        this.applyIndividualEdges && this.applyIndividualEdges({Top: edges, Right: edges, Bottom: edges, Left: edges})
    }

    private applyHorizontalVertical?({horizontal, vertical}: MagmaBlockInsetsHorizontalVertical) {
        this.applyIndividualEdges && this.applyIndividualEdges({
            Top: vertical,
            Right: horizontal,
            Bottom: vertical,
            Left: horizontal
        })
    }

    private applyIndividualEdges?({Top, Right, Bottom, Left}: IMagmaBlockInsetsAllEdges) {
        this.Top = Top
        this.Right = Right
        this.Bottom = Bottom;
        this.Left = Left
    }

}