import {FC} from "react";
import styled from "styled-components";

interface SkeletonMagCardProps {
    width?: number
    height?: number
    rounded?: boolean
    color?: string
    count?: number
}
const Card = styled.article`
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  border-radius: 8px;
  background: #cecece;
  position: relative;
`

const SkeletonMagCard: FC<SkeletonMagCardProps> = ({width, height, rounded, count, color}) => {

    count = count || 1

    const elements=[];
    for(let i=0;i<count;i++){
        // push the component to elements!
        elements.push(<li key={i}><Card><img alt={"Placeholder"} style={{opacity:0.01}} src="https://ik.imagekit.io/nginr/mg_zCdadca2h.png?tr=w-300,h-480,q-1" loading={"lazy"}/></Card></li>);
    }

    return (
        <>
            {elements}
        </>
    )

}

export default SkeletonMagCard