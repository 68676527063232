import {FC, useContext, useState} from "react";
import styled from "styled-components";
import {ChevronDoubleRight, Plus} from "../icons";
import {PagesContext, PageState} from "../Providers/Page/PageProvider";
import {first} from "lodash";
import {useListener} from "react-bus";
import {TiTimes} from "react-icons/all";
import ControlledTemplateCanvas from "./ControlledTemplateCanvas";

declare interface IMagNavigator_Props {
    onOpen?: () => void
    onClose?: () => void
    onNewPage?: () => void
    onSwitchPage?: () => void
}

const Placeholder = styled.section<{ active?: boolean }>`
  width: ${props => props.active ? 250 : 0}px;
  transition: .3s ease;
  transform: translate3d(0, 0, 0, 0);
`


const SwitchHandler = styled.a<{ active?: boolean }>`
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 50%;
  font-size: 1.4em;
  color: #fff;
  transform: ${props => props.active ? 'translateX(-50%)' : 'rotate(180deg)'};
  transition: .3s ease;

`

const RootWrapper = styled.section<{ active?: boolean }>`

  height: calc(100vh - 122px);
  padding: 15px 15px 15px 30px;
  margin-top: 46px;
  position: fixed;
  right: 0;
  background: #4D4D4D;
  overflow: ${props => props.active ? 'auto' : 'hidden'};
  width: ${props => props.active ? 250 : 0}px;
  transition: .3s ease;
  will-change: width;
  transform: translate3d(0, 0, 0, 0);
  background: ${props => props.theme.colors.magNavigatorBackground};

  .body {
    opacity: ${props => props.active ? 1 : 0};

  }
`

const NewPage = styled.div`
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`

const MagNavigator: FC<IMagNavigator_Props> = ({onNewPage, onSwitchPage}) => {
    // @ts-ignore
    const [pagesState, dispatchPagesState] = useContext(PagesContext)
    const [active, setActive] = useState<boolean>(true)

    const handleSwitch = () => {
        setActive(!active)
    }


    useListener('focusModeEnabled', () => {
        setActive(false)
    })

    const handleNewPage = () => {
        if (onNewPage) {
            onNewPage()
        }
    }

    const handleSwitchPage = (uid: string) => {

        dispatchPagesState({
            type: "SWITCH_PAGE",
            payload: uid
        })
        if (onSwitchPage) {
            onSwitchPage()
        }
    }

    const handleDeletePage = (uid: string) => {
        dispatchPagesState({
            type: "REMOVE_PAGE",
            payload: uid
        })
        if (onSwitchPage) {
            onSwitchPage()
        }
    }

    const cover = pagesState?.Pages && first(pagesState?.Pages)
    const coverSnapshot = pagesState?.Proofs && (pagesState?.Proofs[0]?.preview?.URL || null)
    const pages = pagesState?.Pages?.filter((page: PageState, idx: number) => idx !== 0)


    return (
        <>
            <Placeholder active={active}/>
            <RootWrapper active={active}>
                <SwitchHandler onClick={handleSwitch} active={active}>
                    <ChevronDoubleRight/>
                </SwitchHandler>
                <div className={"text-white body"}>

                    <h4>
                        Cover
                    </h4>
                    <hr/>
                    <div className={"grid grid-cols-2 gap-4 mt-8"} data-id={cover?.ID}>
                        <button className={"bg-white"} onClick={() => handleSwitchPage(cover?.ID)}>
                            {coverSnapshot ? <img alt={"Cover Snapshot"} height={145.9} src={coverSnapshot}/> :
                                <div style={{height: 145.9}}>
                                </div>}
                        </button>
                    </div>
                </div>

                <div className={"text-white body mt-8"}>
                    <h4>
                        Pages
                    </h4>
                    <hr/>
                    <div className={"grid grid-cols-2 gap-4 mt-8"}>
                        {pages?.map((page: PageState, idx: number) =>
                            page
                            && <div className="relative" data-id={page.ID} key={page.ID + '_page_thumb'}>
                                <button className="absolute top-0 right-0 text-black hover:opacity-100 opacity-75 z-10"
                                        style={{background: 'rgba(0,0,0,0.9)'}}
                                        onClick={() => handleDeletePage(page.ID)}>
                                    <TiTimes color={'#fff'} size={20}/>
                                </button>
                                <button className="block object-cover w-full bg-white overflow-hidden"
                                        style={{maxHeight: `155.9px`}}
                                        onClick={() => handleSwitchPage(page.ID)}>
                                    {/* eslint-disable-next-line react/jsx-no-undef */}
                                    {Array.isArray(page.Blocks) ?
                                        <ControlledTemplateCanvas components={page.Blocks} removeModule={() => null}
                                                                  setActiveComponent={() => null}
                                                                  snapshot
                                                                  centerX={false} sortEnabled={false}/> :
                                        <div style={{height: 145.9, width: "100%"}}/>}
                                </button>
                            </div>)}
                        <div className={"bg-white"}>
                            <NewPage style={{height: 145.9}} onClick={handleNewPage}>
                                <Plus/>
                            </NewPage>
                        </div>
                    </div>
                </div>
            </RootWrapper>
        </>
    )
}
export default MagNavigator