import {FC, useState} from "react";
import styled from "styled-components";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import Portal from "../../Portal";
import YoutubeVideoSearch from "../YoutubeVideoSearch";
import MagmaVideoEntity, {YoutubeVideo} from "../../lib/Content/MagmaVideoEntity";
import {isNil, omitBy} from 'lodash'

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 6px 12px;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.secondary.bg}
  }
`

const Text = styled.span`
  font-size: 1.2rem;
  margin-left: 18px;
`

const Youtube: FC<{ updateContent?: (content: MagmaBlockContent<MagmaVideoEntity>) => void }> = ({updateContent}) => {


    const [addingVideo, setAddingVideo] = useState<boolean>(false)


    const handleVideoSelection = (video: YoutubeVideo) => {
        //@ts-ignore
        const videoId = video.id.videoId ?? video.id
        const newVideo = {
            Video: omitBy(new MagmaVideoEntity({
                Author: video.snippet.channelTitle,
                ID: videoId,
                Provider: 0,
                ShoppingLink: "",
                Thumbnail: video.snippet.thumbnails.high.url,
                Title: video.snippet.title,
                URL: `https://youtube.com/embed/${videoId}?modestbranding=1&playsinline=1`,
            }), isNil) as MagmaVideoEntity
        }

        if (updateContent) {
            updateContent(newVideo)
        }
    }

    return (
        <>
            <Wrapper onClick={() => setAddingVideo(true)}>
                <img src={"/icon/youtubeLinkIcon.png"} style={{height: 30, width: 30, objectFit: 'cover'}}
                     alt={"youtube icon"}/>
                <Text>Youtube</Text>
            </Wrapper>
            {addingVideo && <Portal onClose={() => setAddingVideo(false)}>
                <YoutubeVideoSearch onVideoSelection={handleVideoSelection}/>
            </Portal>
            }
        </>
    )
}

export default Youtube