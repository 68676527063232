import {IBounds} from "./Block";
import {IAsset} from "./Asset";

export const POINTS_TO_PIXELS_MULTIPLIER = 3

export interface CompState {
    image: IAsset;
    bound: IBounds
    component?: string
    center: [number, number]
    design?: {
        asset: IAsset
        editable: boolean
        id: string
        isLogo: boolean
        opacity: number
        frame?: {
            Origin: [number, number]
            Size: [number, number]

        }
    }
    label?: {
        characterSpacing: number
        fontColor: {
            red: number
            green: number
            blue: number
            alpha: number
        }
        fontName: string
        fontSize: number
        lineHeight: number
        numberOfLines: number
        text: string
        textAlignment: number
    }
    id: string
    tag: number
    transform: [number, number, number, number, number, number]
}

export const convertPixelsToPoints = (pixels: number): number => {
    return pixels / POINTS_TO_PIXELS_MULTIPLIER
}

export const convertPointsToPixels = (points: number): number => {
    return points * POINTS_TO_PIXELS_MULTIPLIER
}