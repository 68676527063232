import {FC, MouseEventHandler} from "react";
import styled from "styled-components";


const ButtonContainer = styled.button<any>`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  background-color: ${props => props.theme.colors[props.color].bg};
  color: ${props => props.dark ? props.theme.colors.darkText : props.theme.colors[props.color].text};
  border: none;
  border-radius: 6px;
  line-height: 48px;
  padding: 0 22px;
  font-size: 20px;
  font-weight: 500;
  outline: none !important;

  width: ${props => props.block ? '100%' : 'auto'};
  opacity: ${props => props.disabled ? 0.4 : 1};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};;

  &:active {
    box-shadow: none;
    transform: scale(.98);
    outline: none !important;

  }

  &:focus {
    box-shadow: 0 0 1px 2px ${props => `rgba(${props.theme.colors[props.color].bgColor}, 0.75)`}, 0 1px 1px rgb(0 0 0 / 15%);
    outline: none !important;

  }
`

declare interface Button_Props {
    color?: string
    dark?: boolean
    block?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    type?: string
    disabled?: boolean
}

const Button: FC<Button_Props> = (props) => {

    const color = props.color || 'secondary'

    return (
        <ButtonContainer disabled={props.disabled} type={props.type} color={color} block={props.block}
                         onClick={props.onClick}>
            {props.children}
        </ButtonContainer>
    )
}

export default Button