import {storage} from "../../Integrations/firebase";
import cuid from "cuid";


export interface IAsset {
    ID: string
    FileType: string
    Directory: string
    URL: string
}


declare interface IUploadAssetToMag_Props {
    magID: string
    file: File | Blob
    extension?: string
    isScreenshot?: boolean
}

declare interface IDeleteAsset_Props {
    ID: string
    Directory: string
    FileType: string
}

export const uploadAssetToMag = async ({
                                           magID,
                                           file,
                                           extension = 'jpg',
                                           isScreenshot = false
                                       }: IUploadAssetToMag_Props): Promise<IAsset> => {

    if (!isScreenshot) {
        window.dispatchEvent((new CustomEvent('start_loading')))
    }
    return await new Promise((resolve, reject) => {

        const storageRef = storage.ref()
        const _id = cuid();

        const assetPath = `/mags/${magID}/${_id}.${extension}`

        const uploadTask = storageRef.child(assetPath).put(file)

        uploadTask.on('state_changed',
            (snapshot) => null,
            (error) => {
                window.dispatchEvent((new CustomEvent('stop_loading')))

                reject(`error uploading file to firebase`)

            },
            () => {
                resolve({
                    FileType: extension,
                    ID: `${_id}`,
                    Directory: `mags/${magID}`,
                    URL: `https://assets.magma.sh${assetPath}`
                })

            },
        );

    })
}

export const deleteAsset = async ({ID, Directory, FileType}: IDeleteAsset_Props): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
        const storageRef = storage.ref()
        const assetPath = `${Directory}/${ID}.${FileType}`
        storageRef.child(assetPath).delete()
            .then((res) => {
                resolve(true)
            }).catch((error) => {
            reject(`error deleting file from firebase`)
        })
    })

}

export const makeAssetEntity = (data: { id: string, name: string, url: string, fileType: 'png' | 'jpg' | 'jpeg', directory: string }): IAsset => {
    return {
        Directory: data.directory,
        FileType: data.fileType,
        ID: data.id,
        URL: data.url
    }
}