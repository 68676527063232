import {IUser} from "../lib/Models/User";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {deleteDraft, deleteMag, getUserDrafts, getUserMags, MagData} from "../lib/Models/Mag";
import styled from "styled-components";
import MagCard from "./MagCard";
import SkeletonMagCard from "./Loaders/SkeletonMagCard";
import {navigate} from "@reach/router";

// @ts-ignore
import {Plus} from "../icons";
import {PagesContext} from "../Providers/Page/PageProvider";
import cuid from "cuid";
import {cloneDeep} from "lodash";
import Portal from "../Portal";
import Prompt from "./Prompt";
import {FaTimes} from "react-icons/all";

interface UserMags_Props {
    user: IUser,
    type?: 'published' | 'draft'
}

interface UserMags_State {
    loading: boolean,
    mags: MagData[]
}

const Wrapper = styled.section`
  padding: 70px 0;
`
const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const MagList = styled.ul`
  display: flex;

  gap: 4rem;
  flex-wrap: wrap;
`

const NewMag = styled.a`
  cursor: pointer;
  border-radius: 8px;
  background: #d0d0d0;
  display: block;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`

const Overlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 4rem;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.colors.secondary.text};
`

const coverID = cuid()

const DEFAULT_STATE = {
    Pages: [
        {
            ID: coverID,
            LayeredBlocks: [],
            Blocks: []
        }
    ],
    activePage: coverID
}

const DeleteButton = styled.button`
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
`

const UserMags: FC<UserMags_Props> = ({user, type = 'published'}) => {
    const createNewMag = () => {

        navigate(`/create`)
    }

    const [magState, setMagState] = useState<UserMags_State>({
        loading: true,
        mags: []
    })
    const [deletingMag, setDeletingMag] = useState<MagData | null | undefined>(null)


    // @ts-ignore
    const pagesStateDispatch = useContext(PagesContext)[1]

    const isPublished = useCallback(() => {
        return type === 'published'
    }, [type])

    useEffect(() => {
        if (isPublished()) {
            getUserMags(user.uid).then((data: MagData[]) => {
                setMagState({loading: false, mags: data})
            })
        } else {
            getUserDrafts(user.uid).then((data: MagData[]) => {
                setMagState({loading: false, mags: data})
            })
        }

        pagesStateDispatch({
            type: 'SET_ACTIVE_MAG_ID',
            payload: null
        })

        pagesStateDispatch({
            type: 'SET_MAG',
            payload: {
                ...cloneDeep(DEFAULT_STATE),
                Proofs: []
            }
        })
    }, [setMagState, user.uid, isPublished, pagesStateDispatch])


    const handleDeleteMag = async (id: string) => {

        setDeletingMag(magState.mags.find(mag => mag.ID === id))

    }

    const forceDeleteMag = () => {
        if (deletingMag?.ID) {
            const removed = isPublished() ? deleteMag(deletingMag.ID) : deleteDraft(user.uid, deletingMag.ID)

            if (removed) {
                const _magState = {
                    ...magState,
                    mags: magState.mags.filter(mag => mag.ID !== deletingMag.ID)
                }
                setMagState(_magState)
            }
            setDeletingMag(null)
        }

    }


    return (
        <Wrapper>
            <Container>
                <MagList>
                    <li>
                        <NewMag onClick={createNewMag}>
                            <img style={{opacity: 0.1}} alt="Placeholder"
                                 width={300}
                                 height={480}
                                 src="https://ik.imagekit.io/nginr/mg_zCdadca2h.png?tr=w-300,h-480,q-1"
                            />
                            <Overlay>
                                <Plus/>
                            </Overlay>
                        </NewMag>
                    </li>
                    {magState?.loading ? <SkeletonMagCard count={user.MagsCount}/> : magState.mags.map(mag => <li
                        key={mag.ID} className="relative">
                        <DeleteButton className="absolute top-1 right-1 text-black text-2xl p-1 m-1 z-10"
                                      onClick={() => mag.ID && handleDeleteMag(mag.ID)}>
                            <FaTimes color={'#fff'} size={16}/>
                        </DeleteButton>

                        {mag.Privacy && mag.Privacy === 'Private' &&
                        <span className="absolute top-0 left-2 text-black text-2xl p-2 z-10 text-secondary"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill={"rgb(251, 179, 30)"}
                            >
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                            </svg>
                        </span>}
                        <MagCard isPublished={isPublished()} mag={mag}/>
                    </li>)}
                </MagList>
            </Container>
            {deletingMag && <Portal onClose={() => setDeletingMag(null)}>
                <Prompt mag={deletingMag} onCancel={() => setDeletingMag(null)}
                        onAgree={() => forceDeleteMag()}/>
            </Portal>}
        </Wrapper>
    )

}

export default UserMags