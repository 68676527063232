import {firestore} from "../../Integrations/firebase";
import {buildSearchQuery} from "../../helpers";
import {IUser} from "../Models/User";
import firebase from "firebase/app";


const searchUsers = async (searchTerm: string, limit: number = 5): Promise<IUser[]> => {
    if (searchTerm === '') {
        return []
    }
    const collection = firestore.collection('users')

    const query = buildSearchQuery<IUser>(collection as firebase.firestore.CollectionReference<IUser>, searchTerm).limit(limit)

    return await query.get().then((results) => {
        return results.docs.map((result) => ({
            ...result.data(),
            ID: result.id
        }))

    })

}

export {searchUsers}