import { FC, memo } from "react";
import PhotoComp from "./PhotoComp";
import Grid, { Column } from "../../HOC/Grid";
import { chunk } from 'lodash'
import { Basic } from "unsplash-js/dist/methods/photos/types";

const columnsCount = 4;

const Feed: FC<{ photos: Basic[], onSelection?: (photo: Basic) => void }> = ({ photos, onSelection }) => {
    const chunks = chunk(photos, Math.ceil(photos.length / columnsCount))

    const selectImage = onSelection ? (photo: Basic) => onSelection(photo) : () => null

    return (
        <div className="feed" style={{ "--column-gutter": "24px", "--columns": columnsCount, "--row-gutter": "24px" }}>
            <Grid>
                {chunks.map((column, idx) => <Column key={idx}>
                    <>
                        {column.map((photo) => (
                            <div key={photo.id}>
                                <PhotoComp onSelection={() => selectImage(photo)} photo={photo} />
                            </div>
                        ))}
                    </>
                </Column>
                )}
            </Grid>

        </div>
    );

};


export default memo(Feed)