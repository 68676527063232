import { ICoverTemplate } from ".."

const BlankCategory: ICoverTemplate[] = [
    {
        title: 'blank',
        thumbnail: '/Assets/blank_placeholder-80.jpg',
        category: 'Blank',
        meta: []
    }
]

export default BlankCategory