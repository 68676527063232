import {DocumentData} from "@google-cloud/firestore";
import {firestore} from "../../Integrations/firebase";
import {buildCDNUrl} from "../../helpers";

export interface IGenreFragment {
    name: string
    thumbnail: string
    featured: boolean
}

export interface IGenre {
    ID: string
    Name: string
    Featured?: boolean
    SubscribersCount: number
    Thumbnail: string
}

export function createGenre(data: DocumentData): IGenre {


    return {
        ID: data.ID,
        SubscribersCount: data.SubscribersCount,
        Name : data.Name,
        Thumbnail: buildCDNUrl(data.Thumbnail),
        Featured: data.Featured
    }
}


export const getAllGenres = async () : Promise<IGenre[]> => {
    return await firestore.collection('genres').orderBy('Name').get()
        .then(snapshot => {
            return snapshot.docs.map(doc => createGenre({...doc.data(), ID: doc.id}))
        })
}