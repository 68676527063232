import {ChangeEventHandler, FC, memo} from "react";
import styled from "styled-components";

declare interface Input_Props {
    type: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    name?: string
    value?: string|number
    placeholder? : string
    id?: string
    stacked?: boolean
}

const InputContainer = styled.input<Input_Props>`
  border: 1px solid #dddfe2;
  color: #1d2129;
  vertical-align: middle;
  font-size: 17px;
  border-radius: 6px;
  padding: 14px 16px;
  height: 60px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${props => props.stacked ? '20px' : 0};
  &:focus{
    border-color: #1877f2;
    box-shadow: 0 0 0 2px #e7f3ff;
    caret-color: #1877f2;
    outline:none!important;
  }
`

const Input:FC<Input_Props> = (props) => {
    return (
        <InputContainer stacked type={props.type} placeholder={props.placeholder} value={props.value} id={props.id} onChange={props.onChange} name={props.name}/>
    )
}


export default memo(Input)