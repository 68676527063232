import React, {FunctionComponent, useEffect, useRef} from 'react'
import MagmaSocialMediaEntity from "../../lib/Content/MagmaSocialMediaEntity";

const INSTAGRAM_API = `//www.instagram.com/embed.js`
const SocialContent: FunctionComponent<MagmaSocialMediaEntity> = ({Html}) => {

    const elRef = useRef<HTMLDivElement>(null)


    useEffect(() => {


        if (!document.querySelector('#instagramAPI')) {
            const script = document.createElement("script");
            script.id = 'instagramAPI'
            script.src = INSTAGRAM_API;
            script.async = true;

            document.body.appendChild(script);
        } else {
            // @ts-ignore
            window.instgrm?.Embeds.process()
        }
        window.onmessage = ({origin, data}: { origin: string, data: any }) => {
            if (origin.indexOf('instagram.') < 0) {
                return
            }
            data = JSON.parse(data)

            const el = elRef.current

            if (data.type === 'MEASURE' && el !== null) {
                el.style.height = data.details.height + 'px'
                const adjuster = el.parentElement?.parentElement?.parentElement
                if (adjuster) {
                    adjuster.style.paddingTop = data.details.height + 40 + 'px'
                }
            }
        }

        return () => {

        }

    }, [])

    return (
        <>

            {Html && <div ref={elRef} dangerouslySetInnerHTML={{__html: Html}}></div>}
        </>
    )
}

export default SocialContent
