import styled from "styled-components";
import {FC, useCallback, useContext} from "react";
import {PagesContext} from "../../Providers/Page/PageProvider";

const Root = styled.div`
  border-top: 1px solid #525252;
  padding: 20px 0;
  margin-top: 40px;
`

const List = styled.div`

`

const ActionButton = styled.button`
  display: block;
  width: 100%;
  margin-bottom: 2px;
  padding: 10px 20px;

  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;

  svg {

    width: 1.5rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const LayersOptions: FC<{ id: string, onUpdate: () => void }> = ({id, onUpdate}) => {
    // @ts-ignore
    const [, pagesStateDispatch] = useContext(PagesContext)


    const updateLayerOrder = useCallback(({direction}: { direction: -1 | 1 | 'back' | 'front' }) => {

        pagesStateDispatch({
            type: 'CHANGE_ORDER',
            payload: {direction, id}
        })
        setTimeout(onUpdate, 0)

    }, [id, onUpdate, pagesStateDispatch])

    return (
        <Root>
            <List>
                <ActionButton onClick={() => updateLayerOrder({direction: 'front'})}>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"

                    >
                        <path
                            fill="currentColor"
                            d="M32 208V48a16 16 0 0116-16h160a16 16 0 0116 16v16h32V48a48 48 0 00-48-48H48A48 48 0 000 48v160a48 48 0 0048 48h80v-32H48a16 16 0 01-16-16zm448 160V144a48 48 0 00-48-48H208a48 48 0 00-48 48v224a48 48 0 0048 48h224a48 48 0 0048-48zm-288 0V144a16 16 0 0116-16h224a16 16 0 0116 16v224a16 16 0 01-16 16H208a16 16 0 01-16-16zm400-112h-80v32h80a16 16 0 0116 16v160a16 16 0 01-16 16H432a16 16 0 01-16-16v-16h-32v16a48 48 0 0048 48h160a48 48 0 0048-48V304a48 48 0 00-48-48zM464 448h96a16 16 0 0016-16v-96a16 16 0 00-16-16h-48v32h32v64h-48.41a79.76 79.76 0 01-41.25 28.43A15.66 15.66 0 00464 448zM176 64H80a16 16 0 00-16 16v96a16 16 0 0016 16h48v-32H96V96h48.41a79.76 79.76 0 0141.25-28.43A15.66 15.66 0 00176 64z"
                        ></path>
                    </svg>
                    <span>Bring To Front</span>
                </ActionButton>
                <ActionButton onClick={() => updateLayerOrder({direction: 'back'})}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"

                    >
                        <path
                            fill="currentColor"
                            d="M175 64H79a16 16 0 00-16 16v96a16 16 0 0016 16h96a16 16 0 0016-16V80a16 16 0 00-16-16zm-16 96H95V96h64zm288-16v80h32v-80a48 48 0 00-48-48H287v32h144a16 16 0 0116 16zm112 176h-96a16 16 0 00-16 16v96a16 16 0 0016 16h96a16 16 0 0016-16v-96a16 16 0 00-16-16zm-16 96h-64v-64h64zm48-160H431a48 48 0 00-48 48v160a48 48 0 0048 48h160a48 48 0 0048-48V304a48 48 0 00-48-48zm16 208a16 16 0 01-16 16H431a16 16 0 01-16-16V304a16 16 0 0116-16h160a16 16 0 0116 16zm-416-96v-80h-32v80a48 48 0 0048 48h144v-32H207a16 16 0 01-16-16zm64-160V48a48 48 0 00-48-48H47A48 48 0 00-1 48v160a48 48 0 0048 48h160a48 48 0 0048-48zm-224 0V48a16 16 0 0116-16h160a16 16 0 0116 16v160a16 16 0 01-16 16H47a16 16 0 01-16-16z"
                        ></path>
                    </svg>

                    <span>Send To Back</span>
                </ActionButton>
                <br/>

                <ActionButton onClick={() => updateLayerOrder({direction: 1})}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M352 304V48a48 48 0 00-48-48H48A48 48 0 000 48v256a48 48 0 0048 48h256a48 48 0 0048-48zm-320 0V48a16 16 0 0116-16h256a16 16 0 0116 16v256a16 16 0 01-16 16H48a16 16 0 01-16-16zm432-144h-80v32h80a16 16 0 0116 16v256a16 16 0 01-16 16H208a16 16 0 01-16-16v-80h-32v80a48 48 0 0048 48h256a48 48 0 0048-48V208a48 48 0 00-48-48zm-48 256H256v-32h-32v48a16 16 0 0016 16h192a16 16 0 0016-16V240a16 16 0 00-16-16h-48v32h32z"
                        ></path>
                    </svg>
                    <span>
                    Bring Forward
                    </span>
                </ActionButton>
                <ActionButton onClick={() => updateLayerOrder({direction: -1})}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"

                    >
                        <path
                            fill="currentColor"
                            d="M48 48h256v80h48V48a48 48 0 00-48-48H48A48 48 0 000 48v256a48 48 0 0048 48h80v-48H48zm208 384h160a16 16 0 0016-16V256a16 16 0 00-16-16H256a16 16 0 00-16 16v160a16 16 0 0016 16zm32-144h96v96h-96zm176-128H208a48 48 0 00-48 48v256a48 48 0 0048 48h256a48 48 0 0048-48V208a48 48 0 00-48-48zm0 304H208V208h256z"
                        ></path>
                    </svg>
                    <span>Send Backward</span>
                </ActionButton>
            </List>
        </Root>
    )
}

export default LayersOptions