import {FC, useCallback} from "react";
import ModuleOptionsWrapper from "../ModuleOptionsWrapper";
import Uploader from "./Components/Uploader";
import {IModuleOptions} from "../../lib/Models/Module";
import {FilePondFile} from "filepond";
import UnsplashSearch from "./Components/UnsplashSearch";
import {Basic} from "unsplash-js/dist/methods/photos/types";
import {urlToBlob} from "../../helpers";
import {uploadAssetToMag} from "../../lib/Models/Asset";
import {useParams} from "@reach/router";
import LayersOptions from "../Components/LayersOptions";

const ImageModuleOptions: FC<IModuleOptions> = ({onDone, id, meta, onUpdate, onRemove}) => {

    const {magID} = useParams()


    const updateMeta = useCallback(() => {
        onUpdate({
            id: id,
            action: 'updateMeta',
            data: null
        })
    }, [id, onUpdate])

    const updateFiles = (data: FilePondFile | null) => {


        if (data === null) {
            return
        }


        // @ts-ignore
        const url = data?.getFileEncodeDataURL()

        if (url) {
            urlToBlob(url).then(blob => {
                uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {
                    onUpdate({
                        id: id,
                        action: 'updateMeta',
                        data: {
                            design: {
                                asset
                            }
                        }
                    })
                })
            })
        }
    }

    const updateWithUnsplash = (data: Basic) => {

        urlToBlob(data?.urls.regular).then(blob => {
            uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {
                onUpdate({
                    id: id,
                    action: 'updateMeta',
                    data: {
                        // @ts-ignore
                        design: {
                            asset
                        }
                    }
                })
            })
        })
    }


    return (
        <ModuleOptionsWrapper name={"Photo"} {...{onDone, onRemove}}>
            <Uploader url={meta?.design?.asset.URL} onUpdateFiles={updateFiles}/>
            <hr className={"my-8"}/>
            <UnsplashSearch onSelection={updateWithUnsplash}/>
            <LayersOptions id={id} onUpdate={updateMeta}/>
        </ModuleOptionsWrapper>
    )
}


export default ImageModuleOptions