import {endsWith, findKey, uniq} from "lodash"

export type StorageFont = "Abel-Regular" |
    "AbhayaLibre-Bold" |
    "AbhayaLibre-Regular" |
    "AbhayaLibre-ExtraBold" |
    "AbhayaLibre-Medium" |
    "AbhayaLibre-SemiBold" |
    "AbrilFatface-Regular" |
    "AlfaSlabOne-Regular" |
    "AmaticSC-Bold" |
    "AmaticSC-Regular" |
    "AnonymousPro-Bold" |
    "AnonymousPro-BoldItalic" |
    "AnonymousPro-Italic" |
    "AnonymousPro-Regular" |
    "Arapey-Italic" |
    "Arapey-Regular" |
    "Archivo-Bold" |
    "Archivo-BoldItalic" |
    "Archivo-Italic" |
    "Archivo-Medium" |
    "Archivo-MediumItalic" |
    "Archivo-Regular" |
    "Archivo-SemiBold" |
    "Archivo-SemiBoldItalic" |
    "ArchivoBlack-Regular" |
    "AsemKandis" |
    "Assistant-Bold" |
    "Assistant-ExtraBold" |
    "Assistant-ExtraLight" |
    "Assistant-Light" |
    "Assistant-Regular" |
    "Assistant-SemiBold" |
    "Athiti-Bold" |
    "Athiti-ExtraLight" |
    "Athiti-Light" |
    "Athiti-Medium" |
    "Athiti-Regular" |
    "Athiti-SemiBold" |
    "AtomicAge-Regular" |
    "BarlowCondensed-Black" |
    "BarlowCondensed-BlackItalic" |
    "BarlowCondensed-Bold" |
    "BarlowCondensed-BoldItalic" |
    "BarlowCondensed-ExtraBold" |
    "BarlowCondensed-ExtraBoldItalic" |
    "BarlowCondensed-ExtraLight" |
    "BarlowCondensed-ExtraLightItalic" |
    "BarlowCondensed-Italic" |
    "BarlowCondensed-Light" |
    "BarlowCondensed-LightItalic" |
    "BarlowCondensed-Medium" |
    "BarlowCondensed-MediumItalic" |
    "BarlowCondensed-Regular" |
    "BarlowCondensed-SemiBold" |
    "BarlowCondensed-SemiBoldItalic" |
    "BarlowCondensed-Thin" |
    "BarlowCondensed-ThinItalic" |
    "Barrio-Regular" |
    "BebasNeue-Regular" |
    "BioRhyme-Bold" |
    "BioRhyme-ExtraBold" |
    "BioRhyme-ExtraLight" |
    "BioRhyme-Light" |
    "BioRhyme-Regular" |
    "Buenard-Bold" |
    "Buenard-Regular" |
    "Cardo-Bold" |
    "Cardo-Italic" |
    "Cardo-Regular" |
    "Caveat-Regular" |
    "Chonburi-Regular" |
    "Comfortaa-Bold" |
    "Comfortaa-Regular" |
    "ContrailOne-Regular" |
    "Coustard-Black" |
    "Coustard-Regular" |
    "CroissantOne-Regular" |
    "DarkerGrotesque-Black" |
    "DarkerGrotesque-SemiBold" |
    "Domine-Bold" |
    "Domine-Regular" |
    "EBGaramond-Regular" |
    "EBGaramond-Regular_Bold" |
    "EBGaramond-Regular_ExtraBold" |
    "EBGaramond-Regular_Medium" |
    "EBGaramond-Regular_SemiBold" |
    "Eczar-Bold" |
    "Eczar-Regular" |
    "FjallaOne-Regular" |
    "FrankRuhlLibre-Black" |
    "FrankRuhlLibre-Bold" |
    "FrankRuhlLibre-Light" |
    "FrankRuhlLibre-Medium" |
    "FrankRuhlLibre-Regular" |
    "FugazOne-Regular" |
    "Glegoo-Bold" |
    "Glegoo-Regular" |
    "GothicA1-Black" |
    "GothicA1-Bold" |
    "GothicA1-ExtraBold" |
    "GothicA1-ExtraLight" |
    "GothicA1-Light" |
    "GothicA1-Medium" |
    "GothicA1-Regular" |
    "GothicA1-SemiBold" |
    "GothicA1-Thin" |
    "GoudyBookletter1911" |
    "GravitasOne" |
    "IBMPlexSans" |
    "IBMPlexSans-Bold" |
    "IBMPlexSans-BoldItalic" |
    "IBMPlexSans-Italic" |
    "IBMPlexSerif" |
    "IBMPlexSerif-Bold" |
    "IBMPlexSerif-BoldItalic" |
    "IBMPlexSerif-Italic" |
    "IM_FELL_English_Italic" |
    "IM_FELL_English_Roman" |
    "Inter-Black" |
    "Inter-BlackItalic" |
    "Inter-Bold" |
    "Inter-BoldItalic" |
    "Inter-ExtraBold" |
    "Inter-ExtraBoldItalic" |
    "Inter-Italic" |
    "Inter-LightBETA" |
    "Inter-LightItalicBETA" |
    "Inter-Medium" |
    "Inter-MediumItalic" |
    "Inter-Regular" |
    "Inter-SemiBold" |
    "Jost-Regular" |
    "JostRoman-Black" |
    "JostRoman-Bold" |
    "JostRoman-ExtraBold" |
    "JostRoman-ExtraLight" |
    "JostRoman-Light" |
    "JostRoman-Medium" |
    "JostRoman-SemiBold" |
    "JostRoman-Thin" |
    "Kanit-Black" |
    "KronaOne-Regular" |
    "LibreBaskerville-Bold" |
    "LibreBaskerville-Italic" |
    "LibreBaskerville-Regular" |
    "Montserrat-Black" |
    "Nobile-Bold" |
    "Nobile-Italic" |
    "Nobile-Regular" |
    "OpenSans-Bold" |
    "OpenSans-BoldItalic" |
    "OpenSans-ExtraBold" |
    "OpenSans-ExtraBoldItalic" |
    "OpenSans-Italic" |
    "OpenSans-Light" |
    "OpenSans-LightItalic" |
    "OpenSans-Regular" |
    "OpenSans-SemiBold" |
    "OpenSans-SemiBoldItalic" |
    "Oswald-Bold" |
    "Oswald-Regular" |
    "PaytoneOne-Regular" |
    "PermanentMarker-Regular" |
    "PlayfairDisplay-Italic" |
    "PlayfairDisplay-Italic_Black-Italic" |
    "PlayfairDisplay-Italic_Bold-Italic" |
    "PlayfairDisplay-Regular" |
    "PlayfairDisplay-Regular_Black" |
    "PlayfairDisplay-Regular_Bold" |
    "Poppins-Bold" |
    "Poppins-Light" |
    "Poppins-Medium" |
    "Poppins-Regular" |
    "Raleway-Bold" |
    "Raleway-BoldItalic" |
    "Raleway-Italic" |
    "Raleway-Regular" |
    "Raleway-SemiBold" |
    "Raleway-SemiBoldItalic" |
    "Rubik-Bold" |
    "Rubik-Italic" |
    "Rubik-Regular" |
    "SixCaps" |
    "TradeWinds" |
    "VastShadow-Regular" |
    "Vidaloka-Regular" |
    "ZillaSlabHighlight-Regular"

const AllFonts = {
    "Abel-Regular": "Abel",
    "AbhayaLibre-Regular": "Abhaya Libre",
    "AbhayaLibre-Bold": "Abhaya Libre",
    "AbhayaLibre-ExtraBold": "Abhaya Libre",
    "AbhayaLibre-Medium": "Abhaya Libre",
    "AbhayaLibre-SemiBold": "Abhaya Libre",
    "AbrilFatface-Regular": "Abril Fatface",
    "AlfaSlabOne-Regular": "Alfa Slab One",
    "AmaticSC-Bold": "Amatic SC",
    "AmaticSC-Regular": "Amatic SC",
    "AnonymousPro-Bold": "Anonymous Pro",
    "AnonymousPro-BoldItalic": "Anonymous Pro",
    "AnonymousPro-Italic": "Anonymous Pro",
    "AnonymousPro-Regular": "Anonymous Pro",
    "Arapey-Italic": "Arapey",
    "Arapey-Regular": "Arapey",
    "Archivo-Bold": "Archivo",
    "Archivo-BoldItalic": "Archivo",
    "Archivo-Italic": "Archivo",
    "Archivo-Medium": "Archivo",
    "Archivo-MediumItalic": "Archivo",
    "Archivo-Regular": "Archivo",
    "Archivo-SemiBold": "Archivo",
    "Archivo-SemiBoldItalic": "Archivo",
    "ArchivoBlack-Regular": "Archivo",
    "AsemKandis": "Asem Kandis",
    "Assistant-Bold": "Assistant",
    "Assistant-ExtraBold": "Assistant",
    "Assistant-ExtraLight": "Assistant",
    "Assistant-Light": "Assistant",
    "Assistant-Regular": "Assistant",
    "Assistant-SemiBold": "Assistant",
    "Athiti-Bold": "Athiti",
    "Athiti-ExtraLight": "Athiti",
    "Athiti-Light": "Athiti",
    "Athiti-Medium": "Athiti",
    "Athiti-Regular": "Athiti",
    "Athiti-SemiBold": "Athiti",
    "AtomicAge-Regular": "Atomic Age",
    "BarlowCondensed-Black": "Barlow Condensed",
    "BarlowCondensed-BlackItalic": "Barlow Condensed",
    "BarlowCondensed-Bold": "Barlow Condensed",
    "BarlowCondensed-BoldItalic": "Barlow Condensed",
    "BarlowCondensed-ExtraBold": "Barlow Condensed",
    "BarlowCondensed-ExtraBoldItalic": "Barlow Condensed",
    "BarlowCondensed-ExtraLight": "Barlow Condensed",
    "BarlowCondensed-ExtraLightItalic": "Barlow Condensed",
    "BarlowCondensed-Italic": "Barlow Condensed",
    "BarlowCondensed-Light": "Barlow Condensed",
    "BarlowCondensed-LightItalic": "Barlow Condensed",
    "BarlowCondensed-Medium": "Barlow Condensed",
    "BarlowCondensed-MediumItalic": "Barlow Condensed",
    "BarlowCondensed-Regular": "Barlow Condensed",
    "BarlowCondensed-SemiBold": "Barlow Condensed",
    "BarlowCondensed-SemiBoldItalic": "Barlow Condensed",
    "BarlowCondensed-Thin": "Barlow Condensed",
    "BarlowCondensed-ThinItalic": "Barlow Condensed",
    "Barrio-Regular": "Barrio",
    "BebasNeue-Regular": "Bebas Neue",
    "BioRhyme-Bold": "BioRhyme",
    "BioRhyme-ExtraBold": "BioRhyme",
    "BioRhyme-ExtraLight": "BioRhyme",
    "BioRhyme-Light": "BioRhyme",
    "BioRhyme-Regular": "BioRhyme",
    "Buenard-Bold": "Buenard",
    "Buenard-Regular": "Buenard",
    "Cardo-Bold": "Cardo",
    "Cardo-Italic": "Cardo",
    "Cardo-Regular": "Cardo",
    "Caveat-Regular": "Caveat",
    "Chonburi-Regular": "Chonburi",
    "Comfortaa-Bold": "Comfortaa",
    "Comfortaa-Regular": "Comfortaa",
    "ContrailOne-Regular": "Contrail One",
    "Coustard-Black": "Coustard",
    "Coustard-Regular": "Coustard",
    "CroissantOne-Regular": "Croissant One",
    "DarkerGrotesque-Black": "Darker Grotesque",
    "DarkerGrotesque-SemiBold": "Darker Grotesque",
    "Domine-Bold": "Domine",
    "Domine-Regular": "Domine",
    "EBGaramond-Regular": "EB Garamond",
    "EBGaramond-Regular_Bold": "EB Garamond",
    "EBGaramond-Regular_ExtraBold": "EB Garamond",
    "EBGaramond-Regular_Medium": "EB Garamond",
    "EBGaramond-Regular_SemiBold": "EB Garamond",
    "Eczar-Bold": "Eczar",
    "Eczar-Regular": "Eczar",
    "FjallaOne-Regular": "Fjalla One",
    "FrankRuhlLibre-Black": "Frank Ruhl Libre",
    "FrankRuhlLibre-Bold": "Frank Ruhl Libre",
    "FrankRuhlLibre-Light": "Frank Ruhl Libre",
    "FrankRuhlLibre-Medium": "Frank Ruhl Libre",
    "FrankRuhlLibre-Regular": "Frank Ruhl Libre",
    "FugazOne-Regular": "Fugaz One",
    "Glegoo-Bold": "Glegoo",
    "Glegoo-Regular": "Glegoo",
    "GothicA1-Black": "Gothic A1",
    "GothicA1-Bold": "Gothic A1",
    "GothicA1-ExtraBold": "Gothic A1",
    "GothicA1-ExtraLight": "Gothic A1",
    "GothicA1-Light": "Gothic A1",
    "GothicA1-Medium": "Gothic A1",
    "GothicA1-Regular": "Gothic A1",
    "GothicA1-SemiBold": "Gothic A1",
    "GothicA1-Thin": "Gothic A1",
    "GoudyBookletter1911": "Goudy Bookletter 1911",
    "GravitasOne": "Gravitas One",
    "IBMPlexSans": "IBM Plex Sans",
    "IBMPlexSans-Bold": "IBM Plex Sans",
    "IBMPlexSans-BoldItalic": "IBM Plex Sans",
    "IBMPlexSans-Italic": "IBM Plex Sans",
    "IBMPlexSerif": "IBM Plex Serif",
    "IBMPlexSerif-Bold": "IBM Plex Serif",
    "IBMPlexSerif-BoldItalic": "IBM Plex Serif",
    "IBMPlexSerif-Italic": "IBM Plex Serif",
    "IM_FELL_English_Italic": "IM FELL English",
    "IM_FELL_English_Roman": "IM FELL English",
    "Inter-Black": "Inter",
    "Inter-BlackItalic": "Inter",
    "Inter-Bold": "Inter",
    "Inter-BoldItalic": "Inter",
    "Inter-ExtraBold": "Inter",
    "Inter-ExtraBoldItalic": "Inter",
    "Inter-Italic": "Inter",
    "Inter-LightBETA": "Inter",
    "Inter-LightItalicBETA": "Inter",
    "Inter-Medium": "Inter",
    "Inter-MediumItalic": "Inter",
    "Inter-Regular": "Inter",
    "Inter-SemiBold": "Inter",
    "Jost-Regular": "Jost",
    "JostRoman-Black": "Jost",
    "JostRoman-Bold": "Jost",
    "JostRoman-ExtraBold": "Jost",
    "JostRoman-ExtraLight": "Jost",
    "JostRoman-Light": "Jost",
    "JostRoman-Medium": "Jost",
    "JostRoman-SemiBold": "Jost",
    "JostRoman-Thin": "Jost",
    "Kanit-Black": "Kanit",
    "KronaOne-Regular": "Krona One",
    "LibreBaskerville-Bold": "Libre Baskerville",
    "LibreBaskerville-Italic": "Libre Baskerville",
    "LibreBaskerville-Regular": "Libre Baskerville",
    "Montserrat-Black": "Montserrat",
    "Nobile-Bold": "Nobile",
    "Nobile-Italic": "Nobile",
    "Nobile-Regular": "Nobile",
    "OpenSans-Bold": "Open Sans",
    "OpenSans-BoldItalic": "Open Sans",
    "OpenSans-ExtraBold": "Open Sans",
    "OpenSans-ExtraBoldItalic": "Open Sans",
    "OpenSans-Italic": "Open Sans",
    "OpenSans-Light": "Open Sans",
    "OpenSans-LightItalic": "Open Sans",
    "OpenSans-Regular": "Open Sans",
    "OpenSans-SemiBold": "Open Sans",
    "OpenSans-SemiBoldItalic": "Open Sans",
    "Oswald-Bold": "Oswald",
    "Oswald-Regular": "Oswald",
    "PaytoneOne-Regular": "Paytone One",
    "PermanentMarker-Regular": "Permanent Marker",
    "PlayfairDisplay-Italic": "Playfair Display",
    "PlayfairDisplay-Italic_Black-Italic": "Playfair Display",
    "PlayfairDisplay-Italic_Bold-Italic": "Playfair Display",
    "PlayfairDisplay-Regular": "Playfair Display",
    "PlayfairDisplay-Regular_Black": "Playfair Display",
    "PlayfairDisplay-Regular_Bold": "Playfair Display",
    "Poppins-Bold": "Poppins",
    "Poppins-Light": "Poppins",
    "Poppins-Medium": "Poppins",
    "Poppins-Regular": "Poppins",
    "Raleway-Bold": "Raleway",
    "Raleway-BoldItalic": "Raleway",
    "Raleway-Italic": "Raleway",
    "Raleway-Regular": "Raleway",
    "Raleway-SemiBold": "Raleway",
    "Raleway-SemiBoldItalic": "Raleway",
    "Rubik-Bold": "Rubik",
    "Rubik-Italic": "Rubik",
    "Rubik-Regular": "Rubik",
    "SixCaps": "Six Caps",
    "TradeWinds": "Trade Winds",
    "VastShadow-Regular": "Vast Shadow",
    "Vidaloka-Regular": "Vidaloka",
    "ZillaSlabHighlight-Regular": "Zilla Slab Highlight",
}

export const getFonts = () => {
    return uniq(Object.values(AllFonts))
}


export const findRegularStorageFont = (fontName: string) => {
    return findKey(AllFonts, (font, storageFont) => {
        if (font === fontName) {
            if (endsWith(storageFont, '-Regular')) {
                return true
            }
            if (storageFont === fontName) {
                return true
            }
            if (storageFont === fontName.replace(/[ \s]/gm, '')) {
                return true
            }
        }
        return false
    })
}

export default AllFonts