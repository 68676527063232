import MagmaDesignEntity from "../../LayeredBlocks/MagmaDesignEntity"
import MagmaLabelEntity from "../../LayeredBlocks/MagmaLabelEntity"

const FashionCategory = {
    fashion_1: {
        background: new MagmaDesignEntity({
            isLogo: false,
            scale: 1,
            asset: {
                FileType: "png",
                ID: "fashion-1/fashion1-bkg",
                URL: "https://assets.magma.sh/templates/fashion-1/fashion1-bkg.png",
                Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
            },
            editable: true,
            id: "fashion-1/fashion1-bkg"
        }),
        classic: new MagmaLabelEntity({
            fontColor: {
                green: 0.8266312479972839,
                blue: 0.735493540763855,
                red: 0.7243943810462952,
                alpha: 0.65
            },
            characterSpacing: 0.8,
            fontSize: 118,
            fontName: "EBGaramond-Regular",
            textAlignment: 0,
            numberOfLines: 1,
            text: "Classic",
            lineHeight: 0
        }),
        old_new: new MagmaLabelEntity({
            characterSpacing: 1,
            fontName: "EBGaramond-Regular",
            lineHeight: 0.65,
            fontSize: 70,
            text: "Old\nClothes.\nNew\nFashion.",
            textAlignment: 0,
            numberOfLines: 4,
            fontColor: {
                red: 1.0000001192092896,
                alpha: 1,
                blue: 1.0000001192092896,
                green: 1.0000001192092896
            }
        }),
        issue_01: new MagmaLabelEntity({
            textAlignment: 1,
            numberOfLines: 1,
            fontColor: {
                red: 1.0000001192092896,
                blue: 1.0000001192092896,
                green: 1.0000001192092896,
                alpha: 1
            },
            fontSize: 25,
            fontName: "EBGaramond-Regular",
            characterSpacing: 1,
            lineHeight: 0,
            text: "Issue 01"
        })
    }
}

export default FashionCategory