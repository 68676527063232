import { ICoverTemplate } from ".."
import { getCanvasSizeInPoints, makeTemplateThumbnailURL } from "../../../helpers"
import TravelCategoryLayers from "../DesignLayers/Categories/TravelCategory"
import MagmaLayeredBlockEntity from "../LayeredBlocks/MagmaLayeredBlockEntity"

const { travel_1, travel_2 } = TravelCategoryLayers
const canvasSize = getCanvasSizeInPoints()

const TravelCategory: ICoverTemplate[] = [
    {
        title: 'travel1',
        thumbnail: makeTemplateThumbnailURL('travel1', 'jpg'),
        category: 'Travel',
        meta: [
            new MagmaLayeredBlockEntity({
                frame: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        377,
                        603.2
                    ]
                },
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        377,
                        603.2
                    ]
                },
                center: [
                    187,
                    299
                ],
                tag: 0,
            }),
            new MagmaLayeredBlockEntity({
                center: [
                    187,
                    299.335
                ],
                bounds: {
                    Size: [
                        375,
                        603.2
                    ],
                    Origin: [
                        -0.5,
                        -0.2904681647939924
                    ]
                },
                tag: 1,
                design: travel_1.background,
                frame: {
                    Size: [
                        canvasSize.width + 1,
                        canvasSize.height
                    ],
                    Origin: [
                        -0.5,
                        -0.2904681647939924
                    ]
                }
            }),
            new MagmaLayeredBlockEntity({
                bounds: {
                    Size: [
                        187,
                        186.18695652173912
                    ],
                    Origin: [
                        93.5,
                        -60.760144927536224
                    ]
                },
                design: travel_1.badge,
                tag: 2,
                frame: {
                    // Top badge
                    Origin: [
                        canvasSize.width / 4,
                        -60
                    ],
                    Size: [
                        187,
                        186.18695652173912
                    ],
                },
                center: [
                    187,
                    32.333333333333336
                ]
            }),
            new MagmaLayeredBlockEntity({
                tag: 3,
                frame: {
                    // Bottom badge
                    Origin: [
                        canvasSize.width / 4,
                        canvasSize.height - 112
                    ],
                    Size: [
                        187,
                        186.18695652173912
                    ]
                },
                center: [
                    187,
                    578.67
                ],
                bounds: {
                    Origin: [
                        93.5,
                        485.5765217391304
                    ],
                    Size: [
                        187,
                        186.18695652173912
                    ]
                },
                design: travel_1.badge
            }),
            new MagmaLayeredBlockEntity({
                frame: {
                    Origin: [
                        canvasSize.width / 15,
                        (canvasSize.height / 2) + 70
                    ],
                    Size: [
                        canvasSize.width - (canvasSize.width / 8),
                        canvasSize.height / 5
                        // 327.25,
                        // 119.734
                    ]
                },
                center: [
                    188.55833333333334,
                    419.202
                ],
                tag: 5,
                label: travel_1.label_1,
                isBackground: false,
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        327.25,
                        119.734
                    ]
                }
            }),
            new MagmaLayeredBlockEntity({
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        314.5,
                        168.4375
                    ]
                },
                frame: {
                    Origin: [
                        canvasSize.width / 20,
                        (canvasSize.height / 2) - 130
                    ],
                    Size: [
                        canvasSize.width - (canvasSize.width / 15),
                        160
                    ]
                },
                isBackground: false,
                label: travel_1.label_2,
                center: [
                    187,
                    289.335
                ],
                tag: 6
            })
        ]
    },
    {
        title: 'travel2',
        thumbnail: makeTemplateThumbnailURL('travel2'),
        category: 'Travel',
        meta: [
            new MagmaLayeredBlockEntity({
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        377,
                        603.2
                    ]
                },
                frame: {
                    Size: [
                        377,
                        603.2
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                isBackground: false,

                center: [
                    187,
                    299
                ],
                tag: 0
            }),
            new MagmaLayeredBlockEntity({
                frame: {
                    Size: [
                        375,
                        603.7191011235955
                    ],
                    Origin: [
                        -0.5,
                        -0.5245505617977528
                    ]
                },
                tag: 1,
                center: [
                    187,
                    299.335
                ],
                bounds: {
                    Size: [
                        375,
                        603.7191011235955
                    ],
                    Origin: [
                        -0.5,
                        -0.5245505617977528
                    ]
                },
                design: travel_2.background,
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                design: travel_2.bottom_graphic,
                center: [
                    187,
                    549.687909090909
                ],
                tag: 2,
                bounds: {
                    Size: [
                        124.66666666666669,
                        214.52075471698117
                    ],
                    Origin: [
                        124.66666666666666,
                        462.4275317324184
                    ]
                },
                frame: {
                    Size: [
                        124.66666666666669,
                        214.52075471698117
                    ],
                    Origin: [
                        124.66666666666666,
                        442.4275317324184
                    ]
                },
                isBackground: false,

            }),
            new MagmaLayeredBlockEntity({
                label: travel_2.small,
                isBackground: false,
                frame: {
                    Size: [
                        275,
                        202.93898305084744
                    ],
                    Origin: [
                        62.33333333333333,
                        155.4888461538461
                    ]
                },
                bounds: {
                    Size: [
                        249.33333333333334,
                        202.93898305084744
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                center: [
                    187,
                    256.95833767926985
                ],
                tag: 3
            }),
            new MagmaLayeredBlockEntity({
                center: [
                    187,
                    378.5640198321891
                ],
                label: travel_2.travels,
                bounds: {
                    Size: [
                        275,
                        63.0178947368421
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                isBackground: false,
                frame: {
                    Size: [
                        275,
                        63.0178947368421
                    ],
                    Origin: [
                        62.33333333333333,
                        347.05507246376806
                    ]
                },
                tag: 4
            }),
            new MagmaLayeredBlockEntity({
                label: travel_2.portland,
                tag: 5,
                center: [
                    187,
                    93.3640119047619
                ],
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        187,
                        49.88916666666666
                    ]
                },
                frame: {
                    Size: [
                        187,
                        49.88916666666666
                    ],
                    Origin: [
                        93.5,
                        68.41942857142857
                    ]
                },
                isBackground: false,
            })
        ]
    }
]

export default TravelCategory