import { RGBObject } from "../../../Modules/Text/TextModule"

export interface MagmaLabelEntityProps {
    text:string
    fontName?: string
    fontColor?: RGBObject
    fontSize?: number
    textAlignment?: number
    numberOfLines?: number
    lineHeight?: number
    characterSpacing?: number
}

export default class MagmaLabelEntity {
    text:string
    fontName: string
    fontColor: RGBObject
    fontSize: number
    textAlignment: number
    numberOfLines: number
    lineHeight: number
    characterSpacing: number

    constructor({
        text,
        fontName = "IBMPlex",
        fontColor = {
            red: 0,
            green: 0,
            blue: 0,
            alpha: 1
        },
        fontSize = 125,
        textAlignment = 1,
        numberOfLines = 1,
        lineHeight = 0,
        characterSpacing = 2
    }: MagmaLabelEntityProps) {
        this.text = text
        this.fontName = fontName
        this.fontColor = fontColor
        this.fontSize = fontSize
        this.textAlignment = textAlignment
        this.numberOfLines = numberOfLines
        this.lineHeight = lineHeight
        this.characterSpacing = characterSpacing
    }
}