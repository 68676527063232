import React, {FC, useContext, useRef, useState} from "react";
import styled from "styled-components";
import {useBus, useListener} from "react-bus";
import Block from "./MagReader/Block";
import MagmaBlockEntity from "../lib/MagmaBlockEntity";
import {Plus} from "../icons";
import ClickAwayListener from "react-click-away-listener";
import {PagesContext} from "../Providers/Page/PageProvider";
import cuid from "cuid";
import MagmaBlockLayout from "../lib/MagmaBlockLayout";
import MagmaBlockInsets from "../lib/MagmaBlockInsets";
import MagmaBlockContent from "../lib/Content/MagmaBlockContent";
import MagmaTextEntity from "../lib/Content/MagmaTextEntity";
import MagmaBlockLayoutMode from "../lib/MagmaBlockLayoutMode";
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';

const INITIAL_CANVAS_SIZE = 226.2


const SIZE_WIDTH_MULTIPLIER = 5
const SIZE_HEIGHT_MULTIPLIER = 8


declare interface IControlledTemplateCanvas {
    components: MagmaBlockEntity[]
    setActiveComponent: (data: { id: string | null, meta?: any }) => void
    removeModule: (id: string | null) => void
    centerX: boolean
    sortEnabled: boolean
    snapshot?: boolean
    scale?: number
}

const Canvas = styled.div<{ size: number, scale: number | undefined, snapshot?: boolean }>`
  width: ${props => props.size * SIZE_WIDTH_MULTIPLIER}px;
  min-height: ${props => props.size * SIZE_HEIGHT_MULTIPLIER}px;
  margin: auto;
  overflow: hidden;
  position: relative;
  background: ${props => props.theme.colors.background};
  transform: scale(${props => props.scale ? props.scale : 1.0});
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
  transform-origin: ${props => props.snapshot ? "top left" : "top center"};
  margin-top: ${props => props.snapshot ? 0 : '100px'};
  max-width: 728px;
  color: ${props => props.theme.colors.text};
  text-align: left;
  ${props => props.snapshot && `
    * {
    pointer-events:none;
    }
  `}
`

const BlocksWrapper = styled.div<{ sortEnabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${props => props.sortEnabled && `
    & > * {
    max-height:300px;
    overflow:hidden;
    cursor:move;
    & *{
    pointer-events: none;
    }
  `}
`

const Dropdown = styled.div<{ active: boolean }>`
  position: absolute;
  background: #333;
  color: #fff;
  left: 0;
  bottom: 100%;

  text-align: left;
  right: 0;
  opacity: 0;
  transition: opacity .3s ease, max-height .3s ease;
  max-height: 0;
  overflow: hidden;

  ul {
    width: 100%;
  }

  li {
    padding: 15px 20px;
    width: 100%;
    font-size: 1.3rem;
    letter-spacing: 0.05em;
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.secondary.bg}

    }

  }

  ${props => props.active && `
    
      opacity: 1;
      max-height: 200px;
    
  `}
`

const AddBlockWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 99;
  margin-top: 30px;
  margin-bottom: 40px;
`

const AddBlockButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border: 1px solid #fff;
  outline: none;
  padding: 8px 25px;
  border-radius: 8px;
  width: fit-content;
  margin: auto;

  &:hover {
    background: ${props => props.theme.colors.secondary.bg};
  }

  svg {
    margin-right: 6px;
    font-size: 1.6rem;
  }
`

const SortableItem = SortableElement((props: any) => <Block {...props}/>);

const SortableList = SortableContainer(({children, sortEnabled}: any) => {

    return (
        <BlocksWrapper sortEnabled={sortEnabled}>
            {children}
        </BlocksWrapper>
    );
});

const ControlledTemplateCanvas: FC<IControlledTemplateCanvas> = ({
                                                                     sortEnabled,
                                                                     children,
                                                                     components,
                                                                     snapshot,
                                                                     scale: _scale
                                                                 }) => {

    const [canvasSize] = useState<number>(INITIAL_CANVAS_SIZE)
    const [scale, setScale] = useState<number>(_scale ?? (snapshot ? 0.13 : 1))
    const canvasRef = useRef<HTMLDivElement>(null)
    const [blockDropdownActive, setBlockDropdownActive] = useState<boolean>(false)

    const bus = useBus()

    // @ts-ignore
    const dispatchPagesState = useContext(PagesContext)[1]


    useListener(
        'scaleChange', val => {
            if (!snapshot) {
                setScale(val)
            }
        }
    )


    const addTextBlock = () => {
        bus.emit('scaleChange', scale)

        dispatchPagesState({
            type: 'ADD_BLOCK_CONTENT',
            payload: new MagmaBlockEntity({
                ID: cuid(),
                Kind: "text",
                Layout: new MagmaBlockLayout({
                    Insets: new MagmaBlockInsets({Top: 15, Right: 20, Bottom: 20, Left: 20}),
                }),
                IsPlaceholder: true,
                Content: new MagmaBlockContent<MagmaTextEntity>({
                    type: "Text",
                    content: new MagmaTextEntity({
                        ID: cuid(),
                        Text: `
                                    <h1>Title goes here</h1>
                                    <h2>Subtitle goes here</h2>
                                    <p>Scourge of the seven seas walk the plank red ensign chandler blow the man down gangplank lad warp maroon pinnace. Yardarm hulk rigging long clothes boom run a rig pink handsomely hornswaggle spanker. Case shot mutiny spirits furl scuppers hang the jib ahoy spike galleon broadside.</p>
                                    <p>Parley Admiral of the Black log cog hulk pillage deadlights coffer Brethren of the Coast brig. Dead men tell no tales blow the man down boom pinnace yawl scuttle brig Nelsons folly starboard killick. Crow's nest measured fer yer chains ahoy loot lateen sail nipperkin scuppers Jolly Roger crack Jennys tea cup run a shot across the bow.</p>
                                `
                    })
                })
            })
        })
    }

    const addMediaBlock = () => {
        bus.emit('scaleChange', scale)

        dispatchPagesState({
            type: 'ADD_BLOCK_CONTENT',
            payload: new MagmaBlockEntity({
                ID: cuid(),
                Kind: "media",
                Layout: new MagmaBlockLayout({
                    Insets: new MagmaBlockInsets({Top: 10, Right: 10, Bottom: 5, Left: 10}),
                    AspectRatio: [1, 1],
                    Mode: new MagmaBlockLayoutMode({Width: 0})
                }),

            }),
        })
    }
    const onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }) => {

        dispatchPagesState({
            type: 'SET_BLOCKS',
            payload: arrayMove(components, oldIndex, newIndex)
        })
    };

    return (
        <Canvas size={canvasSize} id="magmaCanvas" ref={canvasRef} scale={scale} snapshot={snapshot}>

            {children}
            <SortableList sortEnabled={sortEnabled} helperClass={'sortableHelper'} distance={-300}

                          onSortEnd={onSortEnd}>
                {components.map((item, index) => (
                    <SortableItem disabled={!sortEnabled} key={'item-' + (item.ID || index)} index={index} {...item}
                                  snapshot={snapshot}/>
                ))}
            </SortableList>
            {!snapshot && <div className={
                "text-center"
            }>
                <AddBlockWrapper>
                    <AddBlockButton onMouseOver={() => !blockDropdownActive && setBlockDropdownActive(true)}>
                        <Plus/>Add Block
                    </AddBlockButton>
                    <ClickAwayListener onClickAway={() => setBlockDropdownActive(false)}>
                        <Dropdown active={blockDropdownActive}>
                            <ul>
                                <li onClick={() => {
                                    addTextBlock()
                                    setBlockDropdownActive(false)
                                }}>
                                    Text
                                </li>
                                <li onClick={() => {
                                    addMediaBlock()
                                    setBlockDropdownActive(false)
                                }}>
                                    Media
                                </li>
                            </ul>
                        </Dropdown>
                    </ClickAwayListener>

                </AddBlockWrapper>
            </div>}
        </Canvas>
    )
}

export default ControlledTemplateCanvas