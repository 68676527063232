import {FC} from "react";
import styled from "styled-components";
import isElectron from "is-electron";

const Container = styled.div`
  font-size: 2rem;
  font-weight: 600;
`

const Logo: FC = () => {
    return <Container><img width={isElectron() ? 100 : 150}
                           style={{transform: isElectron() ? "translatey(10px)" : undefined}} src={'/Assets/logo.png'}
                           alt={"Magma Logo"}/></Container>
}

export default Logo