import {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {IUser} from "../lib/Models/User";
import {startCase} from 'lodash'
import {buildCDNUrl} from "../helpers";

type TSuggestionBox = FC<{
    position: {
        top: number
        left: number
    }
    users: IUser[]
    onSelect: (user: IUser) => void

    multiple?: boolean
    selectedUsers?: IUser[]
}>


const Wrapper = styled.div<{ top?: number, left?: number }>`
  ${props => props.top && props.left && `
  
  position: fixed;
  top: ${props.top}px;
  left: ${props.left}px;
  `}

  display: block;
  background: ${props => props.theme.colors.textWrapperBg};
`


const UserListItem = styled.div<{ inFocus: boolean }>`
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${props => props.inFocus ? 'rgba(0,0,0,0.5)' : 'transparent'};
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 200px;
`


const SuggestionBox: TSuggestionBox = ({position, users, onSelect, multiple, selectedUsers}) => {

    const [inFocusIndex, setInFocusIndex] = useState<number>(0)

    useEffect(() => {
        setInFocusIndex(0)
    }, [users])

    function checkKey(e: any) {

        e = e || window.event;
        const {key} = e

        switch (key) {
            case 'ArrowUp':
                setInFocusIndex(inFocusIndex === 0 ? 0 : inFocusIndex - 1)
                break
            case 'ArrowDown':
                setInFocusIndex(inFocusIndex + 1)
                break
            case 'Enter':
            case 'Tab':
                onSelect(users[inFocusIndex])
                break
        }


    }

    useEffect(() => {
        document.addEventListener('keydown', checkKey)
        return () => {
            document.removeEventListener('keydown', checkKey)
        }
    })

    return users && (
        <Wrapper top={position?.top} left={position?.left}>
            {users.map((user, idx) => {
                return (
                    <UserListItem key={user.ID} inFocus={inFocusIndex === idx} onMouseOver={() => setInFocusIndex(idx)}
                                  onClick={() => onSelect(users[idx])}>
                        <img loading={"lazy"} src={buildCDNUrl(user.PhotoUrl) + '?tr=w-40,h-40,fo-auto'}
                             className={"rounded-full"}
                             alt={user.Username + ' Profile Picture'}/>
                        <span>{user.Fullname || startCase(user.Username)}</span>
                    </UserListItem>
                )
            })}
        </Wrapper>
    )
}

export default SuggestionBox