import {FC, FormEvent, MouseEventHandler, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {getAllGenres, IGenre} from "../lib/Models/Genre";
import {CheckBold, ChevronLeft} from "../icons";
import ClickAwayListener from "react-click-away-listener";
import {remove, some} from 'lodash'

declare interface IGenre_Props {
    onSelectionChange: (genre: IGenre[]) => void
    initialValue?: IGenre[]
}

const RootContainer = styled.div`
  position: relative;
  z-index: 99;
`


const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  background: ${props => props.theme.colors.overlayTextFieldBg};
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: block;
`

const SelectionContainer = styled.div`
  display: block;
  height: 40px;

  span {
    margin: 0 10px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 50px;
    background: ${props => `rgba(${props.theme.colors.secondary.bgColor}, 0.3)`};

    :hover {
      opacity: 0.6;
    }
  }
`

const DropArrow = styled.div<{ active: boolean }>`
  transform: ${props => props.active ? 'rotate(90deg) translateX(-50%)' : 'rotate(-90deg) translateX(50%)'};
  display: inline-block;
  transform-origin: center;
  position: absolute;
  right: 12px;
  top: calc(50% + 20px);
  z-index: 9;
  font-size: 28px;
  color: #8e8e8e;
  cursor: pointer;
  pointer-events: ${props => props.active ? 'auto' : 'none'};

  &:hover {
    color: ${props => props.active ? '#5a5a5a' : '#8e8e8e'};
  }
`

const OptionsContainer = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.overlayTextFieldBgDark};
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 0 0 1px rgb(64 87 109 / 7%), 0 2px 12px rgb(53 71 90 / 20%);
  max-height: 400px;
  overflow: auto;
`

const SlotContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 15px;
  cursor: pointer;
  position: relative;

  .label {
    margin-left: 14px;
  }

  &:hover {
    background: ${props => props.active ? `rgba(${props.theme.colors.secondary.bgColor}, 0.1)` : '#414141'};
    ${props => props.active ? '' : 'color: #fff;'}
  }

  background: ${props => props.active ? `rgba(${props.theme.colors.secondary.bgColor}, 0.1)` : 'transparent'};
`
const ActiveCheck = styled.div`
  position: absolute;
  right: 10px;
  font-size: 1.3em;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.secondary.bg}
`


const Slot: FC<{ genre: IGenre, active: boolean, onClick: MouseEventHandler<HTMLDivElement> }> = ({
                                                                                                      genre,
                                                                                                      active,
                                                                                                      onClick
                                                                                                  }) => {
    return (
        <SlotContainer active={active} onClick={(e) => {
            onClick(e)
            e.preventDefault()
        }}>
            <div>
                <img loading={"lazy"} src={`${genre.Thumbnail}?tr=w-40,h-40`} alt={genre.Name + " genre thumbnail"}/>
            </div>
            <div className={"label"}>
                {genre.Name}
            </div>
            {active && <ActiveCheck><CheckBold/></ActiveCheck>}
        </SlotContainer>
    )
}

const Options: FC<{ options: IGenre[], selected: IGenre[], onSelect: (genre: IGenre) => void }> = ({
                                                                                                       options,
                                                                                                       selected,
                                                                                                       onSelect
                                                                                                   }) => {

    const handleItemSelection = (genre: IGenre) => {
        onSelect(genre)
    }

    return (
        <OptionsContainer>
            {options.map((genre, idx) => {
                return (
                    <Slot active={some(selected, {ID: genre.ID})} onClick={() => handleItemSelection(genre)}
                          genre={genre}
                          key={idx}/>
                )
            })}
        </OptionsContainer>
    )
}

const GenreSelector: FC<IGenre_Props> = ({onSelectionChange, initialValue}) => {

    const [active, setActive] = useState<boolean>(false)
    const [genres, setGenres] = useState<IGenre[] | null>(null);
    const [userInput, setUserInput] = useState('')
    const [userSelection, setUserSelection] = useState<IGenre[]>(initialValue || [])
    const InputRef = useRef<HTMLInputElement>(null)


    const filteredGenres = useMemo<IGenre[]>(() => {

        if (!genres) {
            return []
        }

        return genres.filter(genre => genre.Name.toLowerCase().indexOf(userInput.toLowerCase()) > -1)

    }, [genres, userInput])

    useEffect(() => {

        getAllGenres().then(result => setGenres(result))

    }, [])

    const handleUserSelection = (genre: IGenre) => {
        let _userSelection = [...userSelection]

        if (userSelection.includes(genre)) {
            remove(_userSelection, {
                Name: genre.Name
            })
        } else {
            _userSelection = [..._userSelection, genre]
        }

        setUserSelection(_userSelection)

        InputRef?.current?.focus()

        if (onSelectionChange) {
            onSelectionChange(_userSelection)
        }
    }


    const handleUserInputChange = (e: FormEvent<HTMLInputElement>) => {
        setUserInput(e.currentTarget.value)
    }


    return (
        <RootContainer>
            <ClickAwayListener onClickAway={() => setActive(false)}>
                <InputWrapper>
                    <SelectionContainer>
                        {userSelection.length > 0 && <>
                            Genres:
                            {userSelection.map((genre, idx) => (
                                <span key={`us-${idx}`} onClick={() => handleUserSelection(genre)}>
                                        {genre.Name}
                                    </span>
                            ))}
                        </>}
                    </SelectionContainer>
                    <Input onInput={handleUserInputChange} ref={InputRef} placeholder={"Select A Genre"}
                           onFocus={() => setActive(true)}/>
                    <DropArrow onClick={() => setActive(false)} active={active}>
                        <ChevronLeft/>
                    </DropArrow>
                    {active && genres &&
                    <Options onSelect={handleUserSelection} selected={userSelection} options={filteredGenres}/>}
                </InputWrapper>
            </ClickAwayListener>
        </RootContainer>
    )
}


export default GenreSelector