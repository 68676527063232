import {FC} from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 112px;
  padding-top: 72px;
  background: ${props => props.theme.colors.loginScreenBg}
`
const Container = styled.div`
  margin: 0 auto;
  padding: 20px 15px;
  position: relative;
  max-width: 980px;
`

const AuthWrapper: FC = ({children}) => {
    return (
        <Wrapper>
            <Container>
                {children}
            </Container>
        </Wrapper>
    )
}


export default AuthWrapper