import {toJpeg} from "html-to-image";

export const INITIAL_CANVAS_SIZE = 226.2
export const SIZE_WIDTH_MULTIPLIER = 5
export const SIZE_HEIGHT_MULTIPLIER = 8

const INTERVAL_TIME = 2000

export const takeScreenshotInBrowser = async (): Promise<Blob | undefined | void> => {
    const canvasEL = document.querySelector('#magmaCanvas')
    if (!canvasEL) {
        return undefined
    }

    let options = {quality: 0.8}
    if ((canvasEL as HTMLElement).dataset.masterCanvas) {
        options = {
            ...options, ...{
                width: INITIAL_CANVAS_SIZE * SIZE_WIDTH_MULTIPLIER * 0.5,
                height: INITIAL_CANVAS_SIZE * SIZE_HEIGHT_MULTIPLIER * 0.5,
            }
        }
    }


    return await toJpeg(canvasEL as HTMLElement, {
        ...options
    })
        .then(async (dataUrl) => {
            if (hasScreenshotPageID()) {
                const blob = await (await fetch(dataUrl)).blob();
                if (blob instanceof Blob) {
                    return blob
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })


}

export const sendScreenshotDoneEvent = (file: Blob) => {
    const event = new CustomEvent('pageScreenshot', {detail: {file}})
    window.dispatchEvent(event)
}


export const setCurrentScreenshotPageID = (ID: string | undefined) => {
    window._SNAPSHOT_PAGE_ID = ID
}

const hasScreenshotPageID = (): boolean => {
    return Boolean(window._SNAPSHOT_PAGE_ID)
}


const clearOldTimeOuts = () => {
    if (Array.isArray(window._SNAPSHOT_INTERVAL)) {
        window._SNAPSHOT_INTERVAL.map(id => clearTimeout(id))
    }
}

export const stopScreenshotTimer = () => {

    clearOldTimeOuts()
}

export const initiateScreenshotTimer = () => {
    if (window._SNAPSHOT_INTERVAL) {
        clearOldTimeOuts()
    }
    window._SNAPSHOT_INTERVAL = []
    window._SNAPSHOT_INTERVAL[0] = setTimeout(startScreenshotTimer, INTERVAL_TIME)

}

const startScreenshotTimer = async (): Promise<void> => {

    if (!hasScreenshotPageID()) {

        return
    }


    takeScreenshotInBrowser().then(file => {

        if (file) {
            sendScreenshotDoneEvent(file)
        }

    }).catch(e => {
        console.error('Unable to Take screenshots', e)
    })
}