import React, {FunctionComponent} from 'react'
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
import styled from "styled-components";

const Wrapper = styled.div<{ hasCaption: boolean }>`
  height: 100%;
`

const Figure = styled.figure`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`


const PhotoContent: FunctionComponent<MagmaPhotoEntity> = ({Asset, TaggedUsers, Caption}) => (
    <>
        <Wrapper hasCaption={!!Caption}>
            <Figure>
                <Image
                    draggable={false}
                    src={Asset?.URL || ''}
                    alt={Caption || ''}
                />
            </Figure>
        </Wrapper>
    </>
)

export default PhotoContent
