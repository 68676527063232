import FontFaceObserver from 'fontfaceobserver'
import React from "react";
import modules from "./Modules";
import {findIndex} from 'lodash'
import safeStringify from 'fast-safe-stringify'
import {firestore} from "./Integrations/firebase";
import {CompState, convertPixelsToPoints} from "./lib/Models/Component";
import {ColorResult, RGBColor} from "react-color";
import {COLOR_MULTIPLIER, RGBObject} from './Modules/Text/TextModule';
import {__MapboxGeocoder} from "./react-app-env";
import {makeAssetEntity} from './lib/Models/Asset';
import firebase from "firebase/app";
import {IUser, IUserFragment} from "./lib/Models/User";
import {INITIAL_CANVAS_SIZE, SIZE_HEIGHT_MULTIPLIER, SIZE_WIDTH_MULTIPLIER} from './Components/MagCanvas';

const FONT_SIZE_CONVERSION_MULTIPLIER = 3

type RGBObjectKey = "red" | "green" | "blue" | "alpha"
type RGBColorKey = "r" | "g" | "b" | "a"

export const buildCDNUrl = (url: string): string => {
    if (!url) {
        return ''
    }
    return url.replace('assets.magma.sh', 'ik.imagekit.io/nginr/magma')
}

export const loadFont = async ({fontName}: { fontName: string }): Promise<void> => {

    return await new Promise((resolve, reject) => {
        if ((document as any).fonts.check(`16px ${fontName}`)) {
            resolve()
            return
        }
        const font = new FontFaceObserver(fontName)


        font.load().then(() => {
        }).catch((e) => null).finally(() => {
            resolve()
        });

    })
}


export const Block = ({component, props}: any) => {
    if (typeof component !== "undefined") {
        return React.createElement(component, props);
    }

    return null

};

export const getComponentName = (component: CompState): string | undefined => {

    if (component.component) {
        return component.component
    }

    if (component.design) {
        return 'Image'
    }
    if (component.label) {
        return 'Text'
    }

    return component.component || ''
}

export const ModuleResolver = ({
                                   component,
                                   type,
                                   props = null
                               }: { component: string, type: 'component' | 'options', props?: any }) => {

    const moduleComponent = modules.find(({name}) => name === component)

    return Block({component: moduleComponent ? moduleComponent[type] : undefined, props})
}

export const getPageMeta = () => {

}

export const convertScalePercentageToDecimal = (percentage: number): number => {
    return percentage / 100
}

export const translateAndStoreElement = ({target, x, y}: { target: HTMLElement, x: number, y: number }) => {
    // translate the element
    target.style.left = `${x}px`
    target.style.top = `${y}px`


    // update the position attributes
    target.setAttribute('data-x', x ? x.toString() : '0')
    target.setAttribute('data-y', y ? y.toString() : '0')
}


export const rotateAndStoreElement = ({target, angle}: { target: HTMLElement, angle: number }) => {
    const _angle = angle || target.getAttribute('data-angle') || 0

    target.style.transform = `rotate(${_angle}rad)`
    target.setAttribute('data-angle', _angle.toString())

}

export const resizeAndStoreElement = ({target, w, h}: { target: HTMLElement, w: number, h: number }) => {
    // update the element's style
    target.style.width = w + 'px'
    target.style.height = h + 'px'

    if (w) {
        target.setAttribute('data-w', w.toString())
    }
    if (h) {
        target.setAttribute('data-h', h.toString())
    }


}

export const normalizeStorageRotation = ({target, transform}: { target: HTMLElement, transform: number[] }) => {
    const [a, b] = transform
    const angle = convertAffineTransformationMatrixToRadians(b, a);
    const dataMatrixString = 'matrix(' + transform.join(',') + ')';
    const angleRadiansTransformRotate = 'rotate(' + angle + 'rad)';
    target.setAttribute('data-angle', String(angle));
    target.setAttribute('data-matrix', dataMatrixString);
    target.style.transform = angleRadiansTransformRotate;
    return angle
}

export const getCanvasBlob = async (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return await new Promise((resolve) => {
        canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.4)
    })
}


export const isCoverPage = (uuid: number) => {
    return uuid === 0
}

export const getPageIndex = (id: string, pages: any[]) => {
    return findIndex(pages, ['ID', id])
}

export const urlToBlob = async (url: string): Promise<Blob> => {
    return await fetch(url).then((response) => {
        return response.blob();
    })
}


export const getDragAngle = (event: any, el: Element) => {
    let box = el;
    let startAngle = parseFloat(box.getAttribute('data-angle') || '0');
    let center = {
        x: parseFloat(box.getAttribute('data-center-x') as string) || 0,
        y: parseFloat(box.getAttribute('data-center-y') as string) || 0
    };
    let angle = convertAffineTransformationMatrixToRadians(center.y - event.clientY, center.x - event.clientX);

    return angle - startAngle;
}

export const convertAffineTransformationMatrixToRadians = (b: number, a: number) => {
    return Math.atan2(b, a)
}

export const radians_to_degrees = (radians: number) => {
    const pi = Math.PI;
    return radians * (180 / pi);
}

export const extractAddressFromPlaceName = (location: __MapboxGeocoder.Result) => {
    if (!location.properties.address) {
        return location.place_name
    }
    const addressIdx = location.place_name.indexOf(location.properties.address)
    return location.place_name.substr(addressIdx)
}

export const convertColorToRGBObject = (color: ColorResult): RGBObject => {
    const result: RGBObject = {
        red: color.rgb.r / COLOR_MULTIPLIER,
        green: color.rgb.g / COLOR_MULTIPLIER,
        blue: color.rgb.b / COLOR_MULTIPLIER,
        alpha: color.rgb.a || 1
    }

    Object.keys(result).forEach(color => {
        if (result[color as RGBObjectKey] > 1) {
            result[color as RGBObjectKey] = 1
        }
        if (result[color as RGBObjectKey] < 0) {
            result[color as RGBObjectKey] = 0
        }
    })


    return result
}

export const convertRGBObjectToRGBColor = (rgba: RGBObject | string): RGBColor | string | undefined => {
    if (typeof rgba === 'string') {
        return rgba
    }

    if (!rgba || !('red' in rgba)) {
        return undefined
    }

    const result = {
        r: rgba.red * COLOR_MULTIPLIER,
        g: rgba.green * COLOR_MULTIPLIER,
        b: rgba.blue * COLOR_MULTIPLIER,
        a: rgba.alpha
    }

    Object.keys(result).forEach(color => {
        if (result[color as RGBColorKey] > COLOR_MULTIPLIER) {
            result[color as RGBColorKey] = COLOR_MULTIPLIER
        }
        if (result[color as RGBColorKey] < 0) {
            result[color as RGBColorKey] = 0
        }
    })

    return result
}

export const makeMagmaDesignWithName = (name: string) => {
    let url = `https://assets.magma.sh/templates/${name}.png`
    let asset = makeAssetEntity({
        url,
        name,
        fileType: 'png',
        directory: name,
        id: name
    })
    return {
        design: {
            id: name,
            asset,
            editable: true
        }
    }
}

export const makeTemplateThumbnailURL = (name: string, fileType: 'png' | 'jpg' | 'jpeg' = 'png') => {
    return `/Assets/PageTemplates/template_${name}.imageset/template_${name}.${fileType}`
}

export const toPlainObject = (data: any) => {
    if (typeof data !== 'object') {
        return undefined
    }

    return JSON.parse(safeStringify(data, (key, value) => {

        if (Array.isArray(value) && value.length === 0) {
            return
        }

        if (value === '[Circular]') {
            return
        }
        return value

    }))
}

export const placeholderAsset = {
    ID: 'dasdhjad',
    FileType: 'jpg',
    Directory: '',
    URL: 'https://ik.imagekit.io/nginr/mediaPlaceholder_H3lhNW9rNKs-.jpeg?updatedAt=1629606112854'
}

export const replicateDrafts = (fromUser: string, toUser: string, draftId: string) => {
    firestore.doc(`/users/${fromUser}/drafts/${draftId}`).get().then(snapShot => {
        const data = snapShot.data()
        firestore.collection(`/users/${toUser}/drafts/`).add({...data})

    })
}


export const convertPixelsToPercentageOfDefaultFontSize = (pixels: number) => {
    return pixels / FONT_SIZE_CONVERSION_MULTIPLIER
}

export const convertPercentageOfDefaultFontSizeToPixels = (percentage: number) => {
    return percentage * FONT_SIZE_CONVERSION_MULTIPLIER
}

export function buildSearchQuery<T>(collection: firebase.firestore.CollectionReference<T>, searchTerm: string): firebase.firestore.Query<T> {

    /**
     * Correct way to make 'starts-with' query in firestore
     * search for documents greater than or equal to the string you want and less than a successor key.
     * {@link https://stackoverflow.com/questions/46573804/firestore-query-documents-startswith-a-string Reference}
     */


    const strSearch = searchTerm;
    const strLength = strSearch.length;
    const strFrontCode = strSearch.slice(0, strLength - 1);
    const strEndCode = strSearch.slice(strLength - 1, strSearch.length);


    const endCode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return collection
        .where('LowercasedUsername', '>=', strSearch)
        .where('LowercasedUsername', '<', endCode)
}

export const prepareTaggedUsers = (rawUsers: IUser[]): IUserFragment[] => {

    return rawUsers.map(user => ({
        Fullname: user.Fullname || '',
        PhotoUrl: user.PhotoUrl,
        Ref: user.Ref || firestore.collection('users').doc(`${user.ID}`),
        Username: user.Username
    }))
}


export const checkInView = (container: HTMLElement, element: HTMLElement, partial: boolean = false) => {

    //Get container properties
    let cTop = container.scrollLeft;
    let cBottom = cTop + container.clientWidth;

    //Get element properties
    let eTop = element.offsetLeft - container.offsetLeft; // change here
    let eBottom = eTop + element.clientWidth;

    //Check if in view
    let isTotal = (eTop >= cTop && eBottom <= cBottom);
    let isPartial = partial && (
        (eTop < cTop && eBottom > cTop) ||
        (eBottom > cBottom && eTop < cBottom)
    );

    //Return outcome
    return (isTotal || isPartial);
}

export const getChildIndex = (node: HTMLElement | Element): number => {
    if (!node.parentNode) return 0

    return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
}

export const getCanvasSizeInPoints = (): {
    width: number,
    height: number
} => {
    return {
        width: convertPixelsToPoints(INITIAL_CANVAS_SIZE * SIZE_WIDTH_MULTIPLIER),
        height: convertPixelsToPoints(INITIAL_CANVAS_SIZE * SIZE_HEIGHT_MULTIPLIER)
    }
}

