import React, { Component, createContext } from "react";
import {auth, generateUserDocument} from "../../Integrations/firebase";
import firebase from "firebase/app";
import {IUser} from "../../lib/Models/User";


declare interface UserProvider_State{
    user: IUser | null
}

export const UserContext = createContext<UserProvider_State>({ user: null });


class UserProvider extends Component<any, UserProvider_State> {

    state: UserProvider_State = {
        user: null
    };

    unsubscribeUserAuthStateChangedListener: firebase.Unsubscribe| null = null;


    componentDidMount = async () => {
       this.unsubscribeUserAuthStateChangedListener =  auth.onAuthStateChanged(async userAuth => {
            const user: any = await generateUserDocument(userAuth);
            this.setState({ user });
        });
    };

    componentWillUnmount() {
        if(this.unsubscribeUserAuthStateChangedListener){
            this.unsubscribeUserAuthStateChangedListener()
        }
    }

    render() {
        return (
            // @ts-ignore
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;