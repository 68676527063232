import * as React from "react";

function SvgIconCanvaCDarkLight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="icon-canva-c-dark-light_svg__a"
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={28}
        height={28}
      >
        <path
          d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.333-13.333-13.333C8.636 2.667 2.667 8.637 2.667 16c0 7.364 5.97 13.333 13.333 13.333z"
          fill="currentColor"
        />
      </mask>
      <g
        mask="url(#icon-canva-c-dark-light_svg__a)"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path d="M1.333 1.333h29.334v29.334H1.333V1.333z" fill="#00C4CC" />
        <path
          d="M21.477 18.628a.602.602 0 00-.568-.47.567.567 0 00-.522.307c-.056.086-.107.172-.155.254a6.653 6.653 0 01-.11.181c-.626.987-1.12 1.424-2.289 2.036a3.842 3.842 0 01-1.697.425c-1.72 0-2.72-1.522-3.053-2.826-.587-2.31.386-5.183 1.653-6.827.719-.935 1.521-1.473 2.203-1.476a1.16 1.16 0 01.925.512c.357.575.473 1.059.067 1.968a.614.614 0 00.257.768c.34.181.759-.023 1.213-.675.48-.686.511-1.765.071-2.456-.543-.849-1.593-1.377-2.763-1.377a4.423 4.423 0 00-2.306.673c-2.88 1.82-4.531 6.023-3.679 9.367.304 1.193.973 2.272 1.835 2.963.338.266 1.553 1.137 3.026 1.137h.018c1.485-.007 2.613-.637 3.336-1.113.789-.526 1.477-1.248 2.106-2.21.052-.078.103-.16.152-.242l.139-.226a.88.88 0 00.141-.693zm-5.892 4.179v.001-.001z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgIconCanvaCDarkLight;
