import {forIn, groupBy} from "lodash";
import {FC, memo, useEffect, useState} from "react";
import {TextureData} from "../../../lib/Models/Texture";
import Texture from "./Texture";
import styled from "styled-components";


const Category = styled.div`
  margin: 12px;
`

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: 24px;
  row-gap: 24px;
  margin-top: 4px;
`
const Row: FC = ({children}) => <RowWrapper>{children}</RowWrapper>

declare interface FeedCategory {
    category: string
    categoryTextures: TextureData[]
}

const Feed: FC<{ textures: TextureData[], onSelection?: (texture: TextureData) => void }> = ({
                                                                                                 textures,
                                                                                                 onSelection
                                                                                             }) => {

    const [feedCategories, setFeedCategories] = useState<FeedCategory[]>([])

    useEffect(() => {
        const categories = groupBy(textures, 'Category')
        const _feedCategories: FeedCategory[] = []
        forIn(categories, (categoryTextures: TextureData[], category: string) => {
            _feedCategories.push({
                category,
                categoryTextures
            })
        })

        setFeedCategories(_feedCategories)

    }, [textures])

    return (
        <div style={{overflowY: 'scroll', maxHeight: '90vh'}}>
            {feedCategories.map(({category, categoryTextures}) => <Category key={category}>
                <span className={'text-white capitalize'}>
                    {category}
                </span>
                <Row>
                    {categoryTextures.map(texture => <Texture onSelection={onSelection} texture={texture}
                                                              key={texture.ID}/>)}
                </Row>
            </Category>)}
        </div>
    )
}

export default memo(Feed)