import {FC, memo} from "react";
import styled from "styled-components";

import {buildCDNUrl} from "../../helpers";

declare interface Profile_Props {
    src: string
    username: string
    size?: number
}

const Container = styled.div`
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 54px;
  align-items: center;
  margin-left: 60px;

`


const Profile: FC<Profile_Props> = ({src, size}) => {

    size = size || 50
    const radius = size / 2
    const imageUrl = src ? buildCDNUrl(src) + `?tr=w-${size * 2}` : ''

    return (
        <Container>
            {Boolean(imageUrl) && <svg data-visualcompletion="ignore-dynamic" role="none"
                                       style={{height: size, width: size, marginRight: 12}}>
                <mask id="media_profile">
                    <circle cx={radius} cy={radius} fill="white" r={radius}/>
                </mask>
                <g mask="url(#media_profile)">
                    <image style={{height: size, width: size}} x="0" y="0" height="100%"
                           width="100%"
                           xlinkHref={imageUrl}/>
                    <circle style={{strokeWidth: 2, fill: 'none', stroke: 'rgba(255,255,255,0.8)'}} cx={radius}
                            cy={radius} r={radius}/>
                </g>
            </svg>}
        </Container>
    )
}

export default memo(Profile)