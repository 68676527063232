import {ChangeEvent, FC, useCallback, useContext, useMemo} from "react";
import styled from "styled-components";
import {IGenre} from "../../lib/Models/Genre";
import GenreSelector from "../GenreSelector";
import {PagesContext, PageState} from "../../Providers/Page/PageProvider";
import {IProof} from "../../lib/Models/Mag";
import LocationSelector from "../LocationSelector";
import PrivacySelector from "../PrivacySelector";
import Button from "../../HOC/Button";
import {IPrivacy} from "../../lib/Models/Privacy";
import {__MapboxGeocoder} from "../../react-app-env";
import {ILocation} from "../../lib/Models/Map";
import cuid from "cuid";
import {extractAddressFromPlaceName} from "../../helpers";
import {debounce} from "lodash";
import ControlledTemplateCanvas from "../ControlledTemplateCanvas";


const RootWrapper = styled.section`
  position: relative;
`

const Section = styled.div`
  width: 100vw;
  background: ${props => props.theme.colors.publishScreenBg};
  height: calc(100vh);
  overflow: auto;
  padding-top: 160px;
  padding-bottom: 160px;
`

const Container = styled.div`
  padding: 0 30px;
  max-width: 800px;
  margin: 0 auto;
`

const HeadingContainer = styled.div`
  position: relative;
`

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
`

const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  width: 100%;
  background: ${props => props.theme.colors.overlayTextFieldBg};
  border: 1px solid #777;
  border-radius: 3px;

  ::placeholder {

  }
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
`

const Snapshot = styled.img`
  height: 300px;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
`

const PublishScreen: FC<{ onClose: () => void }> = ({onClose}) => {
    // @ts-ignore
    const [pagesState, dispatchPagesState] = useContext(PagesContext)


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeValue = useCallback(debounce((e: ChangeEvent<HTMLInputElement>) => {
        dispatchPagesState({
            type: 'SET_MAG_WITH_STORAGE',
            payload: {Caption: e.target.value}
        })
    }, 500), [dispatchPagesState])

    const handleLocationChange = useCallback((location: __MapboxGeocoder.Result) => {
        const locationToBeStored: ILocation = {
            Name: location.text,
            Address: '',
            Attributes: {
                Direction: 0,
                Zoom: 14
            },
            Coordinates: {
                Latitude: location.geometry.coordinates[0],
                Longitude: location.geometry.coordinates[1]
            },
            ID: cuid(),
            ShoppingLink: ''
        }

        if (location.properties.address) {
            locationToBeStored.Address = extractAddressFromPlaceName(location)
        }

        dispatchPagesState({
            type: 'SET_MAG_WITH_STORAGE',
            payload: {Location: locationToBeStored}
        })
    }, [dispatchPagesState])

    const handleGenreChange = (genres: IGenre[]) => {
        dispatchPagesState({
            type: 'SET_MAG_WITH_STORAGE',
            payload: {
                Genre: genres.map(genre => {
                    let _genre = {...genre}
                    if ('Featured' in _genre) {
                        delete _genre['Featured']
                    }
                    return _genre
                })
            }
        })
    }

    const handlePrivacyChange = (privacy: IPrivacy) => {
        dispatchPagesState({
            type: 'SET_MAG_WITH_STORAGE',
            payload: {Privacy: privacy.Name}
        })
    }


    const handlePublish = useCallback((e) => {
        e.preventDefault()
        dispatchPagesState({
            type: 'PUBLISH_MAG'
        })

    }, [dispatchPagesState])


    const pages = useMemo(() => pagesState?.Pages?.filter((page: PageState, idx: number) => idx !== 0), [pagesState?.Pages])

    return (
        <RootWrapper>
            <Section>
                <Container>
                    <HeadingContainer>
                        <Heading>
                            Publishing New Mag
                        </Heading>

                    </HeadingContainer>
                    <form onSubmit={handlePublish}>
                        <InputWrapper style={{display: 'flex'}}>
                            {pagesState?.Proofs && pagesState?.Proofs.map((proof: IProof, idx: number) => {
                                return <Snapshot src={proof?.preview?.URL} key={`proof-${idx}`}/>
                            })}
                            {pages.map((page: PageState) => <div key={page.ID}
                                                                 style={{
                                                                     display: 'inline-block',
                                                                     height: 300,
                                                                     width: 188,
                                                                     overflow: 'hidden'
                                                                 }}>
                                <ControlledTemplateCanvas components={page.Blocks}
                                                          removeModule={() => null}
                                                          setActiveComponent={() => null}
                                                          snapshot
                                                          centerX={false}
                                                          scale={0.25}
                                                          sortEnabled={false}/></div>)}
                        </InputWrapper>
                        <InputWrapper>
                            <Input defaultValue={pagesState?.Caption || ''} placeholder="Caption"
                                   required
                                   onChange={changeValue}/>
                        </InputWrapper>
                        <InputWrapper>
                            <GenreSelector initialValue={pagesState?.Genre} onSelectionChange={handleGenreChange}/>
                        </InputWrapper>
                        <InputWrapper>
                            <LocationSelector initialValue={pagesState?.Location}
                                              onSelectionChange={handleLocationChange}/>
                        </InputWrapper>
                        <InputWrapper>
                            <PrivacySelector initialValue={pagesState?.Privacy}
                                             onSelectionChange={handlePrivacyChange}/>
                        </InputWrapper>
                        <InputWrapper>
                            <Button type={"submit"}>
                                Publish Mag
                            </Button>
                        </InputWrapper>
                    </form>
                    <div style={{height: 100}}></div>
                </Container>
            </Section>
        </RootWrapper>
    )
}

export default PublishScreen