import styled from "styled-components";
import {FC} from "react";

const CardContainer = styled.div<Card_Props>`
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 40px 0 0;
  padding: 28px;
  width: 100%;
  max-width: ${props => props.maxWidth + 'px' || 'none'};
`

declare interface Card_Props{
    maxWidth?: number|string
}

const Card:FC<Card_Props> = ({maxWidth, children}) => {
    return (
        <CardContainer maxWidth={maxWidth}>
            {children}
        </CardContainer>

    )
}

export default Card