import {FC, useContext, useEffect} from "react";
import {UserContext} from "../Providers/Auth/UserProvider";
import Header from "../Layouts/Header";
import {navigate, RouteComponentProps} from "@reach/router";
import styled from "styled-components";
import {IUser} from "../lib/Models/User";
import SimpleCarousel from "../Components/SimpleCarousel";
import CoverTemplates, {ICoverTemplate} from "../lib/CoverTemplates";
import {PagesContext} from "../Providers/Page/PageProvider";
import {getNewMagID} from "../lib/Models/Mag";
import MagmaLayeredBlockEntity from "../lib/CoverTemplates/LayeredBlocks/MagmaLayeredBlockEntity";
import {pickBy} from "lodash";
import {toPlainObject} from "../helpers";


const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 60px 0;
`
const Wrap = styled.div`
  width: 100%;
`

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 15px;
`

const Onboarding: FC<RouteComponentProps> = () => {

    // @ts-ignore
    const user: IUser = useContext(UserContext);

    // @ts-ignore
    const dispatchPagesState = useContext(PagesContext)[1]

    const createNewMag = async () => {
        const newMagID = await getNewMagID(user.uid)
        dispatchPagesState({
            action: 'SET_ACTIVE_MAD_ID',
            payload: newMagID
        })
        await navigate(`/create/${newMagID}`)
    }

    const handleTemplateSelection = (template: ICoverTemplate) => {
        const payload = template.meta.map((layeredBlock: MagmaLayeredBlockEntity | any) => {
            layeredBlock = pickBy(layeredBlock, value => typeof value !== "undefined")

            return toPlainObject(layeredBlock)
        })

        dispatchPagesState({
            type: 'SET_COMPONENTS',
            payload
        })

        createNewMag().finally(() => null)
    }

    useEffect(() => {
        document.title = 'Create A Mag'
        return () => {
            document.title = 'Magma.'
        }
    })

    return (
        <>
            <Header user={user}/>
            <Root>
                <Wrap>
                    <Container>
                        <Title>Start With A Cover</Title>
                        <SimpleCarousel
                            onSelect={handleTemplateSelection}
                            labelKey={'category'}
                            items={CoverTemplates}
                            imageKey={'thumbnail'}/>
                    </Container>
                </Wrap>
            </Root>
        </>
    )
}

export default Onboarding