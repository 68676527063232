import styled from "styled-components";
import LocationSelector from "./LocationSelector";
import {FC} from "react";
import {__MapboxGeocoder} from "../react-app-env";
import cuid from "cuid";
import {extractAddressFromPlaceName} from "../helpers";
import MagmaLocationEntity from "../lib/Content/MagmaLocationEntity";
import {uploadAssetToMag} from "../lib/Models/Asset";
import {useParams} from "@reach/router";
import MagmaBlockContent from "../lib/Content/MagmaBlockContent";
import {isNil, omitBy} from 'lodash'

const ACCESS_TOKEN = `pk.eyJ1Ijoic2toYW5uYTEwMDUiLCJhIjoiY2tlNXE0YWx1MHQ1NjJ5cGV5ZW9tNmRnMSJ9.ihWPmR_cqrdmKTzIgYWbTw`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 99;
  overflow: auto;
`

const Container = styled.div`
  width: 800px;
  padding: 40px;
  height: 400px;
  background-color: #555;
  overflow: hidden;
  padding-bottom: 110px;
`

const LocationPicker: FC<{ updateContent: (content: MagmaBlockContent<MagmaLocationEntity>) => void }> = ({updateContent}) => {

    const {magID} = useParams()

    const getStaticImage = async ({zoom = 14, lng, lat}: { zoom?: number, lat: number, lng: number }) => {
        return await fetch(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+f00000(${lng},${lat})/${lng},${lat},${zoom},0/788x788?access_token=${ACCESS_TOKEN}`)
            .then(response => response.blob())
    }


    const handleLocationChange = async (result: __MapboxGeocoder.Result) => {
        const image = await getStaticImage({
            lat: result.geometry.coordinates[1],
            lng: result.geometry.coordinates[0]
        })

        const asset = await uploadAssetToMag({file: image, extension: 'png', magID})

        const content = {
            Map: omitBy(new MagmaLocationEntity({
                Name: result.text,
                Address: result.properties.address ? extractAddressFromPlaceName(result) : '',
                Attributes: {
                    Direction: 0,
                    Zoom: 14,
                    Thumbnail: asset
                },
                Coordinates: {
                    Longitude: result.geometry.coordinates[0],
                    Latitude: result.geometry.coordinates[1]
                },
                ID: cuid(),
                ShoppingLink: '',
                Thumbnail: asset.URL
            }), isNil) as MagmaLocationEntity
        }

        if (updateContent) {
            updateContent(content)
        }

    }

    return (
        <Wrapper>
            <Container>
                <LocationSelector onSelectionChange={handleLocationChange}/>
                <hr/>
            </Container>
        </Wrapper>
    )
}

export default LocationPicker