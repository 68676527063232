import {ChangeEvent, FC, useCallback, useEffect, useState} from "react";
import {createUnsplashApi} from "./definitions";
import Feed from "./Feed";
import {Basic} from "unsplash-js/dist/methods/photos/types";
import styled from "styled-components";
import {debounce} from 'lodash'

interface IUnsplash {
    ACCESS_KEY: string
    height: string
    width: string
    onSelection?: (photo: Basic) => void
}

const SearchBar = styled.div`
  padding: 24px 40px 24px 0;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;

  input {
    width: 100%;
    max-width: 800px;

    color: #111;
    height: 40px;
    padding-left: 20px;
    border-radius: 24px;
    background-color: #eee;
    border: 1px solid transparent;

    &:hover {
      background-color: #eee;
      border-color: #d1d1d1;
    }

    &:focus {
      outline: none;
    }

    &:focus-within {
      background-color: #fff;
      border-color: #d1d1d1;
    }
  }

`

export const RootWrapper = styled.section`
  position: relative;


`

export const UnsplashWrapper = styled.div`
  position: relative;
  padding: 0 24px 24px 24px;

  overflow: auto;
`

const Unsplash: FC<IUnsplash> = ({ACCESS_KEY, onSelection, height, width}) => {

    const unsplashApi = createUnsplashApi({ACCESS_KEY})


    const [photosResponse, setPhotosResponse] = useState<Basic[] | null>(null);
    const [searchQuery, setSearchQuery] = useState<null | string>(null);


    useEffect(() => {

        const request = searchQuery ? unsplashApi.search.getPhotos({
            query: searchQuery,
            perPage: 30
        }) : unsplashApi.photos.getRandom({count: 20})

        // @ts-ignore
        request.then((result) => {

            let photos: any[] = [];
            if (Array.isArray(result.response)) {
                photos = result.response
            }
            if (result.response && "results" in result.response) {
                photos = result.response?.results || []
            }
            setPhotosResponse(photos)

        })
            .catch(() => {
                console.log("something went wrong!");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchKeyword = useCallback(debounce((ev: ChangeEvent<HTMLInputElement>) => {
        const targetEl = ev.target as HTMLInputElement
        setSearchQuery(targetEl.value)
    }, 500), [setSearchQuery])

    return (
        <RootWrapper>
            <UnsplashWrapper style={{height, width}}>
                <SearchBar>
                    <input type="search" autoComplete="off"
                           onChange={searchKeyword}
                           autoFocus

                           name="searchKeyword" placeholder="Search free high-resolution photos" title="Search Unsplash"
                           autoCapitalize="none" spellCheck="false"/>
                </SearchBar>
                {photosResponse && <Feed photos={photosResponse} onSelection={onSelection || (() => null)}/>}
            </UnsplashWrapper>
        </RootWrapper>
    )
}

export default Unsplash