import {FC, useContext} from "react";
import styled from "styled-components";
import {PagesContext, PageState} from "../../Providers/Page/PageProvider"
import {CompState} from "../../lib/Models/Component";
import cuid from "cuid";
import PageTemplatesCategories from "../../lib/Configurations/PageTemplates";
import MagmaBlockEntity from "../../lib/MagmaBlockEntity";
import {toPlainObject} from "../../helpers";

const RootWrapper = styled.section`
  position: relative;
`

const Section = styled.div`
  width: 100vw;
  background: #000;
  height: calc(100vh);
  overflow: auto;
  padding-top: 160px;
  padding-bottom: 160px;
`
const Container = styled.div`
  padding: 0 30px;
  max-width: 1400px;
  margin: 0 auto;
`

const Title = styled.h2`
  font-size: 26px;
  font-weight: bold;
  opacity: 1;
`

const CategoryTitle = styled.h3`
  text-transform: uppercase;
  margin-top: 40px;
  font-weight: 800;
  opacity: 0.6;
  font-size: 24px;
`
const Thumbnail = styled.img`
  width: auto;
  height: 400px;
  object-fit: cover;
  object-position: top;
  box-shadow: 0 0 0 1px rgb(64 87 109 / 7%), 0 2px 12px rgb(53 71 90 / 20%);
`

const Showcase: FC<{ onPageAdded: Function }> = ({onPageAdded}) => {


    // @ts-ignore
    const dispatchPagesState = useContext(PagesContext)[1]


    const addPage = (blocks?: (MagmaBlockEntity | undefined)[]) => {
        let payload: PageState
        if (!blocks) {
            const LayeredBlocks: CompState[] = []
            const id = cuid()
            payload = {ID: id, LayeredBlocks, Blocks: []}

        } else {
            const Blocks: MagmaBlockEntity[] = toPlainObject(blocks)
            const id = cuid()
            payload = {ID: id, LayeredBlocks: [], Blocks}
        }


        dispatchPagesState({
            type: "ADD_PAGE",
            payload
        })

        onPageAdded()
    }

    return (
        <RootWrapper>
            <Section>
                <Container>
                    <Title>Select A Template</Title>
                    <hr/>
                    <div>
                        <button className={"py-8 px-6"}
                                onClick={() => addPage()}>

                            <Thumbnail src={'/Assets/blank_placeholder-80.jpg'}/>
                        </button>

                    </div>

                    {PageTemplatesCategories.map(({name, keyword, templates}) => {
                        return (
                            <div key={keyword}>
                                <CategoryTitle>{name}</CategoryTitle>

                                <div>
                                    <div className={"flex flex-wrap gap-1"}>
                                        {templates.map(({blocks, thumbnail, id}, idx) => {
                                            return (
                                                <button key={idx + 'img'} className={"py-8 px-6"}
                                                        onClick={() => addPage(blocks)}>
                                                    <Thumbnail src={thumbnail}/>
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Container>
            </Section>
        </RootWrapper>
    )
}


export default Showcase