import MagmaBlockLayoutMode from "./MagmaBlockLayoutMode";
import MagmaBlockInsets from "./MagmaBlockInsets";


export type TMagmaBlockLayout = {
    Mode?: MagmaBlockLayoutMode,
    AspectRatio?: TAspectRatio,
    Insets: MagmaBlockInsets,
    BackgroundColor?: string
}

// [width, height]
export type TAspectRatio = [number, number] | null

export default class MagmaBlockLayout {

    Mode?: MagmaBlockLayoutMode
    AspectRatio?: TAspectRatio
    Insets: MagmaBlockInsets
    BackgroundColor?: string // hex code


    constructor({
                    Mode = MagmaBlockLayoutMode.default(),
                    AspectRatio = null,
                    Insets,
                    BackgroundColor = '#FFFFFF'
                }: TMagmaBlockLayout) {

        this.Mode = Mode
        this.AspectRatio = AspectRatio
        this.Insets = Insets
        this.BackgroundColor = BackgroundColor
    }
}