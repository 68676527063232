import {FC, useState} from "react"
import styled from "styled-components"
import {UnsplashWrapper} from "../../../Components/MediaComponents/Unsplash"
import {TextureData} from "../../../lib/Models/Texture"
import Portal from "../../../Portal"
import {UnsplashButton} from "../../Image/Components/UnsplashSearch"
import Textures from "./Textures"

const TexturesWrapper = styled(UnsplashWrapper)`
  & > * {
    background-color: #000;
  }
`

const TexturePicker: FC<{ onSelection: (texture: TextureData) => void }> = ({onSelection}) => {
    const [visible, setVisible] = useState<boolean>(false)

    const closePortal = () => {
        setVisible(false)
    }

    const _onSelection = (texture: TextureData) => {
        if (typeof onSelection === 'function') {
            onSelection(texture)
        }
        setVisible(false)
    }


    return (
        <div className='mt-8'>
            <UnsplashButton onClick={() => setVisible(true)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill={"currentColor"}
                    viewBox="0 0 24 24"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                        d="M19.51 3.08L3.08 19.51c.09.34.27.65.51.9.25.24.56.42.9.51L20.93 4.49c-.19-.69-.73-1.23-1.42-1.41zM11.88 3L3 11.88v2.83L14.71 3h-2.83zM5 3c-1.1 0-2 .9-2 2v2l4-4H5zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83L21 12.12V9.29L9.29 21z"></path>
                </svg>
                <span className={"ml-4"}>Textures</span>
            </UnsplashButton>

            {visible && <Portal onClose={closePortal}>
                <TexturesWrapper>
                    <Textures onSelection={_onSelection}/>
                </TexturesWrapper>
            </Portal>}
        </div>
    )
}


export default TexturePicker