import {FC, MouseEventHandler, useState} from "react";
import styled from "styled-components";
import Portal from "../../Portal";
import Unsplash from "../../Integrations/Unpslash";
import {Basic} from "unsplash-js/dist/methods/photos/types";
import {urlToBlob} from "../../helpers";
import {uploadAssetToMag} from "../../lib/Models/Asset";
import {useParams} from "@reach/router";
import cuid from "cuid";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";

const MY_ACCESS_KEY = "OlvCw6F-XjvvAyUDsZmOnwbMMnm5ADXu84bf7sRFgBs"

export const Wrapper = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 6px 12px;

  &:hover {
    background-color: ${props => props.theme.colors.secondary.bg}
  }
`

export const Text = styled.span`
  font-size: 1.2rem;
  margin-left: 18px;
`
export const UnsplashWrapper = styled.section`

  display: flex;
  align-items: center;
  justify-content: center;


  & > * {
    background-color: #fff;
  }
`

const UnsplashComponent: FC<any> = ({updateContent}) => {

    const {magID} = useParams()
    const [addingUnsplash, setAddingUnsplash] = useState<boolean>(false)

    const handleItemClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
        setAddingUnsplash(true)
    }

    const _onSelection = (photo: Basic) => {

        setAddingUnsplash(false)

        urlToBlob(photo?.urls.regular).then(blob => {
            uploadAssetToMag({magID, extension: 'jpg', file: blob}).then(asset => {
                if (updateContent) {
                    updateContent(new MagmaBlockContent(
                        {
                            type: 'Photo',
                            content: new MagmaPhotoEntity({
                                Caption: `Photo by <a target="_blank" href="${photo?.user.links.html}">${photo?.user.name}</a> on <a href="https://unsplash.com" target="_blank">Unsplash</a>`,
                                TaggedUsers: [],
                                ShoppingLink: "",
                                ID: cuid(),
                                Asset: asset
                            })
                        }
                    ))
                }
            })
        })


    }


    return (
        <>
            <Wrapper onClick={handleItemClick}>
                <img src={"/icon/white-unsplash.png"} style={{height: 30}} alt={"White unsplash icon"}/>
                <Text>Unsplash</Text>
            </Wrapper>
            {addingUnsplash && <Portal onClose={() => setAddingUnsplash(false)}>
                <UnsplashWrapper>
                    <Unsplash onSelection={_onSelection} height={"70vh"} width="80vw" ACCESS_KEY={MY_ACCESS_KEY}/>
                </UnsplashWrapper>
            </Portal>
            }
        </>
    )
}

export default UnsplashComponent