import { IAsset } from "../../Models/Asset"


export interface MagmaDesignEntityProps {
    id: string
    editable: boolean
    isLogo: boolean
    asset: IAsset
    scale: number
}

export default class MagmaDesignEntity {
    id: string
    editable: boolean
    isLogo: boolean
    asset: IAsset
    scale: number

    constructor({id, editable, isLogo, asset, scale}: MagmaDesignEntityProps) {
        this.id = id
        this.editable = editable
        this.isLogo = isLogo
        this.asset = asset
        this.scale = scale
    }
}