import React, {FC, useEffect, useMemo, useState} from "react";
import {Link, RouteComponentProps} from "@reach/router";
import Card from "../../HOC/Card";
import Input from "../../HOC/Input";
import Button from "../../HOC/Button";
import AuthWrapper from "../../Layouts/Auth/Wrapper";
import styled from "styled-components";
import {auth, firestore} from "../../Integrations/firebase";
import {IUser} from "../../lib/Models/User";
import {debounce} from "lodash";


const Action = styled.div`
  text-align: center;
  padding: 20px 0;
`


const SignUp: FC<RouteComponentProps> = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false)
    const [username, setUsername] = useState<null | string>(null)


    const checkUsername = () => {
        if (!username || username === '') {
            return
        }

        fetch('https://us-central1-magma-app.cloudfunctions.net/CheckUsernameAvailability', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({username}) // body data type must match "Content-Type" header
        }).then(res => res.json())
            .then(d => {
                console.log(d)
            })
            .catch(() => null)

    };
    const debouncedCheckUsername = useMemo(
        () => debounce(checkUsername, 300)
        , []);


    useEffect(() => {

        debouncedCheckUsername()

    }, [debouncedCheckUsername, username])


    const createUserWithEmailAndPasswordHandler = (event: React.FormEvent<HTMLFormElement>, email: string, password: string, displayName: string) => {


        event.preventDefault();


        auth.createUserWithEmailAndPassword(email, password)
            .then(data => {
                if (!data.user) {
                    return
                }
                const user: IUser = {
                    BackgroundPhotoUrl: "",
                    LowercasedUsername: "",
                    MagsCount: 0,
                    PhotoUrl: "",
                    SubscribedCount: 0,
                    SubscribersCount: 0,
                    Username: "",
                    uid: data.user?.uid,
                    Fullname: displayName,
                    ID: data.user?.uid

                }

                firestore.doc(data.user?.uid).set(user).then(data => {
                    console.log(data)
                })

            })
            .catch(error => {
                setError(error.message);
                console.error("Error signing in with password and email", error);
            });

    };
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        } else if (name === "userPassword") {
            setPassword(value);
        } else if (name === "displayName") {
            setDisplayName(value);
        } else if (name === "username") {
            setUsername(value.replace(/\s/g, ''));
        }
    };
    return (
        <AuthWrapper>
            <div className="grid lg:grid-cols-5 gap-3 items-center">
                <div className={"lg:col-span-3"}>
                    <h1 className={"text-6xl"}>
                        Magma.
                    </h1>
                    <h2 className={"mt-3 text-xl max-w-md font-light"}>
                        Let your creativity and content live free and powerful. Page after page.
                    </h2>
                </div>
                <div className={"lg:col-span-2"}>
                    <Card>
                        <>
                            {error !== null &&
                            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
                            <form onSubmit={(event) => {
                                createUserWithEmailAndPasswordHandler(event, email, password, displayName)
                            }}>
                                <div>
                                    <Input
                                        stacked
                                        type="text"
                                        name="username"
                                        value={username ?? ''}
                                        placeholder="Username"
                                        id="username"
                                        onChange={onChangeHandler}
                                    />
                                    {username && isUsernameAvailable && 'Username is available'}
                                    {username && !isUsernameAvailable && 'Username not available'}
                                </div>

                                <div>
                                    <Input
                                        stacked
                                        type="text"
                                        name="displayName"
                                        value={displayName}
                                        placeholder="Display Name"
                                        id="displayName"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div>
                                    <Input
                                        stacked
                                        type="email"
                                        name="userEmail"
                                        value={email}
                                        placeholder="Email"
                                        id="userEmail"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div>
                                    <Input
                                        stacked
                                        type="password"
                                        name="userPassword"
                                        value={password}
                                        placeholder="Password"
                                        id="userPassword"
                                        onChange={onChangeHandler}
                                    />
                                </div>
                                <div>
                                    <Button block disabled={!username || username === '' || !isUsernameAvailable}>
                                        Create An Account
                                    </Button>
                                </div>
                            </form>


                        </>

                    </Card>
                    <Action>
                        <Link to={"/"}>
                            Log In To An Existing Account
                        </Link>

                    </Action>
                </div>
            </div>
        </AuthWrapper>
    );
};
export default SignUp;