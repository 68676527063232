import {FC, useState} from "react";
import styled from "styled-components";

import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import Portal from "../../Portal";
import LocationPicker from "../LocationPicker";
import MagmaLocationEntity from "../../lib/Content/MagmaLocationEntity";

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 6px 12px;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.secondary.bg}
  }
`

const Text = styled.span`
  font-size: 1.2rem;
  margin-left: 18px;
`


const Location: FC<{ updateContent: (content: MagmaBlockContent<MagmaLocationEntity>) => void }> = ({updateContent}) => {


    const [addingMap, setAddingMap] = useState<boolean>(false)


    return (
        <>
            <Wrapper onClick={() => setAddingMap(true)}>
                <img alt={"Location Icon"} src={"/icon/white-location.png"}
                     style={{height: 30, width: 30, objectFit: 'cover'}}/>
                <Text>Embed Map</Text>
            </Wrapper>
            {addingMap && <Portal onClose={() => setAddingMap(false)}>
                <LocationPicker updateContent={updateContent}/>
            </Portal>
            }
        </>
    )
}

export default Location