import firebase from "firebase/app";
import { firestore as _firestore } from "../../Integrations/firebase";
import { IAsset } from "./Asset";

export type TextureData = ITexture

export interface ITexture {
    Asset: IAsset
    Category: string
    ID: string
}

const createTexture = function (data: firebase.firestore.DocumentData): TextureData {
    const {
        Asset,
        Category,
        ID
    } = data

    return {
        Asset,
        Category,
        ID
    }
}

export const getTextures = async (): Promise<any> => {
    const texturesRef = _firestore.collection('textures')

    const querySnapshot: firebase.firestore.QuerySnapshot = await texturesRef.get();

    const textures: TextureData[] = []

    querySnapshot.docs.forEach(doc => textures.push(createTexture(doc.data())));

    return textures
}