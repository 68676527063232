import {MagmaBlockContentKind} from "./MagmaBlockContentKind";
import MagmaBlockLayout from "./MagmaBlockLayout";
import MagmaBlockContent from "./Content/MagmaBlockContent";
import MagmaTextEntity from "./Content/MagmaTextEntity";
import MagmaPhotoEntity from "./Content/MagmaPhotoEntity";

type TPossibleEntities = MagmaTextEntity | MagmaPhotoEntity;

type MagmaBlockEntityProps = {
    ID: string
    Kind: MagmaBlockContentKind,
    Layout: MagmaBlockLayout
    IsPlaceholder?: boolean
    Content?: MagmaBlockContent<TPossibleEntities>
}

export default class MagmaBlockEntity {

    ID: string
    Kind: MagmaBlockContentKind
    Content?: MagmaBlockContent<TPossibleEntities>
    Layout: MagmaBlockLayout
    IsPlaceholder: boolean

    constructor({ID, Kind, Layout, IsPlaceholder = true, Content}: MagmaBlockEntityProps) {
        this.ID = ID
        this.Kind = Kind
        this.Layout = Layout
        this.IsPlaceholder = IsPlaceholder

        Content && (this.Content = Content)
    }

}