import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import {PagesState} from "../Providers/Page/PageProvider";


const firebaseConfig = {
    apiKey: "AIzaSyCUhZed9QnzhFpSr3l1TvZw4wgGtyxV1A8",
    authDomain: "magma-app.firebaseapp.com",
    databaseURL: "https://magma-app.firebaseio.com",
    projectId: "magma-app",
    storageBucket: "magma-app.appspot.com",
    messagingSenderId: "503584050787",
    appId: "1:503584050787:web:51a9742784e1196ff5b256",
    measurementId: "G-M6J9FBWCPR"
};

firebase.initializeApp(firebaseConfig);

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(googleAuthProvider);
};


export const generateUserDocument = async (user: any | { uid?: any; email?: any; displayName?: any; photoURL?: any; }, additionalData?: firebase.firestore.DocumentData) => {
    if (!user) return;

    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const {email, displayName, photoURL} = user;
        try {
            await userRef.set({
                displayName,
                email,
                photoURL,
                ...additionalData
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

const getUserDocument = async (uid: any) => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};


export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();


export const saveDraft = (payload: PagesState) => {
    firestore.doc(`/users/${payload.PublisherID}/drafts/`)
}


