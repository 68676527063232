import {FC, useState} from "react";
import styled from "styled-components";
import {UnsplashSymbol} from "../../../icons";
import Portal from "../../../Portal";
import Unsplash from "../../../Integrations/Unpslash";
import {Basic} from "unsplash-js/dist/methods/photos/types";

const MY_ACCESS_KEY = "OlvCw6F-XjvvAyUDsZmOnwbMMnm5ADXu84bf7sRFgBs"

const UnsplashWrapper = styled.section`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;


  & > * {
    background-color: #fff;
  }
`
export const UnsplashButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px;
  width: 100%;
  border: 1px solid #000;
  line-height: 42px;
  border-radius: 8px;
  outline: none !important;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }

  &:focus, &:active {
    background: rgba(0, 0, 0, 0.07);
  }
`

const UnsplashSearch: FC<{ onSelection: (photo: Basic) => void }> = ({onSelection}) => {

    const [visible, setVisible] = useState<boolean>(false)

    const closePortal = () => {
        setVisible(false)
    }

    const _onSelection = (photo: Basic) => {
        if (typeof onSelection === 'function') {
            onSelection(photo)
        }
        setVisible(false)
    }


    return (
        <div>
            <UnsplashButton onClick={() => setVisible(true)}>
                <UnsplashSymbol/> <span className={"ml-4"}>Search Unsplash</span>
            </UnsplashButton>

            {visible && <Portal onClose={closePortal}>
                <UnsplashWrapper>
                    <Unsplash onSelection={_onSelection} height={"70vh"} width="80vw" ACCESS_KEY={MY_ACCESS_KEY}/>
                </UnsplashWrapper>
            </Portal>
            }
        </div>
    )
}

export default UnsplashSearch