import {FC, useCallback} from "react";
import {IModule} from "../../lib/Models/Module";
import ModuleWrapper from "../ModuleWrapper";
import {useBus, useListener} from "react-bus";
import {deleteAsset} from "../../lib/Models/Asset";
import {CompState} from "../../lib/Models/Component";

export interface ImageMeta {
    url?: string | null
    alt?: string
}

const INITIAL_CANVAS_SIZE = 226.2
const SIZE_WIDTH_MULTIPLIER = 5
const SIZE_HEIGHT_MULTIPLIER = 8

const ImageModule: FC<IModule> = ({id, onRemove, onInactive, onActive, meta, sequence}) => {

    const bus = useBus()

    const sendUpdateSignal = useCallback((payload: any) => {
        bus.emit('updateComponent', {id, data: payload})
    }, [bus, id])

    const updateMeta = useCallback((payload: any) => {
        if (payload.action === "updateMeta") {
            sendUpdateSignal(payload.data)
        }
    }, [sendUpdateSignal])

    const onUpdateFromWrapper = useCallback((payload: any) => {
        sendUpdateSignal(payload)
    }, [sendUpdateSignal])


    useListener(id, updateMeta)


    return (
        <ModuleWrapper
            sequence={sequence}
            rotatable={true} componentMeta={meta} resizable={true} onUpdate={onUpdateFromWrapper}
            initialSize={[INITIAL_CANVAS_SIZE * SIZE_WIDTH_MULTIPLIER + 'px', INITIAL_CANVAS_SIZE * SIZE_HEIGHT_MULTIPLIER + 'px']}
            onActive={() => onActive(meta)} onInactive={onInactive} id={id} onRemoveButtonClick={onRemove}>
            <img alt={"placeholder"} style={{
                background: 'transparent',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }}
                 src={meta?.design?.asset?.URL || 'https://ik.imagekit.io/nginr/magma-placeholder_i3wp7mB_OMH.jpg'}/>
        </ModuleWrapper>
    )
}


export const removeAsset = async (component: CompState) => {
    if (component?.design?.asset?.ID) {
        return await deleteAsset(component.design.asset)
    }

    return true
}

export default ImageModule

