import {IAsset} from "./Asset";
import cuid from "cuid";
import {TMagmaBlockLayout} from "../MagmaBlockLayout";
import MagmaBlockContent from "../Content/MagmaBlockContent";
import { clone } from "lodash";

export const DEFAULT_TRANSFORM = [1, 0, 0, 1, 0, 0]

export interface IBlock {
    ID: string
    Kind: 'master' | 'text' | 'media'
    Content: MagmaBlockContent<unknown>
    Layout: TMagmaBlockLayout
    IsPlaceholder: boolean
}

export interface IBounds {
    Origin: [number, number]
    Size: [number, number]
}

export interface ILayeredBlock {
    bound: IBounds
    center: [number, number]
    design?: {
        asset: IAsset
        editable: boolean
        id: string
        isLogo: boolean
        opacity: number
        frame?: {
            Origin: [number, number]
            Size: [number, number]

        }
    }
    label?: {
        characterSpacing: number
        fontColor: {
            red: number
            green: number
            blue: number
            alpha: number
        }
        fontName: string
        fontSize: number
        lineHeight: number
        numberOfLines: number
        text: string
        textAlignment: number
    }
    id: string
    tag: number
    transform: [number, number, number, number, number, number]
}

export function createMasterBlock(asset: IAsset): any {

    return {
        ID: cuid(),
        IsPlaceholder: false,
        Content: {
            Master: {
                Background: {
                    Asset: asset,
                    Attributes: {
                        Transform: clone(DEFAULT_TRANSFORM)
                    },
                    ID: cuid()
                },
                ID: cuid()
            }
        },
        Kind: 'master',
        Layout: {
            BackgroundColor: '#FFFFFF',
            Insets: {Bottom: 0, Left: 0, Right: 0, Top: 0},
            Mode: {
                Height: 0,
                Width: 0
            }
        }
    }

}
