import {IAsset} from "../Models/Asset";
import {IUserFragment} from "../Models/User";
import MagmaLinkEntity from "./MagmaLinkEntity";

type MagmaVideoProvider = 'youtube' | 'vimeo' | 'magma'

export type YoutubeVideo = {
    etag: string
    id: string | { videoId: string }
    kind: string
    snippet: {
        channelId: string
        channelTitle: string
        description: string
        liveBroadcastContent: string
        publishTime: string
        publishedAt: string
        thumbnails: {
            default: {
                url: string
                width: number
                height: number
            }
            high: {
                url: string
                width: number
                height: number
            }
            medium: {
                url: string
                width: number
                height: number
            }
        }
        title: string
    }
}


type TMagmaVideoEntity = {
    ID: string
    Thumbnail?: string
    URL?: string
    Asset?: IAsset
    Placeholder?: IAsset | null
    Title: string
    Author?: string
    Provider: number
    Link?: any
    Caption?: string
    ShoppingLink?: string
    TaggedUsers?: IUserFragment[]
}


export default class MagmaVideoEntity {
    static DECODE_KEYS = {
        0: 'youtube',
    }
    ID: string
    Thumbnail?: string
    URL?: string
    Asset?: IAsset
    Placeholder?: IAsset | null
    Title: string
    Author?: string
    Provider: number
    Link?: MagmaLinkEntity
    Caption?: string
    ShoppingLink?: string = ""
    TaggedUsers?: IUserFragment[]

    constructor({
                    Asset,
                    Author = "",
                    Caption = "",
                    ID,
                    Link = null,
                    Placeholder = null,
                    Provider,
                    ShoppingLink = "",
                    TaggedUsers = [],
                    Thumbnail = "",
                    Title,
                    URL
                }: TMagmaVideoEntity) {

        this.Asset = Asset
        this.Author = Author
        this.Caption = Caption
        this.ID = ID
        this.Link = Link
        this.Title = Title
        this.Provider = Provider
        this.ShoppingLink = ShoppingLink
        this.Placeholder = Placeholder

        if (TaggedUsers) {
            this.TaggedUsers = TaggedUsers
        }

        if (URL) {
            this.URL = URL
        }
        this.Thumbnail = Thumbnail


    }

    decodeProviderName?(): MagmaVideoProvider {

        if (!(typeof this.Provider === 'number') || !(this.Provider in (MagmaVideoEntity.DECODE_KEYS))) {
            return 'magma'
        }

        // @ts-ignore
        return this.DECODE_KEYS[this.Provider]
    }

}