import {FC, useEffect, useRef} from "react";
import {Basic} from "unsplash-js/dist/methods/photos/types";
import {decode} from "blurhash";
import styled from "styled-components";


const Cover = styled.div`
  transition: .3s ease;
  opacity: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .34) 0, rgba(0, 0, 0, .338) 3.5%, rgba(0, 0, 0, .324) 7%, rgba(0, 0, 0, .306) 10.35%, rgba(0, 0, 0, .285) 13.85%, rgba(0, 0, 0, .262) 17.35%, rgba(0, 0, 0, .237) 20.85%, rgba(0, 0, 0, .213) 24.35%, rgba(0, 0, 0, .188) 27.85%, rgba(0, 0, 0, .165) 31.35%, rgba(0, 0, 0, .144) 34.85%, rgba(0, 0, 0, .126) 38.35%, rgba(0, 0, 0, .112) 41.85%, rgba(0, 0, 0, .103) 45.35%, rgba(0, 0, 0, .1) 48.85%, rgba(0, 0, 0, .103) 52.35%, rgba(0, 0, 0, .112) 55.85%, rgba(0, 0, 0, .126) 59.35%, rgba(0, 0, 0, .144) 62.85%, rgba(0, 0, 0, .165) 66.35%, rgba(0, 0, 0, .188) 69.85%, rgba(0, 0, 0, .213) 73.35%, rgba(0, 0, 0, .237) 76.85%, rgba(0, 0, 0, .262) 80.35%, rgba(0, 0, 0, .285) 83.85%, rgba(0, 0, 0, .306) 87.35%, rgba(0, 0, 0, .324) 90.85%, rgba(0, 0, 0, .338) 94.35%, rgba(0, 0, 0, .347) 97.85%, rgba(0, 0, 0, .35));
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  padding: 20px;

  a {
    display: flex;
    align-items: center;
    color: #fff;

    img {
      border-radius: 50%;
      margin-right: 10px;
    }
  }
`

const Figure = styled.figure`
  position: relative;
  display: block;
  cursor: pointer;

  &:hover ${Cover} {
    opacity: 1;
  }`

const ImageCanvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`


const PhotoComp: FC<{ photo: Basic, onSelection?: () => void }> = ({photo, onSelection}) => {
    const {user, urls, blur_hash, height, width} = photo;

    const pixels = blur_hash && decode(blur_hash, 32, 32);

    useEffect(() => {
        if (canvas.current) {
            const ctx = canvas.current.getContext("2d");
            if (ctx && Array.isArray(pixels)) {
                const imageData = ctx.createImageData(32, 32);
                imageData.data.set(pixels);
                ctx.putImageData(imageData, 0, 0);
            }
        }
    })

    const selectImage = onSelection || (() => null)

    const canvas = useRef<HTMLCanvasElement>(null)

    return (

        // <Fragment>
        <Figure onClick={selectImage}>
            <ImageCanvas as={"div"} style={{backgroundColor: 'rgb(89, 89, 89)'}}/>
            <ImageCanvas height={32} width={32} ref={canvas}/>
            <ImageCanvas as={"img"} src={urls.small} loading={"lazy"}/>
            <div style={{paddingBottom: `${height / width * 100}%`}}/>
            <Cover>
                <a
                    className="credit"
                    target="_blank"
                    href={`https://unsplash.com/@${user.username}`} rel="noreferrer"
                >
                    <img alt="user profile" src={user.profile_image.small} loading={"lazy"}/>{user.name}
                </a>
            </Cover>
        </Figure>

        // </Fragment>
    );
};


export default PhotoComp