import {ICoverTemplate} from ".."
import {makeTemplateThumbnailURL} from "../../../helpers"
// import MagmaDesignEntity from "../LayeredBlocks/MagmaDesignEntity"
import MagmaLayeredBlockEntity from "../LayeredBlocks/MagmaLayeredBlockEntity"
import FashionCategoryLayers from "../DesignLayers/Categories/FashionCategory"

const {fashion_1} = FashionCategoryLayers

const FashionCategory: ICoverTemplate[] = [
    {
        title: 'fashion1',
        thumbnail: makeTemplateThumbnailURL('fashion1', "png"),
        category: 'Fashion',
        meta: [
            new MagmaLayeredBlockEntity({
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        374,
                        598.6666666666666
                    ]
                },
                backgroundColor: {
                    green: 0,
                    blue: 0,
                    red: 0,
                    alpha: 0
                },
                isBackground: false,
                tag: 0,
                center: [
                    187,
                    299.3333333333333
                ],
                frame: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        374,
                        598.6666666666666
                    ]
                }
            }),
            new MagmaLayeredBlockEntity({
                frame: {
                    Origin: [
                        -0.5,
                        -0.29046816479404924
                    ],
                    Size: [
                        375,
                        599.2509363295881
                    ]
                },

                backgroundColor: {
                    red: 1,
                    alpha: 1,
                    green: 1,
                    blue: 1
                },
                center: [
                    187,
                    299.335
                ],
                isBackground: false,
                design: fashion_1.background,
                bounds: {
                    Size: [
                        375,
                        599.2509363295881
                    ],
                    Origin: [
                        -0.5,
                        -0.29046816479404924
                    ]
                },
                tag: 1,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                isBackground: false,
                bounds: {
                    Size: [
                        374,
                        120
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                frame: {
                    Size: [
                        374,
                        120
                    ],
                    Origin: [
                        15,
                        0
                    ]
                },
                center: [
                    202,
                    60
                ],
                tag: 2,
                backgroundColor: {
                    alpha: 0,
                    blue: 0,
                    green: 0,
                    red: 0
                },
                label: fashion_1.classic
            }),
            new MagmaLayeredBlockEntity({
                backgroundColor: {
                    green: 0,
                    red: 0,
                    blue: 0,
                    alpha: 0
                },

                label: fashion_1.old_new,
                bounds: {
                    Size: [
                        299.2,
                        299.335
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                frame: {
                    Size: [
                        299.2,
                        299.335
                    ],
                    Origin: [
                        40,
                        294.3349999999999
                    ]
                },
                tag: 3,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                isBackground: false,
                center: [
                    189.6,
                    444.00249999999994
                ]
            }),
            new MagmaLayeredBlockEntity({

                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        93.5,
                        35
                    ]
                },
                tag: 4,
                label: fashion_1.issue_01,
                center: [
                    41.75,
                    183.79722222222222
                ],
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                backgroundColor: {
                    red: 0,
                    green: 0,
                    alpha: 0,
                    blue: 0
                },
                isBackground: false,
                frame: {
                    Size: [
                        35.000000000000014,
                        93.5
                    ],
                    Origin: [
                        24.249999999999996,
                        137.04722222222222
                    ]
                }
            })
        ]
    }
]

export default FashionCategory