import {FC} from "react";
import styled from "styled-components";
import Button from "../HOC/Button";

interface IModuleOptionsWrapper_Props {
    name: string
    onDone: () => void
    onRemove?: () => void
}

const RootWrapper = styled.aside`
  position: absolute;
  left: 0;
  background: ${props => props.theme.colors.moduleOptionsBg};
  top: 0;
  bottom: 0;
  width: 300px;
  padding: 25px 20px;
  overflow: auto;
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 10px;
`

const ModuleOptionsWrapper: FC<IModuleOptionsWrapper_Props> = ({children, onDone, name}) => {

    return (
        <RootWrapper>
            <>
                <Title>
                    {name}
                </Title>
                <hr className={"mb-8"}/>
                {children}


                <div className={"mt-12 sticky bottom-0"}>
                    <Button block={true} onClick={() => onDone()}>
                        Done
                    </Button>
                </div>
            </>
        </RootWrapper>
    )
}

export default ModuleOptionsWrapper