import {FC, useCallback, useEffect, useMemo} from "react";
import {IModule} from "../../lib/Models/Module";
import ModuleWrapper from "../ModuleWrapper";
import {useBus, useListener} from "react-bus";
import {isNil, omitBy} from "lodash";

import "quill/dist/quill.bubble.css"
import {useQuill} from "react-quilljs";
import {stopScreenshotTimer} from "../../screenshotUtils";
import AllFonts, {StorageFont} from "../../Assets/Fonts/AllFonts";
import {convertPercentageOfDefaultFontSizeToPixels} from "../../helpers";

export interface RGBObject {
    alpha: number,
    red: number,
    green: number,
    blue: number
}

const ALIGNMENT = {
    0: false, 1: "center", 2: "right", "natural": "left"
}

const DEFAULT_STYLES = {
    // TODO: pick a default font
    fontFamily: 'Inter',
    color: 'rgb(0,0,0)',
    fontSize: 100,
    align: 'center'
}


export const COLOR_MULTIPLIER = 255

const TextModule: FC<IModule> = ({id, onRemove, onInactive, onActive, meta, sequence}) => {
    const bus = useBus()
    const {quill, quillRef, editor} = useQuill({
        theme: "bubble", placeholder: "Text", modules: {
            "toolbar": false
        }
    });

    const options = useMemo(() => {

        let fontSize = meta.label?.storageFontSize || 100

        if (!fontSize) {
            fontSize = convertPercentageOfDefaultFontSizeToPixels(meta.label.fontSize)
        }

        return {
            fontFamily: meta.label?.fontName || DEFAULT_STYLES.fontFamily,
            color: meta.label?.fontColor ? stringifyRGBObject(meta.label?.fontColor) : DEFAULT_STYLES.color,
            fontSize: fontSize || DEFAULT_STYLES.fontSize,
            align: ALIGNMENT[meta.label?.textAlignment as 0 | 1 | 2],
            letterSpacing: meta.label?.characterSpacing ? convertPercentageOfDefaultFontSizeToPixels(meta.label.characterSpacing) : 'inherit'
        }
    }, [meta])

    const sendUpdateSignal = useCallback((payload: any) => {
        const _payload = omitBy(payload, isNil)

        bus.emit('updateComponent', {id, data: _payload})
    }, [bus, id])

    useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(0, meta.label?.text ?? 'Text');
            quill.blur()
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [quill])

    useEffect(() => {
        if (quill) {
            quill.formatText(0, quill.getLength(), {
                ...options
            });
        }
    }, [options, quill])

    const updateMeta = (payload: any) => {
        sendUpdateSignal(payload.data)
    }

    const handler = useCallback(() => {
        if (quill) {

            quill.blur()
            const text = quill.getText(0).replace(/\n/g, "<br/>");
            quill.root.style.minWidth = quill.root.clientWidth + 'px'

            sendUpdateSignal({...meta, label: {...meta.label, text}})
        }
    }, [meta, quill, sendUpdateSignal])


    useEffect(() => {
        editor?.root.addEventListener('blur', handler);
        return () => {
            editor?.root.removeEventListener('blur', handler);
        }
    }, [editor?.root, handler, quill])


    const onUpdateFromWrapper = useCallback((payload: any) => {
        // TODO: Experimental
        if (quill) {
            quill.root.style.minWidth = quill.root.clientWidth + 'px'
        }

        sendUpdateSignal(payload)
    }, [sendUpdateSignal, quill])

    useListener(id, updateMeta)

    const fontFamily = useMemo(() => AllFonts[options.fontFamily as StorageFont] ?? options.fontFamily, [options.fontFamily])

    return (
        <ModuleWrapper
            sequence={sequence}
            initialPos={[meta.x ?? 0, meta.y ?? 0]}
            rotatable={true}
            onUpdate={onUpdateFromWrapper}
            resizable={false}
            initialSize={['auto', 'auto']}
            componentMeta={meta}
            onDoubleClick={() => {
                if (quill) {
                    quill.setSelection(0, quill.getLength())
                }

                stopScreenshotTimer()
            }}
            onActive={() => {
                onActive(meta)
            }}
            onInactive={onInactive}
            id={id}
            onRemoveButtonClick={onRemove}>
            <div style={{
                padding: '15px',
                minWidth: 300,
            }}>
                <div style={{
                    color: options.color,
                    fontSize: options.fontSize,
                    fontFamily: fontFamily,
                    letterSpacing: options.letterSpacing,
                }} ref={quillRef}/>
            </div>

        </ModuleWrapper>
    )
}

function stringifyRGBObject(color: RGBObject) {
    return `rgba(${[(color.red ?? 0) * COLOR_MULTIPLIER, (color.green ?? 0) * COLOR_MULTIPLIER, (color.blue ?? 0) * COLOR_MULTIPLIER, color.alpha ?? 1].toString()})`
}


export default TextModule