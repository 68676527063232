import {LightTheme} from 'styled-components';

const lightTheme: LightTheme = {
    name: 'lightTheme',
    colors: {
        headerBg: '#fff',
        sidenavBg: 'rgb(255,255,255)',
        sidenavHover: 'rgba(0,0,0,0.2)',
        canvasWrapperBg: 'rgb(235,236,240)',
        canvasWrapperBgFocus: 'rgba(235,236,240, 0.4)',
        magNavigatorBackground: '#252627',
        canvasControlsBackground: '#fff',
        moduleOptionsBg: 'rgba(242, 243, 245)',
        textWrapperBg: '#fff',
        textOnOnboard: 'rgba(0,0,0,0.5)',
        textWrapperIconHoverBg: 'rgba(0, 0, 0, 0.05)',
        actionDropdownBg: 'rgba(242,243,245, 0.8)',
        donButtonBg: 'rgb(255,255,255)',
        overlayPanelBg: 'rgba(255,255,255, 0.9)',
        overlayTextFieldBg: '#fff',
        overlayTextFieldBgDark: '#fafafa',
        borderColor: '#e5e7eb',
        text: '#000',
        background: '#fff',
        darkText: '#fff',
        publishScreenBg: 'rgb(255,255,255)',
        loginScreenBg: 'rgb(241,241,241)',
        muted: '#f0f2f5',
        secondary: {
            bgColor: `251, 179, 30`,
            bg: 'rgb(251,179,30)',
            text: 'rgb(17, 17, 17)'
        },
        success: {
            bgColor: `90, 196, 96`,
            bg: 'rgb(90, 196, 96)',
            text: '#fff'
        },
        error: {
            bgColor: `172, 0, 32`,
            bg: '#b00020',
            text: '#fff'
        }
    },

};

export {lightTheme};