export type GLOBAL_EVENT = 'THEME_CHANGE'

export const GLOBAL_EVENTS: Record<GLOBAL_EVENT, string> = {
    THEME_CHANGE: 'themeChange'
}

export const on = (eventType: string, listener: EventListenerOrEventListenerObject) => {
    document.addEventListener(eventType, listener);
}

export const off = <K extends string>(eventType: K, listener: (this: Document, ev: Event) => any) => {
    document.removeEventListener(eventType, listener);
}

export const once = (eventType: string, listener: EventListenerOrEventListenerObject) => {
    on(eventType, handleEventOnce);

    function handleEventOnce(event: Event) {
        if (typeof listener === 'function')
            listener(event);
        off(eventType, handleEventOnce);
    }
}

export const trigger = <D>(eventType: string, data?: D | any) => {
    const event = new CustomEvent(eventType, {detail: data});
    document.dispatchEvent(event);
}