import {FC} from "react";
import MagmaBlockLayout, {TAspectRatio} from "../../lib/MagmaBlockLayout";
import styled from "styled-components";
import MagmaBlockLayoutMode, {widthLayoutMode} from "../../lib/MagmaBlockLayoutMode";
import {IMagmaBlockInsetsAllEdges} from "../../lib/MagmaBlockInsets";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
import MagmaTextEntity from "../../lib/Content/MagmaTextEntity";


const widthKeys = {
    'fullWidth': '100%',
    'fullWidthIgnoringInsets': '100%',
    'halfWidth': "50%",
    'thirdWidth': `${100 / 3}%`,
    'quarterWidth': `25%`,
    'fifthWidth': `20%`

}

const Wrapper = styled.div<{ width: widthLayoutMode }>`
  overflow: hidden;
  width: ${props => widthKeys[props.width]};
`


const Container = styled.div<{ aspectRatio?: TAspectRatio }>`
  position: relative;
  overflow: hidden;
  padding-top: ${props => props.aspectRatio ? `${(props.aspectRatio[1] / props.aspectRatio[0]) * 100}%` : 0};
`

const InsetContainer = styled.div<{ insets: IMagmaBlockInsetsAllEdges }>`
  margin: ${({insets: {Top, Left, Bottom, Right}}) => `${Top || 0}px ${Right || 0}px ${Bottom || 0}px ${Left || 0}px`};
`

const WrapperWidthAspectRatio = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 30px;
`


const BlockLayout: FC<MagmaBlockLayout & { Content?: MagmaBlockContent<MagmaPhotoEntity | MagmaTextEntity> }> = ({
                                                                                                                     Mode,
                                                                                                                     AspectRatio,
                                                                                                                     Insets,
                                                                                                                     children,
                                                                                                                 }) => {
    return (
        <Wrapper
            width={Mode?.Width ? MagmaBlockLayoutMode.DECODE_KEYS.Width[Mode?.Width as 0 | 1 | 2 | 3 | 4 | 5 | 6] as widthLayoutMode : 'fullWidth'}>
            <InsetContainer insets={Insets}>
                <Container
                    aspectRatio={AspectRatio}>
                    {AspectRatio
                        ? <WrapperWidthAspectRatio>{children}</WrapperWidthAspectRatio>
                        : children
                    }
                </Container>

            </InsetContainer>
        </Wrapper>
    )
}

export default BlockLayout