import React, {FC, useCallback, useState} from "react";
import styled from "styled-components";
import {Plus} from "../icons";

type TAddLinkButton<T = any> = FC<{
    items: T[]
    titleKey?: string
    valueKey?: string
    onSelect?: (item: T) => void
    width?: number
    center?: boolean
    right?: boolean
}>
const PADDING_VERTICAL: number = 7.5
const PADDING_HORIZONTAL: number = 7.5

const AddLinkWrapper = styled.button`
  padding: 4px;
  border-radius: 50%;
  color: #000000;
  font-size: 2rem;
  background-color: #fff;


`

const RootWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  &:hover ${AddLinkWrapper} {
    background: ${props => props.theme.colors.secondary.bg}
  }

`

const ListItem = styled.button`
  padding: ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: ${props => props.theme.colors.secondary.bg};
  }
`


const Dropdown = styled.div<{ width: number, center?: boolean, right?: boolean }>`
  position: absolute;
  width: ${props => props.width}px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.actionDropdownBg};
  border-radius: 8px;
  top: 100%;
  margin-top: 10px;
  backdrop-filter: blur(10px);

  ${props => props.center && `
    left:50%;
    transform:translateX(-50%);
  `}
  ${props => props.right && `
    right:0;
  `}
  & > ${ListItem}:first-child {
    padding-top: ${PADDING_VERTICAL + (PADDING_VERTICAL / 2)}px;
  }

  & > ${ListItem}:last-child {
    padding-bottom: ${PADDING_VERTICAL + (PADDING_VERTICAL / 2)}px;
  }

`


const AddLinkButton: TAddLinkButton = ({
                                           items,
                                           titleKey,
                                           valueKey,
                                           onSelect,
                                           center,
                                           right,
                                           width = 200
                                       }) => {

    const [showOptions, setShowOptions] = useState<boolean>(false)

    let mouseLeaveTimeout: NodeJS.Timeout | null = null

    const handleOnMouseLeave = () => {
        mouseLeaveTimeout = setTimeout(() => {
            setShowOptions(false)
        }, 400)
    }

    const handleOnMouseOver = () => {

        if (mouseLeaveTimeout) {
            clearTimeout(mouseLeaveTimeout)
        }

        setShowOptions(true)
    }

    const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>, val: any) => {
        e.preventDefault()

        const value = valueKey ? val[valueKey] : val

        if (onSelect) {
            onSelect(value)
        }
    }, [onSelect, valueKey])

    return (
        <RootWrapper onMouseOver={handleOnMouseOver} onMouseLeave={handleOnMouseLeave}>
            <AddLinkWrapper>
                <Plus/>
            </AddLinkWrapper>
            {showOptions && <Dropdown width={width} center={center} right={right}>
                {items && items.map((item, idx) => {
                    return <ListItem key={idx} onClick={e => handleClick(e, item)}>
                        {titleKey ? item[titleKey] : ('title' in item ? item['title'] : 'unknown')}
                    </ListItem>
                })}
            </Dropdown>}
        </RootWrapper>
    )
}

export default AddLinkButton