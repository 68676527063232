import { ICoverTemplate } from ".."
import { makeTemplateThumbnailURL } from "../../../helpers"
import MagmaLayeredBlockEntity from "../LayeredBlocks/MagmaLayeredBlockEntity"
import MagmaLabelEntity from "../LayeredBlocks/MagmaLabelEntity"
import MagmaDesignEntity from "../LayeredBlocks/MagmaDesignEntity"

const SportCategory: ICoverTemplate[] = [
    {
        title: 'sport1',
        thumbnail: makeTemplateThumbnailURL('sport1'),
        category: 'Sports',
        meta: [
            new MagmaLayeredBlockEntity({
                center: [
                    187,
                    299.3333333333333
                ],
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        374,
                        598.6666666666666
                    ]
                },
                backgroundColor: {
                    green: 0,
                    alpha: 0,
                    blue: 0,
                    red: 0
                },
                isBackground: false,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                frame: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        374,
                        598.6666666666666
                    ]
                },
                id: "ckttclmk8bz00lu1mrddlsqb0",
                tag: 0
            }),
            new MagmaLayeredBlockEntity({
                backgroundColor: {
                    alpha: 1,
                    red: 1,
                    blue: 1,
                    green: 1
                },
                tag: 1,
                design: new MagmaDesignEntity({
                    isLogo: false,
                    scale: 1,
                    editable: true,
                    asset: {
                        URL: "https://assets.magma.sh/templates/food-1/food1-bkg.png",
                        ID: "food-1/food1-bkg",
                        FileType: "png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
                    },
                    id: "food-1/food1-bkg"
                }),
                bounds: {
                    Size: [
                        375,
                        599.7191011235956
                    ],
                    Origin: [
                        -0.5,
                        -0.5245505617978097
                    ]
                },
                isBackground: false,
                frame: {
                    Size: [
                        375,
                        599.7191011235956
                    ],
                    Origin: [
                        -0.5,
                        -0.5245505617978097
                    ]
                },
                center: [
                    187,
                    299.335
                ],
                id: "ckttclmkac000lu1msck0kxv7",
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                frame: {
                    Origin: [
                        264.0016666666666,
                        -27.66666666666663
                    ],
                    Size: [
                        124.66666666666674,
                        668.67
                    ]
                },
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                backgroundColor: {
                    red: 0,
                    blue: 0,
                    alpha: 0,
                    green: 0
                },
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        668.67,
                        124.66666666666667
                    ]
                },
                center: [
                    326.335,
                    306.6683333333333
                ],
                label: new MagmaLabelEntity({
                    numberOfLines: 1,
                    lineHeight: 0,
                    fontName: "Kanit-Black",
                    fontSize: 172,
                    fontColor: {
                        green: 1.0000001192092896,
                        alpha: 1,
                        blue: 1.0000001192092896,
                        red: 1.0000001192092896
                    },
                    textAlignment: 1,
                    text: "FOODIE"
                }),
                tag: 2,
                id: "ckttclmkac100lu1mvrgcl2l0",
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                label: new MagmaLabelEntity({
                    numberOfLines: 0,
                    fontSize: 32,
                    fontName: "PlayfairDisplay-Italic_Bold-Italic",
                    textAlignment: 0,
                    fontColor: {
                        green: 1.0000001192092896,
                        alpha: 1,
                        blue: 1.0000001192092896,
                        red: 1.0000001192092896
                    },
                    text: "Denver\n        Colorado",
                    lineHeight: 0.7
                }),
                tag: 3,
                center: [
                    162.06666666666666,
                    69.88916666666665
                ],
                id: "ckttclmkbc200lu1mdmsm44dx",
                frame: {
                    Origin: [
                        37.39999999999999,
                        19.999999999999993
                    ],
                    Size: [
                        249.33333333333334,
                        99.77833333333332
                    ]
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        249.33333333333334,
                        99.77833333333332
                    ]
                },
                isBackground: false,
                backgroundColor: {
                    red: 0,
                    blue: 0,
                    green: 0,
                    alpha: 0
                }
            }),
            {
                bounds: {
                    Size: [
                        187,
                        99.77833333333332
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                label: new MagmaLabelEntity({
                    textAlignment: 0,
                    lineHeight: 0,
                    fontColor: {
                        red: 1.0000001192092896,
                        blue: 1.0000001192092896,
                        alpha: 1,
                        green: 1.0000001192092896
                    },
                    fontSize: 32,
                    fontName: "PlayfairDisplay-Italic_Bold-Italic",
                    numberOfLines: 0,
                    text: "Part One"
                }),
                id: "ckttclmkcc300lu1mmmj5k9en",
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                frame: {
                    Origin: [
                        37.400000000000006,
                        498.8916666666667
                    ],
                    Size: [
                        187,
                        99.77833333333332
                    ]
                },
                center: [
                    130.9,
                    548.7808333333334
                ],
                backgroundColor: {
                    alpha: 0,
                    green: 0,
                    blue: 0,
                    red: 0
                },
                isBackground: false,
                tag: 4
            }
        ]
    },
    {
        title: 'sport2',
        thumbnail: makeTemplateThumbnailURL('sport2'),
        category: 'Sports',
        meta: [
            new MagmaLayeredBlockEntity({
                id: "ckttclxoacc00lu1m48sse7dj",
                isBackground: false,
                bounds: {
                    Size: [
                        374,
                        598.6666666666666
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                tag: 0,
                backgroundColor: {
                    alpha: 0,
                    red: 0,
                    green: 0,
                    blue: 0
                },
                center: [
                    187,
                    299.3333333333333
                ],
                frame: {
                    Size: [
                        374,
                        598.6666666666666
                    ],
                    Origin: [
                        0,
                        0
                    ]
                }
            }),
            new MagmaLayeredBlockEntity({
                backgroundColor: {
                    green: 1,
                    alpha: 1,
                    blue: 1,
                    red: 1
                },
                isBackground: false,
                frame: {
                    Origin: [
                        -0.5,
                        -0.5245505617978097
                    ],
                    Size: [
                        375,
                        599.7191011235956
                    ]
                },
                center: [
                    187,
                    299.335
                ],
                id: "ckttclxoccd00lu1mu8e26wnp",
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Origin: [
                        -0.5,
                        -0.5245505617978097
                    ],
                    Size: [
                        375,
                        599.7191011235956
                    ]
                },
                tag: 1,
                design: new MagmaDesignEntity({
                    editable: true,
                    scale: 1,
                    id: "lifestyle-1/background",
                    asset: {
                        ID: "lifestyle-1/background",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                        URL: "https://assets.magma.sh/templates/lifestyle-1/background.png",
                        FileType: "png"
                    },
                    isLogo: false
                })
            }),
            new MagmaLayeredBlockEntity({
                bounds: {
                    Origin: [
                        53.428571428571445,
                        155.96872962886164
                    ],
                    Size: [
                        336.6,
                        389.3616835994195
                    ]
                },
                id: "ckttclxocce00lu1meapakow8",
                isBackground: false,
                backgroundColor: {
                    green: 1,
                    blue: 1,
                    red: 1,
                    alpha: 1
                },
                tag: 2,
                frame: {
                    Size: [
                        336.6,
                        389.3616835994195
                    ],
                    Origin: [
                        53.428571428571445,
                        155.96872962886164
                    ]
                },
                design: new MagmaDesignEntity({
                    editable: true,
                    asset: {
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                        URL: "https://assets.magma.sh/templates/lifestyle-1/bkg_image.png",
                        ID: "lifestyle-1/bkg_image",
                        FileType: "png"
                    },
                    scale: 1,
                    isLogo: false,
                    id: "lifestyle-1/bkg_image"
                }),
                center: [
                    221.72857142857146,
                    350.6495714285714
                ],
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Origin: [
                        269.91022099447514,
                        -26.714285714285715
                    ],
                    Size: [
                        114.67955801104972,
                        93.5
                    ]
                },
                backgroundColor: {
                    alpha: 1,
                    green: 1,
                    red: 1,
                    blue: 1
                },
                design: new MagmaDesignEntity({
                    id: "lifestyle-1/graphic_top",
                    scale: 1,
                    editable: true,
                    asset: {
                        FileType: "png",
                        URL: "https://assets.magma.sh/templates/lifestyle-1/graphic_top.png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                        ID: "lifestyle-1/graphic_top"
                    },
                    isLogo: false
                }),
                id: "ckttclxodcf00lu1m74xekhpx",
                center: [
                    327.25,
                    20.035714285714285
                ],
                tag: 3,
                frame: {
                    Size: [
                        114.67955801104972,
                        93.5
                    ],
                    Origin: [
                        269.91022099447514,
                        -26.714285714285715
                    ]
                },
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                design: new MagmaDesignEntity({
                    scale: 1,
                    isLogo: false,
                    asset: {
                        ID: "lifestyle-1/graphic_bottom",
                        URL: "https://assets.magma.sh/templates/lifestyle-1/graphic_bottom.png",
                        FileType: "png",
                        Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
                    },
                    editable: true,
                    id: "lifestyle-1/graphic_bottom"
                }),
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                id: "ckttclxoecg00lu1m1ulfmj0r",
                bounds: {
                    Origin: [
                        267.81282714984184,
                        498.8916666666666
                    ],
                    Size: [
                        165.13224043715843,
                        149.6
                    ]
                },
                backgroundColor: {
                    red: 1,
                    blue: 1,
                    alpha: 1,
                    green: 1
                },
                isBackground: false,
                frame: {
                    Size: [
                        165.13224043715843,
                        149.6
                    ],
                    Origin: [
                        267.81282714984184,
                        498.8916666666666
                    ]
                },
                tag: 4,
                center: [
                    350.37894736842105,
                    573.6916666666666
                ]
            }),
            new MagmaLayeredBlockEntity({
                frame: {
                    Origin: [
                        -34.665000000000035,
                        -14.5624509803921
                    ],
                    Size: [
                        150.00000000000006,
                        648.67
                    ]
                },
                label: new MagmaLabelEntity({
                    lineHeight: 0,
                    fontSize: 170,
                    fontName: "Caveat-Regular",
                    text: "Handmade",
                    fontColor: {
                        blue: 0.8060823678970337,
                        alpha: 1,
                        green: 0.8284377455711365,
                        red: 1.0093305110931396
                    },
                    numberOfLines: 1,
                    textAlignment: 1
                }),
                backgroundColor: {
                    green: 0,
                    red: 0,
                    blue: 0,
                    alpha: 0
                },
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        648.67,
                        150
                    ]
                },
                tag: 5,
                id: "ckttclxoech00lu1m7qrv7vq2",
                center: [
                    40.33499999999998,
                    309.7725490196078
                ],
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                tag: 6,
                bounds: {
                    Size: [
                        404,
                        104.76724999999999
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                isBackground: false,
                label: new MagmaLabelEntity({
                    textAlignment: 0,
                    text: "      MODERN LIVING\nVOLUME ONE",
                    numberOfLines: 2,
                    fontName: "EBGaramond-Regular_Bold",
                    fontSize: 35,
                    fontColor: {
                        red: 1.0140273571014404,
                        blue: 0.48350125551223755,
                        alpha: 1,
                        green: -0.10922790318727493
                    },
                    lineHeight: 0.75
                }),
                backgroundColor: {
                    blue: 0,
                    alpha: 0,
                    green: 0,
                    red: 0
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                id: "ckttclxofci00lu1meiitufgq",
                center: [
                    228.18,
                    173.3270593434343
                ],
                frame: {
                    Origin: [
                        26.180000000000007,
                        120.94343434343432
                    ],
                    Size: [
                        404,
                        104.76724999999999
                    ]
                }
            })
        ]
    }
]

export default SportCategory