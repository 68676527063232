import {IPage} from './Page'
import {IPublisherFragment} from "./Publisher";
import {IGenreFragment} from "./Genre";
import {ILocation} from "./Map";
import {firestore as _firestore} from "../../Integrations/firebase";
import firebase from "firebase/app";
import {buildCDNUrl} from "../../helpers";
import {IAsset} from "./Asset";
import cuid from "cuid";
import {PagesState} from "../../Providers/Page/PageProvider";
import {IUser} from "./User";
import {unset} from "lodash";
import {navigate} from "@reach/router";

const Timestamp = firebase.firestore.Timestamp;


export type MagData = IMag


export interface IProof {
    id: string
    kind: 'proof' | string
    preview: IAsset
}


export interface IMag {
    ID?: string
    Caption: string | null
    Cover: any
    Thumbnail: string
    Pages: IPage[]
    Publisher: IPublisherFragment
    Location?: ILocation
    GenreRefs?: any
    Genres: IGenreFragment[]
    PublishedAt: firebase.firestore.Timestamp
    ViewCount: number | null
    LikeCount: number | null
    slug: string,
    Random: number | null
    StackCount: number
    Privacy?: string
    WebViewCount: any
}


export interface IDraft {
    ID?: string
    CreatedAt: firebase.firestore.Timestamp
    Pages: IPage[]
    Proofs: IProof[]
    PublisherID: string
    UpdatedAt: firebase.firestore.Timestamp
    Thumbnail: string
}

export function buildMagURL(username: string, slug: string): string | null {
    if (!username || !slug) return null

    return `/@${username}/${slug}`
}

export default function createMag(data: firebase.firestore.DocumentData): MagData {
    const {
        id,
        Caption,
        Cover,
        GenreRefs,
        Genres,
        LikeCount,
        Location,
        Pages,
        Privacy,
        PublishedAt,
        Publisher,
        Random,
        StackCount,
        Thumbnail,
        ViewCount,
        WebViewCount,
        slug
    } = data


    return {
        ID: id,
        Caption,
        Cover,
        GenreRefs,
        Genres,
        LikeCount,
        Location,
        Pages,
        Privacy,
        PublishedAt,
        Publisher,
        Random,
        StackCount,
        Thumbnail: buildCDNUrl(Thumbnail),
        ViewCount,
        WebViewCount,
        slug
    }

}

export function createDraft(data: firebase.firestore.DocumentData): IDraft {

    const {id, CreatedAt, Pages, Proofs, PublisherID, UpdatedAt} = data
    return {
        ID: id,
        CreatedAt,
        Pages,
        Proofs,
        PublisherID,
        UpdatedAt,
        Thumbnail: Proofs && buildCDNUrl(Proofs[0]?.preview.URL)
    }
}

export const getUserMags = async (uuid: string): Promise<any> => {
    const userRef = _firestore.collection('users').doc(uuid)

    const magsRef = _firestore.collection('mags')
        .where('Publisher.Ref', '==', userRef)
        // .where('Privacy', '==', 'Public')
        .orderBy('PublishedAt', 'desc')

    const querySnapshot: firebase.firestore.QuerySnapshot = await magsRef.get();

    let mags: MagData[] = []

    querySnapshot.docs.forEach(doc => mags.push(createMag({...doc.data(), id: doc.id})));

    return mags

}


export const getUserDrafts = async (uuid: string): Promise<any> => {

    const draftsRef = _firestore.collection('users').doc(uuid).collection('drafts')
    const querySnapshot: firebase.firestore.QuerySnapshot = await draftsRef.get();

    let mags: IDraft[] = []

    querySnapshot.docs.forEach(doc => mags.push(createDraft({...doc.data(), id: doc.id})));

    return mags

}

export const publishMag = async ({draft}: { draft: PagesState }) => {

    const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = _firestore.collection('users').doc(draft.PublisherID)
    const user: IUser = await userRef.get().then(doc => doc.data() as IUser)

    let cover = draft.Pages[0]
    unset(cover, 'LayeredBlocks')


    // @ts-ignore
    const mag: IMag = {
        Caption: draft.Caption,
        Cover: cover,
        Pages: (draft.Pages.filter((val, idx) => idx !== 0).map(page => ({
            ...page,
            LayeredBlocks: null
        })) as unknown as IPage[]),
        ID: draft.ID as string,
        // Publisher: {
        //     Fullname: 'Magma Dev',
        //     Username: 'november',
        //     Ref: _firestore.collection('users').doc('UZMTfYpo57V7Hol28dKSMdQHGtA3'),
        //     PhotoUrl: 'https://assets.magma.sh/users/UZMTfYpo57V7Hol28dKSMdQHGtA3/ckstgczfj76006k1lbqlpfjl1.jpg',
        //     Name: ''
        // },
        Publisher: {
            Fullname: user.Fullname || '',
            Username: user.Username,
            Ref: userRef,
            PhotoUrl: user.PhotoUrl,
            Name: ''
        },
        Random: 3,
        Genres: draft.Genre.map(genre => ({
            Name: genre.Name,
            Ref: _firestore.collection('genres').doc(genre.ID),
            Thumbnail: genre.Thumbnail
        })) as any,
        Location: draft.Location ?? null,
        Privacy: draft.Privacy,
        PublishedAt: Timestamp.now(),
        Thumbnail: draft.Proofs[0].preview.URL,
        ViewCount: 0,
        GenreRefs: draft.Genre.map(genre => _firestore.collection('genres').doc(genre.ID) as unknown as IGenreFragment),
    }


    await _firestore.collection('mags').doc(mag.ID).set(mag)

    if (mag.ID) deleteDraft(draft.PublisherID, mag.ID)
    await navigate('/')
    return

}

export const getNewMagID = async (uuid: string): Promise<any> => {

    const coverID = cuid()
    const magID = cuid()
    await _firestore.collection("users").doc(uuid).collection('drafts').doc(magID).set({
        ID: magID,
        CreatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        PublisherID: uuid,
        Proofs: [],
        Pages: [
            {
                ID: coverID,
                LayeredBlocks: [],
                Blocks: []
            }
        ],
        activePage: coverID,
        UpdatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    return magID
}

export const deleteMag = async (id: string): Promise<boolean> => {
    return await _firestore.collection('mags')
        .doc(id)
        .delete()
        .then(() => {
            return true
        }).catch(error => {
            console.error("Error deleting document: ", error)

            return false
        })

}

export const deleteDraft = async (uuid: string, id: string): Promise<boolean> => {
    return await _firestore.collection("users").doc(uuid).collection('drafts').doc(id).delete()
        .then(() => {
            return true
        })
        .catch((error) => {
            console.error("Error deleting document: ", error);

            return false
        });
}