import {createApi} from "unsplash-js";

export type Photo = {
    id: number;
    width: number;
    height: number;
    urls: { large: string; regular: string; raw: string; small: string };
    color: string | null;
    user: {
        username: string;
        name: string;
    };
};

export const createUnsplashApi = ({ACCESS_KEY} : {ACCESS_KEY: string}) => createApi({
    // Don't forget to set your access token here!
    // See https://unsplash.com/developers
    accessKey: ACCESS_KEY
});
