import {FC, useCallback} from "react";
import {IModule} from "../../lib/Models/Module";
import ModuleWrapper from "../ModuleWrapper";
import {useBus, useListener} from "react-bus";

const BackgroundModule: FC<IModule> = ({id, onRemove, onInactive, onActive, meta}) => {

    const bus = useBus()

    const sendUpdateSignal = useCallback((payload: any) => {
        bus.emit('updateComponent', {id, data: payload})
    }, [bus, id])


    const updateMeta = useCallback((payload: any) => {
        if (payload.action === "updateMeta") {
            sendUpdateSignal(payload.data)
        }
    }, [sendUpdateSignal])

    const onUpdateFromWrapper = useCallback((payload: any) => {
        sendUpdateSignal(payload)
    }, [sendUpdateSignal])

    useListener(id, updateMeta)

    return (
        <ModuleWrapper sequence={-1} componentMeta={meta} resizable={false} onUpdate={onUpdateFromWrapper}
                       onActive={() => onActive(meta)} onInactive={onInactive} id={id} onRemoveButtonClick={onRemove}
                       initialSize={['100%', '100%']} draggable={false} background={true}>
            <img alt={"placeholder"} style={{
                transition: 'width .15s',
                background: '#fafafa',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
            }}
                 src={meta?.design?.asset?.URL || 'https://ik.imagekit.io/nginr/magma-placeholder_i3wp7mB_OMH.jpg'}/>
        </ModuleWrapper>
    )
}

export default BackgroundModule

