import MagmaDesignEntity from "../../LayeredBlocks/MagmaDesignEntity";
// import MagmaImageEntity from "../../LayeredBlocks/MagmaImageEntity";
import MagmaLabelEntity from "../../LayeredBlocks/MagmaLabelEntity";
import { MagmaLayeredBlockMetaEntity } from "../../LayeredBlocks/MagmaLayeredBlockEntity";

const FitnessCategory: Record<string, Record<string, MagmaLayeredBlockMetaEntity>> = {
    fitness_1: {
        background: new MagmaDesignEntity({
            editable: true,
            id: "fitness1/fitness1-bkg",
            scale: 1,
            isLogo: false,
            asset: {
                Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82",
                FileType: "png",
                ID: "fitness1/fitness1-bkg",
                URL: "https://assets.magma.sh/templates/fitness1/fitness1-bkg.png"
            }
        }),
        exercises: new MagmaLabelEntity({
            fontName: "Kanit-Black",
            fontColor: {
                red: 1.01723313331604,
                green: 0.914018452167511,
                blue: -0.32147765159606934,
                alpha: 1
            },
            textAlignment: 2,
            text: "      EXERCISES\nBUILT FOR YOU",
            numberOfLines: 2,
            lineHeight: 0.7,
            fontSize: 25,
            characterSpacing: 2
        }),
        basics: new MagmaLabelEntity({
            lineHeight: 0.9,
            fontSize: 55,
            characterSpacing: 3,
            text: "MY WORKOUT\nBASICS",
            fontName: "KronaOne-Regular",
            textAlignment: 0,
            numberOfLines: 2,
            fontColor: {
                red: 0.9999018311500549,
                blue: 0.9998798966407776,
                green: 1.0000687837600708,
                alpha: 1
            }
        }),
        coach: new MagmaLabelEntity({
            fontName: "KronaOne-Regular",
            fontSize: 14,
            text: "COACH JAMES",
            characterSpacing: 2,
            numberOfLines: 1,
            fontColor: {
                red: 0,
                alpha: 1,
                green: 0,
                blue: 0
            },
            lineHeight: 0,
            textAlignment: 1
        })
    },
    fitness_2: {
        background: new MagmaDesignEntity({
            scale: 1,
            isLogo: false,
            id: "fitness-2/bkg-image",
            editable: true,
            asset: {
                URL: "https://assets.magma.sh/templates/fitness-2/bkg-image.png",
                ID: "fitness-2/bkg-image",
                FileType: "png",
                Directory: "users/7pLVSdBQ37SISlXoBvNpsJ9j7R82"
            }
        }),
        lift: new MagmaLabelEntity({
            text: "LIFT",
            fontName: "Kanit-Black",
            fontSize: 195,
            fontColor: {
                alpha: 1,
                green: 1.0000687837600708,
                red: 0.9999018311500549,
                blue: 0.9998798966407776
            },
            textAlignment: 1,
            numberOfLines: 1,
            lineHeight: 0
        }),
        year: new MagmaLabelEntity({
            textAlignment: 1,
            text: "2021",
            fontName: "Kanit-Black",
            lineHeight: 0,
            fontSize: 32,
            fontColor: {
                green: 0.061598606407642365,
                blue: 0.1712283194065094,
                alpha: 1,
                red: 1.0890523195266724
            },
            numberOfLines: 1
        }),
        modern_exercise: new MagmaLabelEntity({
            fontSize: 27,
            textAlignment: 1,
            text: "MODERN EXERCISE",
            fontName: "KronaOne-Regular",
            numberOfLines: 1,
            lineHeight: 0,
            fontColor: {
                alpha: 1,
                blue: 0.1712283194065094,
                green: 0.061598606407642365,
                red: 1.0890523195266724
            }
        })
    }
}

export default FitnessCategory



