import {FC, useCallback, useContext, useState} from "react";
import ModuleOptionsWrapper from "../ModuleOptionsWrapper";
import {IModuleOptions} from "../../lib/Models/Module";
import styled from "styled-components";
import 'react-tabs/style/react-tabs.css';
import AllFonts, {findRegularStorageFont, getFonts, StorageFont} from "../../Assets/Fonts/AllFonts";
import {
    convertColorToRGBObject,
    convertPercentageOfDefaultFontSizeToPixels,
    convertPixelsToPercentageOfDefaultFontSize,
    convertRGBObjectToRGBColor,
    loadFont
} from "../../helpers";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"
import {ChromePicker, Color, ColorChangeHandler} from 'react-color'
import {ChevronLeft, Plus} from "../../icons";
import {has, toSafeInteger} from "lodash";
import {PagesContext, PageState} from "../../Providers/Page/PageProvider";
import {CompState} from "../../lib/Models/Component";
import LayersOptions from "../Components/LayersOptions";
import {FaAlignCenter, FaAlignLeft, FaAlignRight} from "react-icons/all";

const DEFAULTS = {
    fontSize: 300,
    fontFamily: 'Raleway-Regular',
    color: '#000',
    textAlignment: 1
}

const FontsList = styled.ul`
  max-height: calc(100% - 150px);
  overflow: auto;
  background: #fafafa;
`

const SizeWrapper = styled.div`
  padding: 0 20px;
  margin-top: 3rem;
  position: relative;

  &:before {
    content: '-';
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
  }

  &:after {
    content: '+';
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
  }
`

const Wrapper = styled.div`
  position: relative;
`

const FontSelector = styled.div`
  position: absolute;
  background: #fafafa;
  top: 0;
  right: 0;
  bottom: 90px;
  left: 0;
  color: #000;
  z-index: 99;
`

const SelectorHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(23, 23, 23, 0.1);
  padding-right: 12px;

  svg {
    font-size: 140%;
  }


  h4 {
    padding: 12px 18px;
    font-weight: 600;
  }
`
const FontDisplay = styled.a`
  border: 1.5px solid #323232;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 35px;
  padding: 0 15px;
  font-weight: 600;
  font-size: 14px;
  color: #B5B5B5;
  cursor: pointer;

  svg {
    font-size: 140%;
    transform: translateY(2px);
    display: block;
  }
`

const FontLink = styled.li<{ active: boolean }>`
  cursor: pointer;
  display: block;
  padding: 0 20px;
  line-height: 42px;
  font-size: 15px;
  position: relative;
  background: ${props => props.active ? '#eee' : 'transparent'};

  &:hover {
    background: #eee;
  }
`

const SelectFontButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1rem;
  background: ${props => props.theme.colors.secondary.bg};
  padding: 0 12px;
  z-index: 5;

  &:hover {
    opacity: 0.8;
  }
`

const SliderWithTooltip = Slider;

export const MIN_FONT_SIZE = 10
export const MAX_FONT_SIZE = 500
export const TEXT_ALIGNMENT_MAP = [
    'Left',
    'Center',
    'Right'
]

const TextModuleOptions: FC<IModuleOptions> = ({onDone, id, meta, onUpdate, onRemove}) => {
    const initialFontFamily = meta?.label?.fontName || meta?.label?.fontFamily || DEFAULTS.fontFamily
    const fontFamily = AllFonts[initialFontFamily as StorageFont] ?? initialFontFamily
    const _fontSize = has(meta, 'label.fontSize') ? convertPercentageOfDefaultFontSizeToPixels(meta.label.fontSize) : DEFAULTS.fontSize
    const _textAlignment = meta?.label?.textAlignment ?? DEFAULTS.textAlignment

    // @ts-ignore
    const [pagesState] = useContext(PagesContext)

    //...State
    const [activeFontName, setActiveFontName] = useState<string>(fontFamily || "")
    const [color, setColor] = useState<Color | undefined>(convertRGBObjectToRGBColor(meta?.label?.fontColor) || undefined)
    const [fontSize, setFontSize] = useState<number>(_fontSize)
    const [textAlignment, setTextAlignment] = useState<0 | 1 | 2>(_textAlignment)
    const [selectingFont, setSelectingFont] = useState<boolean>(false)

    const getComponent = useCallback((): CompState | undefined => {
        const page: PageState = pagesState?.Pages?.find((page: PageState) => page.ID === pagesState?.activePage)

        const layeredBlocks = page && Array.isArray(page.LayeredBlocks) ? page.LayeredBlocks : []

        return layeredBlocks?.find((layeredBlock: CompState) => layeredBlock.id === id)
    }, [pagesState, id])

    const updateMeta = useCallback(() => {
        onUpdate({
            id: id,
            action: 'updateMeta',
            data: null
        })
    }, [id, onUpdate])

    const applyFont = useCallback((fontName: string) => {
        setActiveFontName(fontName)
        const storageFontName = findRegularStorageFont(fontName)
        const component = getComponent()

        loadFont({fontName}).then(() => {


            onUpdate({
                id,
                action: 'updateMeta',
                data: {
                    ...component,
                    label: {
                        ...component?.label,
                        fontName: storageFontName ?? fontName
                    }
                }
            })
        })
    }, [getComponent, onUpdate, id])

    const changeSize = useCallback((value: number) => {
        setFontSize(value)
        const component = getComponent()

        onUpdate({
            id,
            action: 'updateMeta',
            data: {
                ...component,
                label: {
                    ...component?.label,
                    storageFontSize: convertPixelsToPercentageOfDefaultFontSize(value)
                }
            }
        })

    }, [getComponent, onUpdate, id])

    const changeColor: ColorChangeHandler = useCallback((color) => {
        setColor(color.hex)
        const component = getComponent()
        onUpdate({
            id,
            action: 'updateMeta',
            data: {
                ...component,
                label: {
                    ...component?.label,
                    fontColor: convertColorToRGBObject(color)
                }
            }
        })
    }, [getComponent, onUpdate, id])

    const changeAlignment = useCallback((alignment) => {

        setTextAlignment(alignment)
        const component = getComponent()

        onUpdate({
            id,
            action: 'updateMeta',
            data: {
                ...component,
                label: {
                    ...component?.label,
                    textAlignment: alignment
                }
            }
        })
    }, [getComponent, id, onUpdate])

    return (
        <ModuleOptionsWrapper name={"Text Properties"} {...{onDone, onRemove}}>
            <Wrapper id="text_module_options">
                <div className={"mb-8"}>

                    {/*<div className={"text-center"}>*/}
                    {/*    Text Alignment*/}
                    {/*</div>*/}
                    <div className="flex justify-center mt-0 gap-4 text-2xl">
                        {TEXT_ALIGNMENT_MAP.map((label, value) => {
                            let optionClass = "px-2 cursor-pointer px-4 py-3"
                            if (value === textAlignment) {
                                optionClass += " bg-white text-black"
                            }

                            return (
                                <span className={optionClass} onClick={() => changeAlignment(value)} key={value}>
                                    {label === 'Left' && <FaAlignLeft/>}
                                    {label === 'Center' && <FaAlignCenter/>}
                                    {label === 'Right' && <FaAlignRight/>}
                                </span>
                            )
                        })}
                    </div>
                </div>
                <div>
                    <FontDisplay onClick={() => setSelectingFont(true)} title={"Select A Font"}>
                        <span>{activeFontName}</span>
                        <ChevronLeft style={{transform: 'rotate(180deg)'}}/>
                    </FontDisplay>

                </div>

                <SizeWrapper className={"my-8"}>
                    <div style={{
                        color: '#B5B5B5',
                        top: '-30px',
                        position: 'absolute',
                        transform: 'translateX(-50%)',
                        left: `${(fontSize / (MAX_FONT_SIZE * 1.1) * 100) + 4}%`
                    }}>{toSafeInteger(fontSize)}px
                    </div>
                    <SliderWithTooltip
                        railStyle={{
                            background: '#c1c1c1',
                        }}
                        handleStyle={{
                            background: '#c1c1c1',
                            border: 'none'
                        }}
                        trackStyle={{
                            background: '#c1c1c1',
                        }}
                        min={MIN_FONT_SIZE}
                        max={MAX_FONT_SIZE}
                        onChange={changeSize}
                        defaultValue={_fontSize || DEFAULTS.fontSize}
                    />
                </SizeWrapper>

                <div className={"my-16"}>
                    <ChromePicker

                        color={color}
                        onChange={changeColor}
                    />
                </div>

                {selectingFont && <FontSelector>
                    <SelectorHeader>
                        <h4>
                            Select A Font
                        </h4>
                        <button onClick={() => setSelectingFont(false)} className={'cursor-pointer'}>
                            <Plus style={{transform: 'rotate(45deg)'}}/>
                        </button>
                    </SelectorHeader>
                    <FontsList>
                        {getFonts().map((font, idx) => (
                            <FontLink
                                style={{fontFamily: font}}
                                active={activeFontName === font} onClick={() => applyFont(font)}
                                onDoubleClick={() => setSelectingFont(false)}
                                key={idx}>
                                <span
                                    style={{position: 'relative', zIndex: 1}}>{font}</span> {activeFontName === font &&
                            <SelectFontButton onClick={() => setSelectingFont(false)}>Select</SelectFontButton>}
                            </FontLink>
                        ))}

                    </FontsList>
                </FontSelector>}

                <LayersOptions id={id} onUpdate={updateMeta}/>
            </Wrapper>
        </ModuleOptionsWrapper>
    )
}


export default TextModuleOptions