import { ICoverTemplate } from ".."
import { makeTemplateThumbnailURL } from "../../../helpers"
import MagmaLayeredBlockEntity from "../LayeredBlocks/MagmaLayeredBlockEntity"
import FitnessCategoryLayers from "../DesignLayers/Categories/FitnessCategory"

const { fitness_1, fitness_2 } = FitnessCategoryLayers

const FitnessCategory: ICoverTemplate[] = [
    {
        title: 'fitness1',
        thumbnail: makeTemplateThumbnailURL('fitness1'),
        category: 'Fitness',
        meta: [
            new MagmaLayeredBlockEntity({
                frame: {
                    Size: [
                        375,
                        603.2
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Origin: [
                        -0.5,
                        -0.2904681647939924
                    ],
                    Size: [
                        375,
                        603.2
                    ]
                },
                tag: 1,
                design: fitness_1.background,
                center: [
                    187,
                    299.335
                ],
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                label: fitness_1.exercises,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                bounds: {
                    Size: [
                        249.33333333333334,
                        106.85714285714286
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                center: [
                    218.16666666666669,
                    63.42857142857143
                ],
                tag: 2,
                frame: {
                    Size: [
                        249.33333333333334,
                        106.85714285714286
                    ],
                    Origin: [
                        93.50000000000001,
                        10
                    ]
                },
                isBackground: false,
            }),
            new MagmaLayeredBlockEntity({
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        603.87,
                        150
                    ]
                },
                frame: {
                    Size: [
                        150,
                        603.87
                    ],
                    Origin: [
                        5.334999999999965,
                        -17.897068965517178
                    ]
                },
                label: fitness_1.basics,
                tag: 3,
                isBackground: false,
                center: [
                    80.33499999999998,
                    281.43793103448274
                ],
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                center: [
                    341.2903225806451,
                    500.71311475409834
                ],
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        200,
                        20
                    ]
                },
                frame: {
                    Size: [
                        20,
                        200
                    ],
                    Origin: [
                        331.2903225806451,
                        400.71311475409834
                    ]
                },
                
                isBackground: false,
                label: fitness_1.coach,
                transform: [
                    6.123233995736766e-17,
                    -1,
                    1,
                    6.123233995736766e-17,
                    0,
                    0
                ],
                tag: 4
            })
        ]
    },
    {
        title: 'fitness2',
        thumbnail: makeTemplateThumbnailURL('fitness2'),
        category: 'Fitness',
        meta: [
            new MagmaLayeredBlockEntity({
                bounds: {
                    Size: [
                        377,
                        603.2
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                
                tag: 0,
                center: [
                    187,
                    299
                ],
                frame: {
                    Size: [
                        377,
                        603.2
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                isBackground: false,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            }),
            new MagmaLayeredBlockEntity({
                tag: 1,
                design: fitness_2.background,
                isBackground: false,
                center: [
                    186.5,
                    299.335
                ],
                bounds: {
                    Origin: [
                        -1,
                        -0.6182418952618605
                    ],
                    Size: [
                        375,
                        603.2
                    ]
                },
                
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                frame: {
                    Origin: [
                        -1,
                        -0.6182418952618605
                    ],
                    Size: [
                        375,
                        603.2
                    ]
                }
            }),
            new MagmaLayeredBlockEntity({
                isBackground: false,
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        424,
                        149.6675
                    ]
                },
                frame: {
                    Origin: [
                        -30,
                        249.33499999999998
                    ],
                    Size: [
                        424,
                        149.6675
                    ]
                },
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                tag: 2,
                
                label: fitness_2.lift,
                center: [
                    182,
                    324.16875
                ]
            }),
            new MagmaLayeredBlockEntity({
                tag: 3,
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                
                frame: {
                    Origin: [
                        270.8888888888889,
                        10
                    ],
                    Size: [
                        83.11111111111111,
                        50
                    ]
                },
                bounds: {
                    Size: [
                        83.11111111111111,
                        50
                    ],
                    Origin: [
                        0,
                        0
                    ]
                },
                label: fitness_2.year,
                center: [
                    312.44444444444446,
                    35
                ],
                isBackground: false
            }),
            new MagmaLayeredBlockEntity({
                isBackground: false,
                bounds: {
                    Origin: [
                        0,
                        0
                    ],
                    Size: [
                        394,
                        54.42454545454545
                    ]
                },
                
                transform: [
                    1,
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                tag: 4,
                label: fitness_2.modern_exercise,
                frame: {
                    Origin: [
                        -10,
                        524.2454545454545
                    ],
                    Size: [
                        394,
                        54.42454545454545
                    ]
                },
                center: [
                    187,
                    551.4577272727273
                ]
            })
        ]
    }
]

export default FitnessCategory