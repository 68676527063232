import {FC} from "react";
import MagmaBlockContent from "../../lib/Content/MagmaBlockContent";
import MagmaPhotoEntity from "../../lib/Content/MagmaPhotoEntity";
import MagmaTextEntity from "../../lib/Content/MagmaTextEntity";
import TextContent from "./TextContent";
import PhotoContent from "./PhotoContent";
import VideoContent from "./VideoContent";
import MagmaVideoEntity from "../../lib/Content/MagmaVideoEntity";
import MagmaLocationEntity from "../../lib/Content/MagmaLocationEntity";
import LocationContent from "./LocationContent";
import MagmaSocialMediaEntity from "../../lib/Content/MagmaSocialMediaEntity";
import SocialContent from "./SocialContent";

type BlockContentProps =
    MagmaBlockContent<MagmaPhotoEntity | MagmaTextEntity | MagmaVideoEntity | MagmaLocationEntity | MagmaSocialMediaEntity>
    & { IsPlaceholder: boolean, onUpdate?: (content: MagmaBlockContent<unknown>) => void, onDeleteBlock?: () => void, snapshot?: boolean }

const BlockContent: FC<BlockContentProps> = ({
                                                 Photo,
                                                 Video,
                                                 Text,
                                                 Map,
                                                 Social,
                                                 IsPlaceholder = false,
                                                 onUpdate = () => null,
                                                 onDeleteBlock = () => null,
                                                 snapshot
                                             }) => {
    return (
        <>

            {Text &&
            (snapshot ? <div className={"reset_text_styling"} dangerouslySetInnerHTML={{__html: Text.Text}}/> :
                <TextContent onDeleteBlock={onDeleteBlock} onUpdate={onUpdate}
                             IsPlaceholder={IsPlaceholder} {...Text} snapshot={snapshot}/>)}
            {Photo && <PhotoContent {...Photo}/>}
            {Video && <VideoContent {...Video}/>}
            {Map && <LocationContent {...Map}/>}
            {Social && <SocialContent {...Social}/>}
        </>
    )
}

export default BlockContent