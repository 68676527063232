/// LocationCategory is the high level type of point of interest that the location is part of. i.e.: "Bar", "Airport"
import {IAsset} from "../Models/Asset";
import MagmaLinkEntity from "./MagmaLinkEntity";
import {IUserFragment} from "../Models/User";

type LocationCategory = {
    ID: string
    Name: string
    Icon: string
}

type LocationCoordinates = {
    Latitude: number
    Longitude: number
}

type LocationAttributes = {

    Center?: LocationCoordinates
    Zoom: number
    Direction: number
    Thumbnail: IAsset
    StyleUrl?: string

}

type TMagmaLocationEntity = {
    ID: string
    Name: string
    Address: string
    URL?: string
    Coordinates: LocationCoordinates
    Thumbnail?: string
    Category?: LocationCategory
    Attributes?: LocationAttributes
    ShoppingLink?: string
    Link?: MagmaLinkEntity
    TaggedUsers?: IUserFragment[]
    Caption?: string
}

export default class MagmaLocationEntity {

    ID: string
    Name: string
    Address: string
    URL?: string
    Coordinates: LocationCoordinates
    Thumbnail?: string
    Category?: LocationCategory
    Attributes?: LocationAttributes
    ShoppingLink?: string
    Link?: MagmaLinkEntity
    TaggedUsers?: IUserFragment[]
    Caption?: string

    constructor({
                    Address,
                    Attributes,
                    Caption,
                    Category,
                    Coordinates,
                    ID,
                    Link,
                    Name,
                    ShoppingLink,
                    TaggedUsers,
                    Thumbnail,
                    URL
                }: TMagmaLocationEntity) {
        this.ID = ID
        this.Name = Name
        this.Address = Address
        this.URL = URL
        this.Coordinates = Coordinates
        this.Thumbnail = Thumbnail
        this.Category = Category
        this.Attributes = Attributes
        this.ShoppingLink = ShoppingLink
        this.Link = Link
        if (TaggedUsers) {
            this.TaggedUsers = TaggedUsers
        }
        this.Caption = Caption
    }

}