import {clone} from "lodash";
import {IAsset} from "../Models/Asset";
import {DEFAULT_TRANSFORM} from "../Models/Block";
import {IUserFragment} from "../Models/User";
import MagmaLinkEntity from "./MagmaLinkEntity";


export type ITransform = [number, number, number, number, number, number]

export type IAttribute = {
    Transform: ITransform
}

type TMagmaPhotoEntity = {
    ID: string
    Asset: IAsset
    Attributes?: IAttribute
    ShoppingLink?: string
    Link?: MagmaLinkEntity | null
    TaggedUsers: IUserFragment[]
    Caption?: string

}

export default class MagmaPhotoEntity {

    ID: string
    Asset: IAsset
    Attributes?: IAttribute
    ShoppingLink: string
    TaggedUsers?: IUserFragment[]
    Caption?: string
    Link?: MagmaLinkEntity | null

    constructor({
                    Asset,
                    Attributes = {Transform: clone(DEFAULT_TRANSFORM) as ITransform},
                    ID,
                    ShoppingLink = "",
                    TaggedUsers = [],
                    Caption = "",
                    Link = null,
                }: TMagmaPhotoEntity) {

        this.ID = ID
        this.Asset = Asset
        this.Attributes = Attributes
        this.ShoppingLink = ShoppingLink
        if (TaggedUsers) {
            this.TaggedUsers = TaggedUsers
        }
        this.Caption = Caption
        this.Link = Link
    }
}