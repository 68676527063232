import {FC, FormEventHandler, useEffect, useState} from "react";
import {debounce} from 'lodash'
import styled from "styled-components";
import {YoutubeVideo} from "../lib/Content/MagmaVideoEntity";

const API_KEY = `AIzaSyDaxDQaTvRevIz9tzGbKCYkD1FnPeKt8V0`


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  overflow: auto;
`

const Container = styled.div`
  width: 800px;
  padding: 40px;
  height: 600px;
  background-color: #fff;
  overflow: hidden;
  padding-bottom: 110px;
`

const Input = styled.input`
  outline: none;
  min-width: 300px;
  height: 52px;
  border: 1px solid #333;
  padding: 0 25px;
  color: #333;
  width: 100%;
  border-radius: 100px;
`

const CardGrid = styled.div`
  margin-top: 30px;
  display: grid;
  gap: 0.2rem;
  width: 100%;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  height: 100%;
  overflow: auto;
`

const CardWrapper = styled.button`
  margin-bottom: 10px;
  color: #000;
  text-align: left;
  padding: 10px;

  h4 {
    font-weight: 600;
    font-size: 1rem;
    line-height: normal;
    margin-top: 6px;
  }

  p {
    font-size: .8rem;
    opacity: 0.8;
    margin-top: 6px;
  }

  &:hover {
    background-color: #e3e3e3;
  }
`

const YoutubeCard: FC<{ video: YoutubeVideo, selectVideo: (video: YoutubeVideo) => void }> = ({video, selectVideo}) => {
    return (
        <CardWrapper onClick={() => selectVideo(video)}>
            <div>
                <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.description}/>
            </div>
            <div>
                <h4>
                    {video.snippet.title}
                </h4>
                <p>
                    {video.snippet.channelTitle}
                </p>
            </div>
        </CardWrapper>
    )
}

const YoutubeVideoSearch: FC<{ onVideoSelection?: (video: YoutubeVideo) => void }> = ({onVideoSelection}) => {


    const [videos, setVideos] = useState<YoutubeVideo[]>([])

    const searchKeyword: FormEventHandler<HTMLInputElement> = debounce((event) => {
        fetch(`https://youtube.googleapis.com/youtube/v3/search?part=snippet%2Cid&maxResults=12&type=video&q=${event.target.value}&key=${API_KEY}`)
            .then(response => response.json())
            .then(data => {
                setVideos(data.items)
            })
    }, 500)

    useEffect(() => {
        fetch(`https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2C%20id&chart=mostPopular&maxHeight=80&maxResults=12&maxWidth=120&regionCode=US&key=${API_KEY}`)
            .then(response => response.json())
            .then(data => {
                setVideos(data.items)
            })
    }, [])


    const handleVideoSelection = (video: YoutubeVideo) => {
        if (onVideoSelection) {
            onVideoSelection(video)
        }
    }

    return (
        <Wrapper>
            <Container>
                <Input placeholder={"Search Youtube Video"} autoComplete={"none"} spellCheck={"false"} type="search"
                       onChange={searchKeyword}/>
                <CardGrid>
                    {videos && videos.map((video, idx) => <YoutubeCard key={idx}
                                                                       selectVideo={handleVideoSelection}
                                                                       video={video}/>)}
                </CardGrid>
            </Container>
        </Wrapper>
    )

}

export default YoutubeVideoSearch